import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ContentText, SubmitBtn, BackBtn } from './style'
import I18n from 'i18n'

import Dialog from '@material-ui/core/Dialog'
import ContentHeader from 'lib/elements/ContentHeader'
import Content from 'lib/elements/Content'
import DlgActions from 'lib/elements/DlgActions'

import QuestionsEditPanel from './QuestionsEditPanel'

const questions = [
  {
    title: I18n.t('components.Reports.ConfirmModal.questions.titlePatient'),
    questionItems: [
      {
        question: I18n.t(
          'components.Reports.ConfirmModal.questions.questionSideEffects'
        ),
        description: I18n.t(
          'components.Reports.ConfirmModal.questions.descriptionSideEffects'
        )
      },
      {
        question: I18n.t(
          'components.Reports.ConfirmModal.questions.questionDuration'
        ),
        description: I18n.t(
          'components.Reports.ConfirmModal.questions.descriptionDuration'
        )
      }
    ]
  },
  {
    title: I18n.t('components.Reports.ConfirmModal.questions.titleDoctor'),
    questionItems: [
      {
        question: I18n.t(
          'components.Reports.ConfirmModal.questions.questionuUAW'
        ),
        description: I18n.t(
          'components.Reports.ConfirmModal.questions.descriptionUAW'
        )
      },
      {
        question: I18n.t(
          'components.Reports.ConfirmModal.questions.questionSickness'
        ),
        description: I18n.t(
          'components.Reports.ConfirmModal.questions.descriptionSickness'
        )
      }
    ]
  }
]

const ConfirmModal = ({ onSubmit, onBack, isConfirm, proOffer }) => {
  return (
    <Dialog
      aria-describedby='alert-dialog-description'
      aria-labelledby='alert-dialog-title'
      onClose={onSubmit}
      open={isConfirm}
    >
      <Content>
        <ContentHeader>
          {I18n.t('components.Reports.ConfirmModal.header')}
        </ContentHeader>
        <ContentText>
          <QuestionsEditPanel isPro={proOffer} questions={questions} />
        </ContentText>
      </Content>
      <DlgActions>
        <SubmitBtn onClick={onSubmit} variant='contained'>
          {I18n.t('components.Reports.ConfirmModal.submitBtn')}
        </SubmitBtn>
        <BackBtn onClick={onBack} variant='contained'>
          {I18n.t('components.Reports.ConfirmModal.backBtn')}
        </BackBtn>
      </DlgActions>
    </Dialog>
  )
}

ConfirmModal.propTypes = {
  isConfirm: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  proOffer: PropTypes.string
}

function mapStateToProps (state, props) {
  const { proOffer } = state.proOffer
  return { proOffer }
}

export default connect(mapStateToProps)(ConfirmModal)
