import styled from 'styled-components'
import * as palette from 'styles/palette'

export const HabitTitleRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`

export const HabitText = styled.span`
  display: block;
  font-size: 1.3rem;
  line-height: 1.4rem;
  color: ${palette.tableBodyColor};
  background-color: ${props => (props.isPN ? `${palette.coldBlue}` : '')};
`

export const HabitDetailText = HabitText.extend`
  width: 7.5rem;
`

export const HabitTitle = HabitText.extend`
  font-weight: 400;
  background-color: ${props => (props.isPN ? `${palette.coldBlue}` : '')};
`
