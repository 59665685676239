import styled from 'styled-components'
import * as palette from 'styles/palette'
import MedButton from 'lib/elements/MedButton'

export const RegisterContainer = styled.div`
  width: 48rem;
  // TODO reuse padding if you add checkbox above it
  /* padding: 4.8rem 1.2rem 4rem; */
  margin: auto;
  background-color: ${palette.white};
  border-radius: 0.7rem;
  @media (max-width: 480px) {
    border-radius: 0;
  }
  // TODO since I removed checkbox, it slightly changed appereance
  // so added following properties to make it good looking
  display: flex;
  align-items: center;
`

export const RegisterForm = styled.form`
  padding: 4rem;
  text-align: center;
  @media (max-width: 375px) {
    padding: 0 2rem;
  }
  width: 100%;
  height: 100%;
`
export const AgreementBox = styled.div`
  padding-top: 2.5rem;
  display: flex;
  padding-bottom: 1.9rem;
`

export const AgreementTxt = styled.p`
  font-size: 1.2rem;
  line-height: initial;
  color: ${props =>
    props.hasError ? palette.errorBGColor : palette.primaryLightGrey};
  text-align: left;
  margin: 0;
  margin-top: auto;
`

export const RegisterTitle = styled.span`
  color: ${palette.primaryDarkGreen};
  font-size: 17px;
  font-weight: 600;
`

export const RegisterLabel = styled.p`
  font-weight: 500;
  color: ${palette.primaryDarkGreen};
  text-align: left;
  margin-top: 3rem;
  margin-bottom: 0;
`

export const RegisterBtn = styled(MedButton)`
  &&& {
    background-color: ${palette.primaryDarkGreen};
    &:hover {
      background-color: ${palette.primaryLightGreen};
    }
  }
`

export const ErrorTextLabel = styled.div`
  background-color: ${palette.errorBGColor};
  border-radius: 0.7rem;
  color: ${palette.white};
  padding: 0.7rem 1.3rem;
  position: unset;
  bottom: 1px;
  transition: all ease-in-out 0.6s;
  left: 100%;
  width: auto;
  white-space: nowrap;
  margin-left: 1.1rem;
  margin-right: 1.1rem;
  opacity: 0;
  opacity: 1;
  box-sizing: border-box;
`
export const ErrorMessageContainer = styled.div`
  height: 30px;
  padding: 10px 0 10px 0;
}
`
