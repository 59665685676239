import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  QuestionItem,
  Question,
  EditItem,
  ProText,
  EditBtn,
  CircleCloseBtn,
  Description,
  QuestionItemContainer,
  InputContainer,
  QuestionInput,
  CheckIcon
} from './styles'

import { CARD_TYPE } from '../../../../../constants'

class QuestionItemBox extends Component {
  static propTypes = {
    isPro: PropTypes.string.isRequired,
    questionItem: PropTypes.objectOf(PropTypes.node)
  }

  state = {
    isEdit: false,
    isClose: false,
    question: this.props.questionItem.question,
    description: this.props.questionItem.description
  }

  handleEdit = () => {
    this.setState({ isEdit: !this.state.isEdit })
  }

  handleClose = () => {
    this.setState({ isClose: true })
  }

  handleChangeQuesion = e => {
    this.setState({ question: e.target.value })
  }

  handleChangeDescription = e => {
    this.setState({ description: e.target.value })
  }

  render () {
    const { isPro } = this.props
    const { isEdit, isClose, question, description } = this.state
    return (
      <div>
        {!isClose ? (
          !isEdit ? (
            <div>
              <QuestionItem>
                <Question>{question}</Question>
                <EditItem>
                  {isPro !== CARD_TYPE.Pro && <ProText>pro</ProText>}
                  <EditBtn onClick={this.handleEdit} />
                </EditItem>
                <CircleCloseBtn onClick={this.handleClose} />
              </QuestionItem>
              <Description>{description}</Description>
            </div>
          ) : (
            <div>
              <QuestionItemContainer>
                <InputContainer>
                  <QuestionInput
                    onChange={this.handleChangeQuesion}
                    value={question}
                  />
                  <QuestionInput
                    onChange={this.handleChangeDescription}
                    value={description}
                  />
                </InputContainer>
                <CheckIcon onClick={this.handleEdit} />
              </QuestionItemContainer>
            </div>
          )
        ) : null}
      </div>
    )
  }
}

export default QuestionItemBox
