import { identityInstanceAxios as identity } from '../config'

const TEAM_URL = '/pharma/team'
const INVITE_TEAM_MEMBER = '/pharma/inviteColleague'
const REINVITE_TEAM_MEMBER = '/pharma/resendInvitationLink'

/**
 * Retrieve team data
 *
 * @returns {Promise} - Result of ajax call
 */
export async function getTeamAPI (token) {
  // Support both /sites and /sites/:name

  // TODO: IE serves cached data for GET, alternate fix is to add a timestamp as query param to url
  // Find out the appropriate way to bust the cache at server for IE.
  // Refs: https://github.com/axios/axios/issues/297#issuecomment-473229366
  // https://stackoverflow.com/questions/45912500/reactjs-ie11-not-making-new-http-request-using-cached-data

  const t = Date.now()
  const URL = `${TEAM_URL}?c=${t}`

  const config = {
    url: URL,
    method: 'get',
    headers: { Authorization: token }
  }

  return identity(config)
}

/**
 * Invite team member
 *
 * @returns {Promise} - Result of ajax call
 */
export async function inviteTeamMember (userData, token) {
  // Support both /sites and /sites/:name

  const config = {
    url: INVITE_TEAM_MEMBER,
    method: 'post',
    data: { data: userData },
    headers: { Authorization: token }
  }

  return identity(config)
}

/**
 * Renvite team member
 *
 * @returns {Promise} - Result of ajax call
 */
export async function reInviteTeamMember (userData, token) {
  // Support both /sites and /sites/:name

  const config = {
    url: REINVITE_TEAM_MEMBER,
    method: 'post',
    data: userData,
    headers: { Authorization: token }
  }

  return identity(config)
}

/**
 * Soft delete team member
 *
 * @returns {Promise} - Result of ajax call
 */
export async function deleteTeamMember (memberId, token) {
  const config = {
    url: `${TEAM_URL}/${memberId}`,
    method: 'delete',
    headers: { Authorization: token }
  }

  return identity(config)
}
