import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import PubSub from 'pubsub-js'
import I18n from 'i18n'
import MenuIcon from '@material-ui/icons/Menu'
// import logo from '../../assets/img/medikura-logo_rgb.svg'
import {
  HeaderContainer,
  LeftHeaderContainer,
  CenterHeaderContainer,
  RightHeaderContainer,
  // LogoImg,
  HeaderBtn,
  HeaderLabel,
  HeaderLink,
  ButtonIcon
} from './style'
import UserProfileDropdown from 'components/UserProfileDropdown'
import UserDataModal from 'components/UserDataModal'
import Sidebar from 'components/Sidebar'
import RegisterModal from '../RegisterModal'
import ProTestingModal from '../RegisterModal/pro'
import SearchBar from '../SearchBar'
import * as palette from 'styles/palette'
import { withMedTracker } from 'services/tracking'

class Header extends Component {
  static propTypes = {
    isPro: PropTypes.bool,
    onHowItWorksClick: PropTypes.func,
    handleDrawer: PropTypes.func,
    includeNavSideBar: PropTypes.bool,
    keepSidebarOpen: PropTypes.bool,
    isLoginPage: PropTypes.bool
  }

  state = {
    sidebarOpen: true,
    drawerOpen: false,
    reportsList: []
  }

  componentDidMount () {
    PubSub.subscribe('REPORTS_LIST_LOADED', (msg, reportsList) => {
      this.setState({ reportsList: reportsList })
    })
  }

  componentWillUnmount () {
    PubSub.unsubscribe('REPORTS_LIST_LOADED')
  }

  handleSidebar = () => {
    this.props.onSideBarToggle(!this.state.sidebarOpen)
    this.setState({
      sidebarOpen: !this.state.sidebarOpen
    })
  }

  handleRegisterClick = () => {
    this.setState({ showRegisterModal: true })
  }

  handleTestProClick = () => {
    this.setState({ showTestProModal: true })
  }

  onMyProfileClick = () => {
    this.setState({ showUserDataModal: true })
  }

  onHandleGlobalSearch = async (event, reportSelected) => {
    const searchString = reportSelected.trim()
    const UUIDregexp = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
    if (searchString.match(UUIDregexp)) {
      this.props.MedTrackEvent('PatientCase', 'search', 'reportId', 1)

      this.props.history.push({
        pathname: '/reports/' + searchString,
        state: { reportID: searchString }
      })
    }
  }

  render () {
    const {
      scrolling,
      includeNavSideBar,
      isPro,
      isPlus,
      isLoginPage
    } = this.props
    const { reportsList } = this.state
    return (
      <div>
        <HeaderContainer scrolling={scrolling}>
          <LeftHeaderContainer>
            {includeNavSideBar === true && (
              <ButtonIcon
                edge='start'
                color='inherit'
                aria-label='burgerMenuBtn'
                onClick={this.handleSidebar}
              >
                <MenuIcon
                  aria-label='burgerMenuBtn'
                  style={{ color: palette.primaryDarkGrey }}
                />
              </ButtonIcon>
            )}
            <a href='/reports'>{/* <LogoImg src={logo} /> */}</a>
          </LeftHeaderContainer>
          <CenterHeaderContainer>
            {includeNavSideBar === true && (isPro || isPlus) && (
              <SearchBar
                id='headerSearchbarID'
                options={reportsList}
                handleOnChange={this.onHandleGlobalSearch}
                getOptionLabel={option => option.id}
              />
            )}
          </CenterHeaderContainer>
          <RightHeaderContainer>
            <HeaderLink
              href='https://www.xo-life.com/pharma/'
              target='_blank'
              rel='noopener'
            >
              {I18n.t('components.Header.compareProducts')}
            </HeaderLink>
            <HeaderLabel>{I18n.t('components.Header.support')}</HeaderLabel>
            {!isPro && !isPlus && !isLoginPage && (
              <>
                <HeaderBtn onClick={this.handleRegisterClick}>
                  {I18n.t('components.Header.testBasicPlus')}
                </HeaderBtn>
                <RegisterModal
                  open={this.state.showRegisterModal}
                  onCancel={() => this.setState({ showRegisterModal: false })}
                />
              </>
            )}
            {isPlus && (
              <>
                <HeaderBtn onClick={this.handleTestProClick}>
                  {I18n.t('components.Header.testPro')}
                </HeaderBtn>
                <ProTestingModal
                  open={this.state.showTestProModal}
                  onCancel={() => this.setState({ showTestProModal: false })}
                />
              </>
            )}
            {(isPro || isPlus) && (
              <UserProfileDropdown openMyProfile={this.onMyProfileClick} />
            )}
          </RightHeaderContainer>
        </HeaderContainer>
        {includeNavSideBar === true && (
          <Sidebar
            sidebarOpen={this.state.sidebarOpen}
            isPlus={isPlus}
            isPro={isPro}
          />
        )}
        <UserDataModal
          open={this.state.showUserDataModal}
          onCancel={() => this.setState({ showUserDataModal: false })}
        />
      </div>
    )
  }
}

export default withRouter(withMedTracker(Header))
