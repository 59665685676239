import { loginAPI } from 'api'
import {
  GET_USER_DATA,
  GET_USER_COMPANY_DATA,
  GET_USER_DATA_PENDING,
  GET_USER_COMPANY_DATA_PENDING,
  SET_ROUTE_ERROR
} from '../../actions'

export const LOGIN_AUTH_PENDING = 'LOGIN_AUTH_PENDING'
export const LOGIN_AUTH_SUCCESS = 'LOGIN_AUTH_SUCCESS'
export const LOGIN_AUTH_FAIL = 'LOGIN_AUTH_FAIL'

export const LOGOUT_AUTH_PENDING = 'SERVER_LOGOUT_PENDING'
export const LOGOUT_AUTH_SUCCESS = 'SERVER_LOGOUT_SUCCESS'
export const LOGOUT_AUTH_FAIL = 'SERVER_LOGOUT_FAIL'

export const SET_URL_TOKEN = 'SET_URL_TOKEN'

export const SETUP_USER_ENV = async authToken => async dispatch => {
  dispatch({ type: GET_USER_DATA_PENDING })
  dispatch({ type: GET_USER_COMPANY_DATA_PENDING })
  dispatch({ type: SET_ROUTE_ERROR, payload: false })

  dispatch(await GET_USER_DATA(authToken))
  dispatch(await GET_USER_COMPANY_DATA(authToken))
  return true
}
// TODO: Handle this properly, async dispatch actions
export const LOGIN_AUTH = async data => async dispatch => {
  try {
    const res = await loginAPI(data)
    if (res.data.token) {
      dispatch({ type: LOGIN_AUTH_SUCCESS, payload: { user: res.data } })
      await dispatch(await SETUP_USER_ENV(res.data.token))
    }
    return { type: LOGIN_AUTH_SUCCESS, payload: { user: res.data } }
  } catch (err) {
    if (err.response.data) {
      dispatch({ type: LOGIN_AUTH_FAIL, payload: err.response.data })
    }
    return { type: LOGIN_AUTH_FAIL, payload: err.response.data }
  }
}

export const LOGOUT_AUTH = async () => {
  try {
    return { type: LOGOUT_AUTH_SUCCESS }
  } catch (err) {
    return { type: LOGOUT_AUTH_FAIL, payload: err.response.data }
  }
}
