import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { withStyles, Menu, MenuItem } from '@material-ui/core'
import { SET_PROOFFER, LOGOUT_AUTH, GET_USER_RESET } from 'actions'
import { CARD_TYPE } from 'constants.js'
import { connect } from 'react-redux'
import * as palette from 'styles/palette'
import I18n from 'i18n'
import {
  ProfileItem,
  StyledAvatar,
  ExpandMoreStyled,
  ExpandLessStyled,
  ProfileContainer,
  UserName,
  UserEmail,
  MenuOption
} from './style'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    top: '57px !important',
    minWidth: '250px'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles(theme => ({
  root: {
    width: '89%',
    borderTop: `1px solid ${palette.primaryLightGreen}`,
    textAlign: 'center',
    paddingBottom: '12px',
    paddingTop: '12px',
    marginLeft: '5.5%',
    '&>span': {
      fontSize: '1.3rem',
      fontWeight: '700'
    },
    '&:focus': {
      backgroundColor: palette.rowActiveColor,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: palette.primarySuperLightGreen
      }
    }
  }
}))(MenuItem)

class UserProfileDropdown extends Component {
  state = {
    anchorEl: null,
    isOpened: false,
    logout: false
  }

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
      isOpened: !this.state.isOpened
    })
  }

  handleClose = () => {
    this.setState({ anchorEl: null, isOpened: false })
  }

  handleLogout = async () => {
    this.setState({ logout: true }, () => {
      this.props.logout()
    })
  }

  render () {
    const {
      name = { firstName: '', lastName: '' },
      email = ''
    } = this.props.userData

    if (this.state.logout) {
      return <Redirect to='/login' />
    }

    return (
      <div style={{ alignSelf: 'center' }}>
        <ProfileItem aria-controls='customized-menu' onClick={this.handleClick}>
          <StyledAvatar style={{ marginLeft: '10px' }}>
            {name.firstName.slice(0, 1)}
            {name.lastName.slice(0, 1)}
          </StyledAvatar>
          {!this.state.isOpened ? <ExpandMoreStyled /> : <ExpandLessStyled />}
        </ProfileItem>
        <StyledMenu
          id='customized-menu'
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
        >
          <ProfileContainer>
            <div style={{ padding: '5px' }}>
              <StyledAvatar>
                {name.firstName.slice(0, 1)}
                {name.lastName.slice(0, 1)}
              </StyledAvatar>
              <UserName>
                {name.firstName} {name.lastName}
              </UserName>
              {/* <Divider>|</Divider> */}
              {/* <UserRole>SuperAdmin</UserRole> */}
              <br />
              <UserEmail>{email}</UserEmail>
            </div>

            {/* <div style={{ padding: '5px' }}>
              <TeamCount>Team ({team.teamMembers.length})</TeamCount>
            </div> */}
          </ProfileContainer>
          <StyledMenuItem
            onClick={() => {
              this.props.openMyProfile()
              this.handleClose()
            }}
          >
            <MenuOption>
              {I18n.t('components.UserProfileDropdown.myProflie')}
            </MenuOption>
          </StyledMenuItem>
          <StyledMenuItem onClick={this.handleLogout}>
            <MenuOption>
              {I18n.t('components.UserProfileDropdown.logOut')}
            </MenuOption>
          </StyledMenuItem>
        </StyledMenu>
      </div>
    )
  }
}

function mapStateToProps (state) {
  const { errors, isGettingUserData, userData } = state.userData
  const { team } = state

  return { errors, isGettingUserData, userData, team }
}
// TODO:  Clean up this, too many dispatches
function mapDispatchToProps (dispatch) {
  return {
    logout: async () => {
      await dispatch(await LOGOUT_AUTH())
      await dispatch({ type: GET_USER_RESET })
      await dispatch({ type: SET_PROOFFER, payload: CARD_TYPE.Free })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileDropdown)
