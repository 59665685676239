import React, { Component } from 'react'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import DropDownMenu from '../../../../../../DropDownMenu'
import moment from 'moment'
import PropTypes from 'prop-types'
import I18n from 'i18n'
import {
  InputLabel,
  MenuBtn,
  DurationContainer,
  DontKnowContainer,
  CheckBox,
  FromToContainer,
  ProText,
  DatePickerContainer,
  MedDatePicker,
  CalendarIcon,
  TextBox,
  HiddenCommentBtn,
  DisplayFlex
} from './style'

const menuList = [
  I18n.t(
    'components.ReportDetail.DetailContainer.RightDetailView.DiseasesModal.AdditionalComment.highBloodPressure'
  ),
  I18n.t(
    'components.ReportDetail.DetailContainer.RightDetailView.DiseasesModal.AdditionalComment.diabetes'
  ),
  I18n.t(
    'components.ReportDetail.DetailContainer.RightDetailView.DiseasesModal.AdditionalComment.fat'
  ),
  I18n.t(
    'components.ReportDetail.DetailContainer.RightDetailView.DiseasesModal.AdditionalComment.heart'
  ),
  I18n.t(
    'components.ReportDetail.DetailContainer.RightDetailView.DiseasesModal.AdditionalComment.stomach'
  ),
  I18n.t(
    'components.ReportDetail.DetailContainer.RightDetailView.DiseasesModal.AdditionalComment.lungs'
  )
]
// const moreList = ['Bluthochdruck', 'Fettstoffwechselstörung', 'Herzkreislauf', 'Magen-Darm-Trakt', 'Atemwege', 'Krebs']
const defaultTagName = I18n.t(
  'components.ReportDetail.DetailContainer.RightDetailView.DiseasesModal.AdditionalComment.defaultTagName'
)

class AdditionalComment extends Component {
  static propTypes = {
    onAddComment: PropTypes.func,
    onRemoveComment: PropTypes.func,
    index: PropTypes.number
  }

  state = {
    anchorEl: null,
    tagName: defaultTagName,
    isShowDetails: false,
    fromDate: moment(),
    toDate: moment()
  }

  handleClose = item => {
    this.setState(
      { anchorEl: null, tagName: item !== 'close' ? item : this.state.tagName },
      () => {
        if (
          this.state.tagName !==
          I18n.t(
            'components.ReportDetail.DetailContainer.RightDetailView.DiseasesModal.AdditionalComment.defaultTagName'
          )
        ) {
          this.setState({ isShowDetails: true }, () => {
            this.props.onAddComment()
          })
        } else {
          this.setState({ isShowDetails: false }, () => {
            this.props.onRemoveComment()
          })
        }
      }
    )
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleFromDate = date => {
    this.setState({ fromDate: date })
  }

  handleToDate = date => {
    this.setState({ toDate: date })
  }

  handleHiddenComment = () => {
    this.setState({ isShowDetails: false }, () => {
      this.setState({ tagName: defaultTagName })
      this.props.onRemoveComment()
    })
  }

  render () {
    const { anchorEl, tagName, fromDate, toDate, isShowDetails } = this.state
    return (
      <div>
        {this.props.index < 1 ? (
          <InputLabel>
            {I18n.t(
              'components.ReportDetail.DetailContainer.RightDetailView.DiseasesModal.AdditionalComment.labels.diseases'
            )}
          </InputLabel>
        ) : isShowDetails ? (
          <InputLabel
            style={{ marginTop: this.props.index < 1 ? '0' : '6rem' }}
          />
        ) : (
          <InputLabel
            style={{ marginTop: this.props.index < 1 ? '0' : '6rem' }}
          >
            {I18n.t(
              'components.ReportDetail.DetailContainer.RightDetailView.DiseasesModal.AdditionalComment.labels.moreDiseases'
            )}
          </InputLabel>
        )}
        <DisplayFlex>
          <MenuBtn
            aria-haspopup='true'
            aria-owns={anchorEl ? 'disease-menu' : null}
            hasError={false}
            id='disease-btn'
            isDefault={
              tagName ===
              I18n.t(
                'components.ReportDetail.DetailContainer.RightDetailView.DiseasesModal.AdditionalComment.defaultTagName'
              )
            }
            onClick={this.handleClick}
          >
            <span>{tagName}</span>
            <ArrowDropDownIcon />
          </MenuBtn>
          {isShowDetails && (
            <HiddenCommentBtn onClick={this.handleHiddenComment} />
          )}
        </DisplayFlex>
        <DropDownMenu
          anchorEl={anchorEl}
          buttonId='disease-btn'
          dropdownList={menuList}
          menuId='disease-menu'
          onHandleClose={this.handleClose}
        />
        {isShowDetails && (
          <div>
            <TextBox
              placeholder={I18n.t(
                'components.ReportDetail.DetailContainer.RightDetailView.DiseasesModal.AdditionalComment.placeholder'
              )}
            />
            <DurationContainer>
              <InputLabel>
                {I18n.t(
                  'components.ReportDetail.DetailContainer.RightDetailView.DiseasesModal.AdditionalComment.labels.duration'
                )}
              </InputLabel>
              <DontKnowContainer>
                <CheckBox />
                <InputLabel>
                  {I18n.t(
                    'components.ReportDetail.DetailContainer.RightDetailView.DiseasesModal.AdditionalComment.labels.unknown'
                  )}
                </InputLabel>
              </DontKnowContainer>
            </DurationContainer>
            <FromToContainer>
              <ProText>
                {I18n.t(
                  'components.ReportDetail.DetailContainer.RightDetailView.DiseasesModal.AdditionalComment.from'
                )}
              </ProText>
              <DatePickerContainer>
                <MedDatePicker
                  endDate={toDate}
                  onChange={this.handleFromDate}
                  selected={fromDate}
                  selectsStart
                  startDate={fromDate}
                />
                <CalendarIcon />
              </DatePickerContainer>
              <ProText>
                {I18n.t(
                  'components.ReportDetail.DetailContainer.RightDetailView.DiseasesModal.AdditionalComment.till'
                )}
              </ProText>
              <DatePickerContainer>
                <MedDatePicker
                  endDate={toDate}
                  onChange={this.handleToDate}
                  selected={toDate}
                  selectsEnd
                  startDate={fromDate}
                />
                <CalendarIcon />
              </DatePickerContainer>
            </FromToContainer>
          </div>
        )}
      </div>
    )
  }
}

export default AdditionalComment
