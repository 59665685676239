import { identityInstanceAxios as identity } from '../config'

const LOGIN_URL = '/pharma/login'
const LOGOUT_URL = '/pharma/auth/sign_out'

/**
 * Authentication request using AJAX call
 *
 * @returns {Promise} - Result of ajax call
 */
export async function loginAPI (data) {
  return identity.post(LOGIN_URL, {
    email: data.email,
    password: data.password
  })
}

/**
 * Logout request using AJAX call
 *
 * @returns {Promise} - Result of ajax call
 */
export async function logoutAPI () {
  return identity.delete(LOGOUT_URL)
}
