import styled from 'styled-components'

export const AgbSection = styled.div`
  display: inline;
  margin-left: 10px;
`
export const PrivacySection = styled.div`
  margin-left: 16px;
  display: inline;
`

export const Impressum = styled.div`
  margin-left: 16px;
  display: inline;
`

export const LinkContainer = styled.div`
  margin: 5px;
  padding: 1rem;
  text-align: end;
  margin-right: 4rem;
`
