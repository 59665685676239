import React, { Component } from 'react'
import PropTypes from 'prop-types'
import I18n from 'i18n'
import { connect } from 'react-redux'
import {
  AddressCell,
  CustomMenu,
  DownloadBtn,
  DownloadProgress,
  HeaderCell,
  HeaderReportContainer,
  LabelCell,
  MenuItemTxt,
  MenuLineItem,
  MetadataTable,
  // ProText,
  Title,
  Value,
  ValueCell,
  ErrorText,
  PremiumTeaser,
  PharmaInternalIdContainer
} from './style'

import FileSaver from 'file-saver'
import { CARD_TYPE } from '../../../constants'
import {
  formatAssignedAt,
  formatGender,
  formatHCPConfirmation,
  formatContactConfirmation
} from 'lib/helper/formatters'
import { exportFile } from '../../../api/ExportSvc'
import lodash from 'lodash'

import { withMedTracker } from 'services/tracking'

import NotificationImportantOutlinedIcon from '@material-ui/icons/NotificationImportantOutlined'
import * as palette from '../../../styles/palette'

import { UPDATE_PHARMA_INTERNAL_ID } from 'actions'
import { getUserToken } from 'selectors/user'

import InlineEditField from 'components/common/InlineEditField'

class HeaderReport extends Component {
  static propTypes = {
    report: PropTypes.object.isRequired,
    isQuestion: PropTypes.bool.isRequired,
    onOpenReportModal: PropTypes.func,
    onSetPatientNotification: PropTypes.func,
    onQuestionsModal: PropTypes.func,
    onShowRegisterModal: PropTypes.func,
    proOffer: PropTypes.string
  }

  state = {
    isPatientNotification: true,
    anchorEl: null,
    downloadErrorMessage: '',
    pharmaInternalId: this.props.report.metaData.pharmaInternalId
  }

  componentDidUpdate (prevProps) {
    if (
      prevProps.report.metaData.pharmaInternalId !==
      this.props.report.metaData.pharmaInternalId
    ) {
      this.setState({
        pharmaInternalId: this.props.report.metaData.pharmaInternalId
      })
    }
  }

  handleToggleBtn = () => {
    this.setState(
      { isPatientNotification: !this.state.isPatientNotification },
      () => {
        this.props.onSetPatientNotification()
      }
    )
  }

  handleDownloadBtnClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleDownloadFile = async fileType => {
    this.setState({ downloading: true })
    try {
      const res = await exportFile(this.props.report, fileType)
      const blob = res.data
      FileSaver.saveAs(blob, `${this.props.report.metaData.id}.${fileType}`, {
        type: `application/${fileType};charset=utf-8`
      })
      this.setState({ anchorEl: null, downloading: false })

      this.props.MedTrackEvent('PatientCase', 'download', fileType, 1)
    } catch (error) {
      this.setState({
        anchorEl: null,
        downloading: false,
        downloadErrorMessage: I18n.t(
          'components.ReportDetail.HeaderReport.downloadError'
        )
      })
    }
  }

  handleDownloadBtnClose = () => {
    this.setState({ anchorEl: null })
  }

  handleDownloadCSV = () => {
    const { proOffer } = this.props
    if (proOffer !== CARD_TYPE.Pro) {
      this.setState({ anchorEl: null }, () => {
        this.props.onShowRegisterModal()
      })
    }
  }

  handlePharmaInternalIdChange = async newPharmaInternalId => {
    const updatedInternalId = newPharmaInternalId.trim()
    await this.props.updatePharmaInternalId(
      this.props.report.metaData.id,
      updatedInternalId,
      this.props.userToken
    )
    this.setState({ pharmaInternalId: updatedInternalId })
    this.props.MedTrackEvent(
      'PatientCase',
      'internalId',
      updatedInternalId === '' ? 'unset' : 'set',
      1
    )
  }

  render () {
    const { downloading, downloadErrorMessage } = this.state
    const { report, proOffer } = this.props

    return (
      <HeaderReportContainer>
        {/* right-align Herunterladen button with Premium testen button */}
        <div style={{ width: '100.6%' }}>
          {report.metaData.reportSeverityStatus === 'serious' ? (
            <NotificationImportantOutlinedIcon
              style={{
                color: palette.primaryDarkGreen,
                marginRight: '10px',
                marginBottom: '-7px'
              }}
              fontSize='large'
            />
          ) : (
            ''
          )}
          <Title>{I18n.t('components.ReportDetail.HeaderReport.id')}:</Title>
          <Value>{report.metaData.id}</Value>
          <Title>{I18n.t('components.ReportDetail.HeaderReport.date')}:</Title>
          <Value>{formatAssignedAt(report)}</Value>
          <DownloadBtn
            aria-haspopup='true'
            aria-owns={this.state.anchorEl ? 'download-menu' : null}
            onClick={this.handleDownloadBtnClick}
            variant='contained'
          >
            {downloading && <DownloadProgress size={16} />}
            {I18n.t('components.ReportDetail.HeaderReport.download')}
          </DownloadBtn>
          <PharmaInternalIdContainer>
            <Title style={{ float: 'left', paddingBottom: 0 }}>
              {I18n.t('components.ReportDetail.HeaderReport.internalId')}:
            </Title>
            <InlineEditField
              placeholderText={I18n.t(
                'components.ReportDetail.HeaderReport.na'
              )}
              value={this.state.pharmaInternalId}
              onSave={this.handlePharmaInternalIdChange}
            />
          </PharmaInternalIdContainer>
          <CustomMenu
            anchorEl={this.state.anchorEl}
            id='download-menu'
            onClose={this.handleDownloadBtnClose}
            open={Boolean(this.state.anchorEl)}
          >
            <MenuLineItem onClick={() => this.handleDownloadFile('pdf')}>
              <MenuItemTxt>
                {I18n.t('components.ReportDetail.HeaderReport.pdf')}
              </MenuItemTxt>
            </MenuLineItem>
            {proOffer === CARD_TYPE.Pro && (
              <MenuLineItem onClick={() => this.handleDownloadFile('xml')}>
                <MenuItemTxt>
                  {I18n.t('components.ReportDetail.HeaderReport.xml')}
                </MenuItemTxt>
              </MenuLineItem>
            )}
            {proOffer !== CARD_TYPE.Pro && (
              <MenuLineItem disabled>
                <MenuItemTxt isDisabled>
                  {I18n.t('components.ReportDetail.HeaderReport.xml')}
                </MenuItemTxt>
                <PremiumTeaser>
                  {I18n.t('components.ReportDetail.HeaderReport.premiumTeaser')}
                </PremiumTeaser>
              </MenuLineItem>
            )}
            {
              // component of download button as csv and xml (CSV , XML)
              /*            <MenuLineItem onClick={this.handleDownloadCSV}>
              <MenuItemTxt isDisabled={proOffer !== CARD_TYPE.Pro}>{t('as_CSV')}</MenuItemTxt>
              {
                proOffer !== CARD_TYPE.Pro &&
                <ProText>{t('Premium')}</ProText>
              }
            </MenuLineItem> */
            }
          </CustomMenu>
        </div>
        {downloadErrorMessage && <ErrorText>{downloadErrorMessage}</ErrorText>}
        <div>
          <MetadataTable>
            <colgroup>
              <col style={{ width: '11%' }} />
              <col style={{ width: '13%' }} />
              <col style={{ width: '12%' }} />
              <col style={{ width: '7%' }} />
              <col style={{ width: '17%' }} />
              <col style={{ width: '12%' }} />
              <col style={{ width: '15%' }} />
              <col style={{ width: '13%' }} />
              {/* 20% once addresses are shown there */}
            </colgroup>
            <tbody>
              <tr>
                <HeaderCell colSpan={4}>
                  {I18n.t(
                    'components.ReportDetail.HeaderReport.generalInformation'
                  )}
                </HeaderCell>
                <HeaderCell colSpan={4}>
                  {I18n.t('components.ReportDetail.HeaderReport.medicalStaff')}
                </HeaderCell>
              </tr>
              <tr>
                <LabelCell>
                  {I18n.t('components.ReportDetail.HeaderReport.drug')}:
                </LabelCell>
                <ValueCell>{report.metaData.primaryDrug}</ValueCell>
                <LabelCell>
                  {I18n.t('components.ReportDetail.HeaderReport.source')}:
                </LabelCell>
                <ValueCell>
                  {I18n.t('components.ReportDetail.HeaderReport.patient')}
                </ValueCell>
                <LabelCell>
                  {I18n.t('components.ReportDetail.HeaderReport.confirmation')}:
                </LabelCell>
                <ValueCell>
                  {I18n.t(
                    'components.ReportDetail.HeaderReport.' +
                      formatHCPConfirmation(report)
                  )}
                </ValueCell>
                <LabelCell>
                  {I18n.t('components.ReportDetail.HeaderReport.contact')}:
                </LabelCell>
                <AddressCell rowSpan={2}>
                  {I18n.t(
                    'components.ReportDetail.HeaderReport.' +
                      formatContactConfirmation(report)
                  )}
                </AddressCell>
              </tr>
              <tr>
                <LabelCell>
                  {I18n.t('components.ReportDetail.HeaderReport.country')}:
                </LabelCell>
                <ValueCell>
                  {I18n.t(
                    'components.ReportDetail.HeaderReport.countries.' +
                      lodash.get(
                        report,
                        'contacts.responsibleAuthority.country',
                        ''
                      )
                  )}
                </ValueCell>
                <LabelCell>
                  {I18n.t('components.ReportDetail.HeaderReport.patient')}:
                </LabelCell>
                <ValueCell>
                  {formatGender(report, true) +
                    (report.contacts.patient.dateOfBirthYear
                      ? ', ' + report.contacts.patient.dateOfBirthYear
                      : '')}
                </ValueCell>
                <LabelCell>
                  {I18n.t('components.ReportDetail.HeaderReport.contact')}:
                </LabelCell>
                <AddressCell rowSpan={2}>—{/* TODO */}</AddressCell>
              </tr>
            </tbody>
          </MetadataTable>
        </div>

        {/*
          <ReportDataDetail>
            <div>
              <ValueCell>
                <DetailTitle>Arzneimittel:</DetailTitle>
                <DataValue>{ report.drugs.length > 0 ? report.drugs[0].name : "Keine" }</DataValue>
              </ValueCell>
              <ValueCell>
                <DetailTitle>Berichtsquelle:</DetailTitle>
                <DataValue>Patient</DataValue>
              </ValueCell>
            </div>
            <div>
              <ValueCell>
                <DetailTitle>Einbindung des Arztes:</DetailTitle>
                <DataValue>{report.contacts.healthProfessional ? "Ja" : "Nein"}</DataValue>
              </ValueCell>
              <DataTitle>
                <DetailTitle>Überprüfung des Arztes:</DetailTitle>
                <DataValue>TODO</DataValue>
              </DataTitle>
              <ValueCell>
                <DetailTitle>Arzt:</DetailTitle>
                <DataValue>
                  {report.contacts.healthProfessional && report.contacts.healthProfessional.specialisation ?
                    report.contacts.healthProfessional.specialisation : "Nicht angegeben"}
                </DataValue>
              </ValueCell>
            </div>
          </ReportDataDetail>
          */}
      </HeaderReportContainer>
    )
  }
}

function mapStateToProps (state, props) {
  const { proOffer } = state.proOffer
  return { proOffer, userToken: getUserToken(state) }
}

function mapDispatchToProps (dispatch) {
  return {
    updatePharmaInternalId: async function (reportId, pharmaInternalId, token) {
      await UPDATE_PHARMA_INTERNAL_ID(reportId, pharmaInternalId, token)
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMedTracker(HeaderReport))
