import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation, Trans } from 'react-i18next'
import TeaserOverlay from '../../TeaserOverlay'
import { withMedTracker } from 'services/tracking'

import {
  NotificationContainer,
  PropertyLabel,
  PropertyValue,
  Divider
} from './style'

class Notifications extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showTestProModal: false
    }
  }

  componentDidMount () {
    this.props.MedTrackPageView('Notifications Settings Page')
  }

  handleTestProClick = () => {
    this.setState({ showTestProModal: true })
  }

  render () {
    const isPlus = this.props.isPlus
    const { t } = this.props
    return (
      <>
        <NotificationContainer isPlus={isPlus}>
          {isPlus && (
            <TeaserOverlay
              textToDisplay={
                <Trans
                  i18nKey='components.Settings.Notifications.notificationsTeaserText'
                  values={{
                    break: '<br />'
                  }}
                />
              }
            />
          )}
          <PropertyLabel isPlus={isPlus}>
            {t('components.Settings.Notifications.propertyLabel1')}
          </PropertyLabel>{' '}
          <br />
          <span />
          <PropertyValue>
            {t('components.Settings.Notifications.propertyValue1')}
          </PropertyValue>
          <Divider isPlus={isPlus} />
          <PropertyLabel isPlus={isPlus}>
            {t('components.Settings.Notifications.propertyLabel2')}
          </PropertyLabel>{' '}
          <br />
          <span />
          <PropertyValue>
            {t('components.Settings.Notifications.propertyValue2')}
          </PropertyValue>
        </NotificationContainer>
      </>
    )
  }
}

function mapStateToProps (state, props) {
  const { isPlus } = state.proOffer
  return { isPlus }
}

export default connect(
  mapStateToProps,
  null
)(withTranslation()(withMedTracker(Notifications)))
