import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import deTranslations from './locales/de.json'
import enTranslations from './locales/en.json'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      de: {
        translation: deTranslations
      },
      en: {
        translation: enTranslations
      }
    },
    lng: 'de',
    fallbackLng: 'de',

    interpolation: {
      escapeValue: false
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true
    }
  })

export default i18n
