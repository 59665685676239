import React from 'react'
import PropTypes from 'prop-types'

import { HabitTitleRow, HabitText, HabitTitle } from './style'

import { INTERVALS, UNITS } from '../../../../../constants'

const Habit = ({ habit, title }) => {
  return (
    <HabitTitleRow>
      <HabitTitle>{title}</HabitTitle>
      {habit.amount && habit.unit && habit.interval && (
        <HabitText>
          {habit.amount +
            ' ' +
            UNITS[habit.unit] +
            ' ' +
            INTERVALS[habit.interval]}
        </HabitText>
      )}
      {habit.description && <HabitText>{habit.description}</HabitText>}
    </HabitTitleRow>
  )
}

Habit.propTypes = {
  habit: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
}

export default Habit
