import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation, Trans } from 'react-i18next'
import I18n from 'i18n'

import {
  Wrapper,
  PageHeader,
  Content,
  ErrorMessageContainer,
  ErrorMessageTitle
} from './style'
import background from 'assets/img/medikura-background.png'
import TermsAndConditions from '../../components/TermsAndConditions'

class ErrorPageContainer extends Component {
  render () {
    return (
      <>
        <Wrapper>
          <PageHeader isLoginPage />
          <Content imageUrl={background}>
            {this.props.tokenExpiredError && (
              <ErrorMessageContainer>
                <Trans i18nKey='containers.ErrorPageContainer.tokenExpiredError'>
                  <br />
                  <strong />
                </Trans>
              </ErrorMessageContainer>
            )}
            {this.props.invalidReportIdError && (
              <ErrorMessageContainer>
                <ErrorMessageTitle>
                  {I18n.t('containers.ErrorPageContainer.errorTitle')}
                </ErrorMessageTitle>
                <Trans i18nKey='containers.ErrorPageContainer.invalidReportIdError'>
                  <strong />
                </Trans>
              </ErrorMessageContainer>
            )}
            {this.props.invalidTokenError && (
              <ErrorMessageContainer>
                <ErrorMessageTitle>
                  {I18n.t('containers.ErrorPageContainer.errorTitle')}
                </ErrorMessageTitle>
                <Trans i18nKey='containers.ErrorPageContainer.invalidTokenError'>
                  <strong />
                </Trans>
              </ErrorMessageContainer>
            )}
          </Content>
          <TermsAndConditions isPro />
        </Wrapper>
      </>
    )
  }
}

const mapStateToProps = state => {
  const {
    tokenExpiredError,
    invalidReportIdError,
    invalidTokenError
  } = state.route

  return {
    tokenExpiredError,
    invalidReportIdError,
    invalidTokenError
  }
}

export default connect(mapStateToProps)(withTranslation()(ErrorPageContainer))
