import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash'
import PubSub from 'pubsub-js'
import {
  ReportsContainer,
  ReportsMainContainer,
  LeftPanel,
  RightPanel,
  NOReport,
  NOReportTxt,
  StyledSpinner,
  FilterContainer
} from './style'
import ReportDetail from '../ReportDetail'
import ReportTable from '../ReportTable'
import ProfileEditModal from '../ProfileEditModal'
import ReportModal from './ReportModal'
import ConfirmModal from './ConfirmModal'
import AttentionModal from './AttentionModal'
import RegisterModal from '../RegisterModal'
import OldBrowsersPopup from '../OldBrowsersPopup'
import ProTestingModal from '../RegisterModal/pro'
import Bowser from 'bowser'
import arrow from '../../assets/img/arrow.png'
import NestedMenu from '../NestedMenu'
import { withTranslation } from 'react-i18next'
import { withMedTracker } from 'services/tracking'

import {
  REPORT_HEADERS,
  HEADERS,
  REPORT_STEPS,
  REPORT_STATUS,
  CARD_TYPE
} from '../../constants'

import { GET_NESTED_REPORT_FILTERS, REPORT_FILTERS } from './filters'

import {
  SET_PROOFFER,
  LOGOUT_AUTH,
  GET_USER_RESET,
  SET_ROUTE_ERROR,
  SET_REPORT_FILTERS,
  GET_REPORTS,
  GET_REPORT,
  SET_INVALID_REPORT_ID_ERROR,
  SET_INVALID_TOKEN_ERROR,
  SET_REPORT_LINK_DISABLED_ERROR
} from 'actions'

import { BROWSER_SUPPORT } from 'lib/constants'

import { openReportAPI } from 'api'
import { getUserToken, isUserLoggedIn } from 'selectors/user'

class Reports extends Component {
  static propTypes = {
    reports: PropTypes.array,
    report_id: PropTypes.string,
    isPro: PropTypes.bool
  }

  state = {
    isProfileEditModal: false,
    isRemoveUser: true,
    selectedItem: HEADERS.REGISTER,
    step: REPORT_STEPS.PatientReport,
    status: REPORT_STATUS.New,
    isNR: true, // New Report
    isQuestion: true,
    isReportModal: false,
    isPatientNotification: false,
    isQuestionsModal: false,
    isConfirmModal: false,
    isAttentionModal: false,
    onShowRegisterModal: false,
    onShowTestProModal: false,
    goToRegister: false,
    isSignIn: true,
    profileImg: null,
    isTeamSettingsModal: false,
    isInviteMemberModal: false,
    isInvited: false,
    showOldBrowsersPopup: false,
    drawerOpen: false,
    selected: 0,
    scrolling: false,
    isPremiumPharma: false,
    loading: false,
    reports: [],
    filteredReports: [],
    activeFilters: this.props.defaultFilters
  }

  async componentDidMount () {
    this.setState({ loading: true })
    this.props.userToken &&
      (await this.getReportsListFromAPI(this.props.userToken))
    await this.handleSetReportView(
      this.props.match && this.props.match.params.id
    )
    this.setState({ loading: false })

    // TODO :: Move this browser incompatibility check to HOC
    const browser = Bowser.getParser(window.navigator.userAgent)
    let isBrowserSupported = browser.satisfies(BROWSER_SUPPORT)

    // isBrowserSupported will be undefined when the browser used is not specified in the params;
    // in that case show the popup
    if (typeof isBrowserSupported === 'undefined') {
      isBrowserSupported = false
    }

    this.setState({
      showOldBrowsersPopup: !isBrowserSupported
    })

    // TODO :: Filters should be applied before report list is passed to view
    // if the user is not a pro or plus user, dont apply filters
    if (this.props.isPro || this.props.isPlus) {
      this.filterReports(this.props.defaultFilters)
    } else {
      this.setState({ filteredReports: this.state.reports })
    }

    // define event for scrolling, it is used for header
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  }

  async componentWillReceiveProps () {
    const reportIdFromURL = _.get(this, 'props.history.location.state.reportID')

    if (reportIdFromURL) {
      await this.handleSetReportView(reportIdFromURL)
    }
  }

  // TODO : Clean this, no direct calls to API, only dispatch actions to store or use selectors
  getSingleReport = async (
    reportIdToOpen,
    original = false,
    minVersion = 0
  ) => {
    try {
      const report = await this.props.getReport({
        reportId: reportIdToOpen,
        original,
        minVersion,
        token: this.props.userToken
      })
      return report
    } catch (err) {
      if (err.response.status === 409) {
        this.props.setReportLinkDisabledError()
        const reportId = window.location.pathname.split('/')[2]
        this.props.history.push({ pathname: /login/, state: { reportId } })
      }
      if (err.response.status === 403) {
        // This happens when report is not associated with the logged in Pharma company account
        // Logout the user and show error msg
        await this.props.setRouteError(true)
        await this.props.logout()
        this.props.history.push('/login')
      }
      if (err.response.status === 401) {
        // Unauthorized
        this.props.setInvalidTokenError()
        if (this.props.userLoggedIn) {
          await this.props.logout()
          const reportId = reportIdToOpen
          this.props.history.push({ pathname: /login/, state: { reportId } })
        } else {
          this.props.history.push('/error')
        }
      }

      if (err.response.status === 404) {
        // invalid report id, if not logged in user, redirect to error page and show error
        // TODO:: If this happens when user is logged in, that means on click of the report
        // from list, which is less likely. If reproducible, figure out how to show error.
        if (!this.props.userLoggedIn) {
          this.props.setInvalidReportIdError()
          this.props.history.push('/error')
        }
      }

      this.setState({
        error_code: 'Something went wrong'
      })
    }
  }

  getReportsListFromAPI = async token => {
    let reps = await this.props.getReports(token)
    if (this.props.isPro || this.props.isPlus) {
      await this.setState({ reports: reps })
    }
  }

  reportOpen = async selectedReportId => {
    try {
      const reportsList = [...this.state.reports] // create new array from state
      const reportToOpen =
        selectedReportId || (reportsList.length && reportsList[0].id)

      const reportObj =
        _.find(reportsList, (report, index) => report.id === reportToOpen) || {}
      if (reportObj.status === REPORT_STATUS.New) {
        await openReportAPI(reportToOpen, this.props.userToken)

        let newReports = reportsList.map(report => {
          if (report.id === reportToOpen) {
            report.status = REPORT_STATUS.pharmaOpened
          }
          return report
        })
        this.setState({ reports: newReports })
      }
    } catch (err) {
      console.log(err)
    }
  }

  handleSetReportView = async (reportId, original, minVersion) => {
    try {
      let reportsList = []
      let singleReport = {}

      await this.reportOpen(reportId)
      reportsList = this.state.reports

      PubSub.publish('REPORTS_LIST_LOADED', reportsList)

      if (!reportId && reportsList.length && reportsList[0].id) {
        this.handleSelectReport(reportsList[0].id)
        return
      }

      singleReport = await this.getSingleReport(reportId, original, minVersion)

      if (!this.props.isPro && !this.props.isPlus) {
        reportsList = [
          {
            id: _.get(singleReport, 'metaData.id'),
            primaryDrug: _.get(singleReport, 'drugs[0].name'),
            status: _.get(singleReport, 'metaData.pharmaStatus'),
            patient: {
              dateOfBirthYear: _.get(
                singleReport,
                'contacts.patient.dateOfBirthYear'
              ),
              gender: _.get(singleReport, 'contacts.patient.gender')
            },
            created: _.get(singleReport, 'metaData.dateCreated'),
            assigned: _.get(singleReport, 'metaData.dateAssigned'),
            assignedPharmaEmployeeId: _.get(
              singleReport,
              'metaData.assignedPharmaEmployeeId'
            )
          }
        ]
      }

      this.setState({
        reports: reportsList,
        singleReport: singleReport,
        reportsLoaded: true
      })
    } catch (error) {
      console.error(error)
      this.setState({
        error_code: 'Something went wrong'
      })
    }
  }

  handleOnUpdateReport = async (reportId, original = false, minVersion = 0) => {
    if (this.props.userToken && (this.props.isPro || this.props.isPlus)) {
      // cant retreive reports list when account is basic
      await this.getReportsListFromAPI(this.props.userToken)
    }
    this.handleOnFilterChange(REPORT_STATUS.QuestionsAsked, true)
    this.handleOnFilterChange(REPORT_STATUS.ReminderSent, true)
    await this.handleSetReportView(reportId, original, minVersion)
  }

  handleSelectReport = async selectedReportId => {
    this.props.history.push({
      pathname: '/reports/' + selectedReportId,
      state: { reportID: selectedReportId }
    })
  }

  handleActiveHeaderItem = item => {
    this.setState({ selectedItem: item }, () => {
      if (item === HEADERS.USER) {
        this.handleOpenProfileEditModal()
      }
    })
  }

  handleSetStep = step => {
    this.setState({ step }, () => {
      if (step === REPORT_STEPS.DoctorConfirm) {
        this.setState({ status: REPORT_STATUS.Complete })
      }
    })
  }

  handleSetStatus = status => {
    this.setState({ status })
  }

  handleOpenProfileEditModal = () => {
    this.setState({ isProfileEditModal: true })
  }

  handleCloseProfileEditModal = () => {
    this.setState({ isProfileEditModal: false })
  }

  handleCloseReportModal = () => {
    this.setState({ isReportModal: false })
  }

  handleOpenReportModal = () => {
    this.setState({ isReportModal: true })
  }

  handleSetPatientNotification = () => {
    this.setState({ isPatientNotification: !this.state.isPatientNotification })
  }

  handleOpenQuestionsModal = () => {
    this.setState({ isQuestionsModal: true })
  }

  handleBackToQuestions = () => {
    this.setState({ isConfirmModal: false, isQuestionsModal: true })
  }

  handleSubmitQuestions = () => {
    this.setState({ isConfirmModal: false, isAttentionModal: true })
  }

  handleSendQuesions = () => {
    this.setState({
      isAttentionModal: false,
      status: REPORT_STATUS.QuestionsAsked
    })
  }

  handleCloseAttentionModal = () => {
    this.setState({
      isAttentionModal: false,
      status: REPORT_STATUS.QuestionsAsked
    })
  }

  handleOpenProOffersModal = () => {}

  handleGoToRegister = () => {
    this.setState({ goToRegister: true })
  }

  handleSetProfileImg = profileImg => {
    this.setState({ profileImg })
  }

  handleScroll = event => {
    if (window.scrollY === 0 && this.state.scrolling === true) {
      this.setState({
        scrolling: false
      })
    } else if (window.scrollY !== 0 && this.state.scrolling !== true) {
      this.setState({
        scrolling: true
      })
    }
  }

  handleCloseRegisterModal = () => {
    this.setState({ onShowRegisterModal: false })
  }

  handleOpenRegisterModal = () => {
    this.setState({ onShowRegisterModal: true })
  }

  handleCloseProModal = () => {
    this.setState({ onShowTestProModal: false })
  }

  handleOpenProModal = () => {
    this.setState({ onShowTestProModal: true })
  }

  handleOnFilterChange = (filterKey, isChecked) => {
    if (filterKey.length) {
      let checkUncheckOtherFilters =
        REPORT_FILTERS[filterKey].checkUncheck || {}
      let mergedFilters = _.merge(
        {},
        this.state.activeFilters,
        { [filterKey]: isChecked },
        checkUncheckOtherFilters
      )
      this.setState({ activeFilters: mergedFilters }, function () {
        this.filterReports(this.state.activeFilters)
        this.props.persistReportFilters(
          this.state.activeFilters,
          this.props.user
        )
        this.props.MedTrackEvent(
          'PatientCase',
          'filter',
          REPORT_FILTERS[filterKey].filterType,
          1
        )
      })
    }
  }

  // TODO :: Move filters to selector
  filterReports = filters => {
    let reportsFromState = this.state.reports
    let andFilters = []
    let statusFilters = []

    for (let filterKey in filters) {
      if (filters[filterKey]) {
        if (
          filterKey === 'due-this-week' ||
          filterKey === 'due-this-month' ||
          filterKey === 'is-serious-report' ||
          filterKey === 'assigned-to-me'
        ) {
          andFilters.push(filterKey)
        } else {
          statusFilters.push(filterKey)
        }
      }
    }

    let filteredReports = reportsFromState.filter(report =>
      statusFilters.includes(report.status)
    )

    andFilters
      .map(filterKey => REPORT_FILTERS[filterKey].filterMethod)
      .forEach(
        filterFunction =>
          (filteredReports = filteredReports.filter(
            filterFunction({ loggedInUserId: this.props.userData._id })
          ))
      )

    this.setState({ filteredReports: filteredReports })
  }

  render () {
    const {
      isProfileEditModal,
      step,
      status,
      isNR,
      isQuestion,
      isReportModal,
      isPatientNotification,
      isConfirmModal,
      isAttentionModal,
      onShowRegisterModal,
      onShowTestProModal,
      goToRegister,
      singleReport,
      errorCode,
      reportsLoaded,
      filteredReports
    } = this.state

    const { t } = this.props

    if (goToRegister) {
      return <Redirect to='/register' />
    }

    if (this.props.isPlus) {
      REPORT_FILTERS['all-reports'].checkUncheck[REPORT_STATUS.Archived] = false
    }

    return (
      <>
        <ReportsContainer>
          <ProfileEditModal
            isProfileEdit={isProfileEditModal}
            onClose={this.handleCloseProfileEditModal}
            onSetProfileImg={this.handleSetProfileImg}
          />
          <ReportModal
            isReport={isReportModal}
            onClose={this.handleCloseReportModal}
          />
          <ConfirmModal
            isConfirm={isConfirmModal}
            onBack={this.handleBackToQuestions}
            onSubmit={this.handleSubmitQuestions}
          />
          <AttentionModal
            isReject={isAttentionModal}
            onCancel={this.handleCloseAttentionModal}
            onSend={this.handleSendQuesions}
          />
          <RegisterModal
            open={onShowRegisterModal}
            onCancel={this.handleCloseRegisterModal}
          />
          <ProTestingModal
            open={onShowTestProModal}
            onCancel={this.handleCloseProModal}
          />

          {this.state.loading ? (
            <StyledSpinner aria-label='loading spinner' />
          ) : (
            <ReportsMainContainer>
              <OldBrowsersPopup
                open={this.state.showOldBrowsersPopup}
                onCancel={() => this.setState({ showOldBrowsersPopup: false })}
              />
              <LeftPanel status={status} isPlus={this.props.isPlus}>
                {(this.props.isPro || this.props.isPlus) && (
                  <FilterContainer>
                    <NestedMenu
                      dataToRender={GET_NESTED_REPORT_FILTERS(
                        this.props.isPlus
                      )}
                      onFilterChanged={this.handleOnFilterChange}
                      filters={this.state.activeFilters}
                      openDialog={this.state.showFilters}
                      onCancel={() => this.setState({ showFilters: false })}
                    />
                  </FilterContainer>
                )}
                {reportsLoaded && (
                  <ReportTable
                    data={filteredReports}
                    headers={REPORT_HEADERS}
                    isNR={isNR}
                    onSelectReport={this.handleSelectReport}
                    selectedReportId={_.get(singleReport, 'metaData.id', '')}
                    status={status}
                    isPro={this.props.isPro}
                    onUpdateReport={this.handleOnUpdateReport}
                  />
                )}
              </LeftPanel>
              <RightPanel>
                {errorCode ? (
                  <NOReport>
                    <NOReportTxt>
                      {t('components.Reports.noReportsFound')}
                    </NOReportTxt>
                  </NOReport>
                ) : !filteredReports.length ? (
                  <NOReport>
                    <NOReportTxt>
                      {this.props.t('components.Reports.noReportsShown')}
                    </NOReportTxt>
                    <img src={arrow} />
                  </NOReport>
                ) : !singleReport ? (
                  <NOReport>
                    <NOReportTxt>
                      {t('components.Reports.reportIdInvalid')}
                    </NOReportTxt>
                    <img src={arrow} />
                  </NOReport>
                ) : (
                  <ReportDetail
                    isNR={isNR}
                    isPN={isPatientNotification}
                    isQuestion={isQuestion}
                    onOpenReportModal={this.handleOpenReportModal}
                    onQuestionsModal={this.handleOpenQuestionsModal}
                    onSetPatientNotification={this.handleSetPatientNotification}
                    onShowTestProModal={this.handleOpenProModal}
                    onShowRegisterModal={this.handleOpenRegisterModal}
                    onSetStatus={this.handleSetStatus}
                    onSetStep={this.handleSetStep}
                    status={status}
                    step={step}
                    report={singleReport}
                    onUpdateReport={this.handleOnUpdateReport}
                    onSwitchReport={this.handleSetReportView}
                  />
                )}
              </RightPanel>
            </ReportsMainContainer>
          )}
        </ReportsContainer>
      </>
    )
  }
}

function mapStateToProps (state, props) {
  const { isPro, isPlus } = state.proOffer
  const { user } = state.auth
  const { userData } = state.userData
  const defaultFilters = state.userSettings.filters
  return {
    isPro,
    isPlus,
    user,
    defaultFilters,
    userData,
    userToken: getUserToken(state),
    userLoggedIn: isUserLoggedIn(state)
  }
}

function mapDispatchToProps (dispatch) {
  return {
    logout: async () => {
      await dispatch(await LOGOUT_AUTH())
      await dispatch({ type: GET_USER_RESET })
      await dispatch({ type: SET_PROOFFER, payload: CARD_TYPE.Free })
    },
    setRouteError: async value => {
      await dispatch({ type: SET_ROUTE_ERROR, payload: value })
    },
    persistReportFilters: async (filters, userData) => {
      await dispatch(SET_REPORT_FILTERS(filters, userData))
    },
    getReports: async token => {
      let reportsFromApi = await dispatch(await GET_REPORTS(token))
      return reportsFromApi.payload.reports
    },
    getReport: async options => {
      let reportFromApi = await dispatch(
        await GET_REPORT(
          options.reportId,
          options.original,
          options.minVersion,
          options.token
        )
      )

      if (reportFromApi.type === 'GET_REPORTS_FAIL') {
        let error = {
          response: {
            status: reportFromApi.payload
          }
        }
        throw error
      }

      if (reportFromApi.type === 'GET_REPORTS_SUCCESS') {
        return reportFromApi.payload.report
      }
    },
    setInvalidReportIdError: () => {
      dispatch({ type: SET_INVALID_REPORT_ID_ERROR, payload: true })
    },
    setInvalidTokenError: () => {
      dispatch({ type: SET_INVALID_TOKEN_ERROR, payload: true })
    },
    setReportLinkDisabledError: () => {
      dispatch({ type: SET_REPORT_LINK_DISABLED_ERROR, payload: true })
    }
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(withMedTracker(Reports)))
)
