import React from 'react'
import styled from 'styled-components'
import * as palette from 'styles/palette'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import MedCheck from 'lib/elements/Checkbox'
import layersConfig from 'styles/layersConfig'
import { ArrowDownward, ArrowUpward } from '@material-ui/icons'

export const TableHeaderContainer = styled(props => <TableHead {...props} />)`
  &&& {
    height: auto;
    background: yellow;
    th {
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      background: white;
      z-index: ${layersConfig.levelOne};
    }
  }
`

export const TableRowContainer = styled(props => <TableRow {...props} />)`
  &&& {
    height: auto;
  }
`

export const TableHeaderCell = styled(
  ({ isCheckCell, showArrow, shouldSort, ...props }) => <TableCell {...props} />
)`
  &&& {
    padding: ${props =>
      props.isCheckCell ? '1.6rem 0.2rem' : '1.6rem 1rem 1rem 0rem'};
    box-sizing: border-box;
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: ${palette.pwdBGColor};
    height: auto;
    padding-bottom: 0.5rem;
    font-weight: 700;
    text-align: center;
    & > div > span > span > svg {
      color: ${palette.primaryDarkGrey};
      width: 1.4rem;
      height: 1.4rem;
    }
    cursor: ${props => (props.shouldSort ? 'pointer' : 'unset')};
    & > svg {
      visibility: ${props => (props.showArrow ? 'visible' : 'hidden')};
    }
    &:hover {
      & > svg {
        color: ${props => (props.showArrow ? 'unset' : palette.grey)};
        visibility: unset;
      }
    }
  }
`

export const MedCheckStyled = styled(MedCheck)`
  &&& {
    span,
    svg {
      color: ${props =>
        props.disabled === true
          ? `${palette.primarySuperLightGrey} !important`
          : ''};
    }
  }
`
export const SortingIcon = styled(props => {
  const { showArrow, shouldSort, descendingSorting, ...rest } = props

  if (shouldSort) {
    if (!descendingSorting && showArrow) {
      return <ArrowUpward {...rest} />
    } else {
      return <ArrowDownward {...rest} />
    }
  } else {
    return <></>
  }
})`
  vertical-align: sub;
`
