import React from 'react'
import styled from 'styled-components'
import { Avatar, Grid } from '@material-ui/core'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import * as palette from 'styles/palette'

export const ProfileItem = styled(props => <Grid {...props} />)`
  height: 40px;
  cursor: pointer;
`
export const StyledAvatar = styled(props => <Avatar {...props} />)`
  &&& {
    color: ${palette.primaryBackground};
    background-color: ${palette.primaryLightGreen};
    margin: 0 10px 0 0;
    float: left;
    border-radius: 30%;
  }
`

export const MainUserName = styled.span`
  margin-top: 10px;
  color: ${palette.navy};
  font-weight: 600;
`

export const UserName = styled.span`
  margin-top: 5px;
  color: ${palette.navy};
  font-weight: 600;
  font-size: 0.8vw;
`
export const ExpandMoreStyled = styled(props => <ExpandMore {...props} />)`
  &&& {
    float: left;
    margin: 5px 0 0 10px;
    font-size: 24px;
  }
`
export const ExpandLessStyled = styled(props => <ExpandLess {...props} />)`
  &&& {
    float: left;
    margin: 5px 0 0 10px;
    font-size: 24px;
  }
`

export const ProfileContainer = styled.div`
  margin: 10px;
  height: 70px;
  :focus {
    outline: none !important;
  }
`
export const UserRole = styled(UserName)``
export const UserEmail = styled.span`
  color: ${palette.primaryDarkRed};
  font-size: 10px;
`

export const Divider = styled.span`
  float: left;
  margin: 4px 5px 0 5px;
`

export const MenuOption = styled.span`
  width: 100%;
  font-size: 19px;
  color: ${palette.primaryDarkGreen};
  font-weight: 300;
`

export const TeamCount = styled.span`
  color: ${palette.primaryDarkGreen};
`
