import React from 'react'
import styled from 'styled-components'
import * as palette from 'styles/palette'
import Input from 'lib/elements/Input'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'

export const InputLabel = styled.span`
  color: ${palette.dlgFontColor};
  display: block;
  margin-top: 1.1rem;
  padding-bottom: 6px;
`

export const InputBox = styled(Input)`
  &&& {
    border: 1px solid ${palette.primarySuperLightGreen};
    margin: 0;
    padding-left: 1rem !important;
    &::placeholder {
      color: ${palette.textAreaColor} !important;
    }
  }
`

export const InputBoxForOpacity = InputBox.extend`
  &&& {
    opacity: 0.3;
    &:focus {
      opacity: 1;
    }
  }
`

export const MenuBtn = styled(props => <Button {...props} />)`
  &&& {
    width: 100%;
    border: 1px solid ${palette.primaryLightGreen};
    border-radius: 0.5rem;
    padding: 0 2rem;
    padding-right: 0;
    min-height: 32px;
    height: 32px;
    & > span {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: ${palette.primaryLightGreen};
      text-transform: none;
    }
  }
`

export const Asterisk = styled.span`
  color: ${palette.primaryDarkRed};
`

export const TextBox = styled.textarea`
  &&& {
    height: 7.3rem;
    border-radius: 0.5rem;
    border: 1px solid ${palette.textAreaColor};
    width: 100%;
    margin-top: 1.1rem;
    padding: 0.8rem 1.2rem;
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
  }
`

export const HiddenCommentBtn = styled(props => <CloseIcon {...props} />)`
  padding: 0.3rem;
  border: 1px solid ${palette.primaryLightGreen};
  border-radius: 0.5rem;
  color: ${palette.primaryLightGreen};
  margin-left: 0.9rem;
  &:hover {
    cursor: pointer;
    border-color: ${palette.primaryDarkGreen};
    color: ${palette.primaryDarkGreen};
  }
`

export const DisplayFlex = styled.div`
  display: flex;
`
