import React from 'react'
import styled from 'styled-components'
import * as palette from 'styles/palette'
import Input from 'lib/elements/Input'
import Button from '@material-ui/core/Button'

export const InputLabel = styled.span`
  color: ${palette.dlgFontColor};
  display: block;
  margin-top: 1.1rem;
  padding-bottom: 6px;
`

export const InputBox = styled(Input)`
  &&& {
    border: 1px solid ${palette.primarySuperLightGreen};
    margin: 0;
    padding-left: 1rem !important;
    &::placeholder {
      color: ${palette.textAreaColor} !important;
    }
  }
`

export const InputBoxForOpacity = InputBox.extend`
  &&& {
    opacity: 0.3;
    &:focus {
      opacity: 1;
    }
  }
`

export const MenuBtn = styled(props => <Button {...props} />)`
  &&& {
    width: 100%;
    border: 1px solid ${palette.primaryLightGreen};
    border-radius: 0.5rem;
    padding: 0 2rem;
    padding-right: 0;
    min-height: 32px;
    height: 32px;
    & > span {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: ${palette.primaryLightGreen};
      text-transform: none;
    }
  }
`

export const Asterisk = styled.span`
  color: ${palette.primaryDarkRed};
`

export const GenderBtnGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const GenderBtn = styled.span`
  display: flex;
  align-items: center;
  color: ${props =>
    props.isActive ? palette.white : palette.primaryLightGreen};
  background-color: ${props =>
    props.isActive ? palette.primaryLightGreen : palette.white};
  border: 1px solid ${palette.primaryLightGreen};
  border-radius: 0.5rem;
  padding: 0.7rem 0;
  width: 10.93rem;
  margin-right: 0.5rem;
  text-align: center;
  justify-content: center;

  &:last-child {
    magin-right: 0;
  }
  &:hover {
    cursor: pointer;
  }
  svg {
    padding-left: 0.5rem;
    & > path {
      fill: ${props =>
        props.isActive ? palette.white : palette.primaryLightGreen};
    }
  }
`
