import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'react-router-redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import createExpirationTransform from 'redux-persist-transform-expire'

import { history } from 'routing/index'
import reducers from 'reducers'

// TODO: Structure the reducer data model properly, like move userData inside auth.user.profile
// and proOffer inside auth.user.account

const expireTransform = createExpirationTransform({
  expireKey: 'persistExpiresAt'
})

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['auth', 'userData', 'proOffer', 'userSettings', 'pharmaCompany'],
  transforms: [expireTransform]
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const configureStore = () => {
  const thunkApplied = applyMiddleware(thunk)
  const routerMiddlewareApplied = applyMiddleware(routerMiddleware(history))
  let enhancers
  if (process.env.NODE_ENV === 'development') {
    const { composeWithDevTools } = require('redux-devtools-extension')
    enhancers = composeWithDevTools(thunkApplied, routerMiddlewareApplied)
  } else {
    enhancers = compose(thunkApplied, routerMiddlewareApplied)
  }
  const store = createStore(persistedReducer, enhancers)
  const persistor = persistStore(store)
  return { store, persistor }
}
