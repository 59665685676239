import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ConnectedRouter as Router } from 'react-router-redux'
import { APPLICATION_INIT } from 'actions/common/index'
import App from '../App'

export default class Root extends Component {
  static propTypes = {
    store: PropTypes.object,
    history: PropTypes.object,
    routes: PropTypes.array
  }

  state = {
    showLoader: false
  }

  componentWillMount () {
    const { store } = this.props
    store.dispatch({ type: APPLICATION_INIT })
  }

  render () {
    const { store, history, routes, persistor } = this.props
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={history}>
            <App routes={routes} />
          </Router>
        </PersistGate>
      </Provider>
    )
  }
}
