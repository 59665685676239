import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import auth from './auth'
import reset from './reset'
import register from './register'
import reports from './reports'
import sideEffects from './sideEffects'
import proOffer from './proOffer'
import team from './team'
import userData from './userData'
import pharmaCompany from './pharmaCompany'
import userSettings from './userSettings'
import route from './route'

// Create root reducer
export default combineReducers({
  auth: auth,
  register: register,
  reset: reset,
  reports: reports,
  sideEffects: sideEffects,
  proOffer: proOffer,
  team: team,
  routing: routerReducer,
  userData: userData,
  pharmaCompany: pharmaCompany,
  route: route,
  userSettings: userSettings
})
