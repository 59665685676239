import React, { Component } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import I18n from 'i18n'
import {
  MenuLineItem,
  MenuItemTxt,
  CustomMenu,
  StyledButtonContainer
} from './style'

class MenuItemComponent extends Component {
  state = {
    anchorEl: null
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render () {
    const {
      menuId,
      handleMenuItemClick,
      Icon,
      text,
      showIconOnDropdown,
      inStatuses,
      showTooltip
    } = this.props
    const { anchorEl } = this.state

    return (
      <>
        {(showTooltip && (
          <Tooltip
            title={<span>{I18n.t('components.LoopDropDownMenu.delete')}</span>}
          >
            <StyledButtonContainer>
              <Icon onClick={this.handleClick} />
            </StyledButtonContainer>
          </Tooltip>
        )) || (
          <StyledButtonContainer>
            <Icon onClick={this.handleClick} />
          </StyledButtonContainer>
        )}
        <CustomMenu
          inStatuses={inStatuses}
          anchorEl={anchorEl}
          keepMounted
          id={menuId}
          onClose={this.handleClose}
          open={Boolean(anchorEl)}
        >
          <MenuLineItem
            onClick={e => handleMenuItemClick(e, this.handleClose.bind(this))}
          >
            <MenuItemTxt>
              {showIconOnDropdown && (
                <>
                  <Icon />
                  <span style={{ width: '5px' }} />
                </>
              )}
              <span>{text}</span>
            </MenuItemTxt>
          </MenuLineItem>
        </CustomMenu>
      </>
    )
  }
}

export default MenuItemComponent
