import {
  PASSWORD_RESET_PENDING,
  PASSWORD_RESET_FAIL,
  PASSWORD_RESET_SUCCESS
} from 'actions'

const initialState = {
  errors: {},
  isResettingPassword: false,
  // isChangingPassword: false,
  tokenGenerated: false
  // passChanged: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PASSWORD_RESET_PENDING: {
      return {
        ...state,
        isResettingPassword: true,
        tokenGenerated: false
      }
    }
    case PASSWORD_RESET_FAIL: {
      const { errors } = action.payload
      return {
        ...state,
        isResettingPassword: false,
        tokenGenerated: false,
        errors
      }
    }
    case PASSWORD_RESET_SUCCESS: {
      return {
        ...state,
        isResettingPassword: false,
        tokenGenerated: true,
        errors: {}
      }
    }
    default: {
      return state
    }
  }
}
