import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import validator from 'validator'
import passwordMeter from 'passwordmeter'
import { withTranslation, Trans } from 'react-i18next'
import {
  LabelTxt,
  SaveBtn,
  CancelBtn,
  FileUploadContainer,
  FileUploadBtn,
  FileUploadInnerBtn,
  CircleCloseBtn,
  ImageContainer,
  ProfileImg,
  PreviewImg,
  PreviewContainer,
  FileNameText,
  DescriptionContainer,
  Description,
  InputBox,
  ProfileInputContainer,
  CompanyText
} from './style'

import Dialog from '@material-ui/core/Dialog'
import ContentHeader from 'lib/elements/ContentHeader'
import Content from 'lib/elements/Content'
import DlgActions from 'lib/elements/DlgActions'

import photoImg from '../../assets/img/profileImg.png'

class ProfileEditModal extends Component {
  static propTypes = {
    isProfileEdit: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSetProfileImg: PropTypes.func.isRequired
  }

  state = {
    isChecked: false,
    isDoctor: false,
    goToConfirm: false,

    files: [],
    doctorFile: null,

    // Form values
    fname: '',
    lname: '',
    email: '',
    password: '',
    confirmpassword: '',
    isFocusOnPwd: false,
    strength: 0,

    isMouseOnImage: false,

    // validations
    isValid: {
      fname: true,
      lname: true,
      email: true,
      password: true,
      confirmpassword: true,
      zipcode: true,
      professional: true,
      areaOfExpertise: true,
      additionalDesignation: true,
      isChecked: true,
      hasFile: false
    }
  }

  handleChangeFile = ev => {
    const { isValid } = this.state
    const filesToUpload = ev.target.files
    const doctorFile = filesToUpload[0]
    if (doctorFile) {
      isValid.hasFile = true
      this.setState({ doctorFile, isValid })
    }
  }

  handleSaveProfile = () => {
    if (this.state.files[0] && this.state.files[0].preview) {
      this.props.onSetProfileImg(this.state.files[0].preview)
    }
    this.props.onClose()
  }

  handleMouseHover = () => {
    this.setState({ isMouseOnImage: true })
  }

  handleMouseLeave = () => {
    this.setState({ isMouseOnImage: false })
  }

  handleDrop = files => {
    this.setState({ files })
  }

  handleCloseImg = () => {
    this.setState({ files: [] })
  }

  handleChangePassword = event => {
    const { isValid } = this.state
    this.setState({ password: event.currentTarget.value }, () => {
      if (
        validator.isEmpty(this.state.password) ||
        Math.floor(passwordMeter.checkPass(this.state.password, 8) / 25) < 1
      ) {
        isValid.password = false
      } else {
        isValid.password = true
      }
      this.setState({
        isValid,
        strength: passwordMeter.checkPass(this.state.password, 8)
      })
    })
  }

  handleChangeConfirmPassword = event => {
    const { isValid } = this.state
    this.setState({ confirmpassword: event.currentTarget.value }, () => {
      if (
        validator.isEmpty(this.state.confirmpassword) ||
        this.state.password !== this.state.confirmpassword
      ) {
        isValid.confirmpassword = false
      } else {
        isValid.confirmpassword = true
      }
      this.setState({ isValid })
    })
  }

  isValidation = () => {
    const {
      fname,
      lname,
      email,
      password,
      confirmpassword,
      isChecked,
      file,
      isValid
    } = this.state

    if (!fname.toString().trim().length) {
      isValid.fname = false
    }

    if (!lname.toString().trim().length) {
      isValid.lname = false
    }

    if (!validator.isEmail(email)) {
      isValid.email = false
    }

    if (
      !password.toString().trim().length ||
      Math.floor(passwordMeter.checkPass(password, 8) / 25) < 1
    ) {
      isValid.password = false
    }

    if (
      !confirmpassword.toString().trim().length ||
      password !== confirmpassword
    ) {
      isValid.confirmpassword = false
    }

    if (!isChecked) {
      isValid.isChecked = false
    }

    if (!file) {
      isValid.hasFile = false
    }

    const formValid =
      isValid.fname &&
      isValid.lname &&
      isValid.email &&
      isValid.password &&
      isValid.confirmpassword &&
      isValid.professional &&
      isValid.additionalDesignation &&
      isValid.areaOfExpertise &&
      isValid.zipcode &&
      isValid.isChecked &&
      isValid.isChecked

    this.setState({ isValid })

    return formValid
  }

  handlePwdFocus = () => {
    this.setState({ isFocusOnPwd: true })
  }

  handlePwdBlur = () => {
    this.setState({ isFocusOnPwd: false })
  }

  render () {
    const { isProfileEdit, onClose, t } = this.props
    const { isValid, files, isMouseOnImage } = this.state
    return (
      <Dialog
        aria-describedby='alert-dialog-description'
        aria-labelledby='alert-dialog-title'
        className='abc'
        onClose={onClose}
        open={isProfileEdit}
      >
        <Content>
          <ContentHeader>
            {t('components.ProfileEditModal.header')}
          </ContentHeader>
          <LabelTxt>{t('components.ProfileEditModal.label')}</LabelTxt>
          <FileUploadContainer>
            <Dropzone className='dropzone-container' onDrop={this.handleDrop}>
              <FileUploadBtn
                onMouseEnter={this.handleMouseHover}
                onMouseLeave={this.handleMouseLeave}
              >
                <ImageContainer dotted={isMouseOnImage}>
                  <FileUploadInnerBtn onMouseEnter={this.handleMouseHover} />
                  {isMouseOnImage && <ProfileImg src={photoImg} />}
                </ImageContainer>
              </FileUploadBtn>
            </Dropzone>
            {files.length > 0 && (
              <PreviewContainer>
                <PreviewImg src={files[0].preview} />
                <CircleCloseBtn onClick={this.handleCloseImg} />
              </PreviewContainer>
            )}
            <DescriptionContainer>
              <Description>
                <Trans
                  i18nKey='components.ProfileEditModal.description'
                  values={{
                    break: '<br />'
                  }}
                />
              </Description>
              <FileNameText>{files.length > 0 && files[0].name}</FileNameText>
            </DescriptionContainer>
          </FileUploadContainer>
          <ProfileInputContainer>
            <LabelTxt>
              {t('components.ProfileEditModal.firstName.label')}
            </LabelTxt>
            <InputBox
              placeholder={t(
                'components.ProfileEditModal.firstName.placeholder'
              )}
            />
          </ProfileInputContainer>
          <ProfileInputContainer>
            <LabelTxt>
              {t('components.ProfileEditModal.lastName.label')}
            </LabelTxt>
            <InputBox
              placeholder={t(
                'components.ProfileEditModal.lastName.placeholder'
              )}
            />
          </ProfileInputContainer>
          <ProfileInputContainer>
            <LabelTxt>{t('components.ProfileEditModal.email.label')}</LabelTxt>
            <InputBox
              placeholder={t('components.ProfileEditModal.email.placeholder')}
            />
          </ProfileInputContainer>
          <ProfileInputContainer>
            <LabelTxt>
              {t('components.ProfileEditModal.changePassword')}
            </LabelTxt>
            <ProfileInputContainer style={{ paddingTop: 0 }}>
              <InputBox
                hasError={!isValid.password}
                name='password'
                onBlur={this.handlePwdBlur}
                onChange={this.handleChangePassword}
                onFocus={this.handlePwdFocus}
                placeholder={t('components.ProfileEditModal.oldPassword')}
                type='password'
              />
              <InputBox
                hasError={!isValid.confirmpassword}
                name='confirmpassword'
                onChange={this.handleChangeConfirmPassword}
                placeholder={t('components.ProfileEditModal.newPassword')}
                type='password'
              />
            </ProfileInputContainer>
          </ProfileInputContainer>
          <ProfileInputContainer>
            <LabelTxt>{t('components.ProfileEditModal.companyName')}</LabelTxt>
            <CompanyText>
              {t('components.ProfileEditModal.medikurix')}
            </CompanyText>
          </ProfileInputContainer>
        </Content>
        <DlgActions>
          <SaveBtn onClick={this.handleSaveProfile} variant='contained'>
            {t('components.ProfileEditModal.saveButton')}
          </SaveBtn>
          <CancelBtn onClick={onClose} variant='contained'>
            {t('components.ProfileEditModal.cancelButton')}
          </CancelBtn>
        </DlgActions>
      </Dialog>
    )
  }
}

export default withTranslation()(ProfileEditModal)
