import React from 'react'
import styled, { css } from 'styled-components'
import * as palette from 'styles/palette'
import Menu from '@material-ui/core/Menu'

export const MenuList = styled(props => <Menu {...props} />)`
  &&& {
    & > div {
      border-radius: 0.7rem;
      ul {
        width: ${props => props.width};
        & > li {
          padding: ${props =>
            props.buttonId === 'role-btn' ? '0' : '16px 12px'};
          font-size: ${props =>
            props.buttonId === 'role-btn' ? '.6rem' : '1.2rem'};
          border-bottom: 0.25px solid;
          line-height: ${props =>
            props.buttonId === 'role-btn' ? '.8rem' : '1.6rem'};
          border-color: rgba(17, 121, 130, 0.3);
          color: ${props =>
            props.buttonId === 'role-btn'
              ? palette.primaryDarkGrey
              : palette.primaryDarkGreen};
          display: flex;
          justify-content: center;
          box-sizing: border-box;
          ${props =>
            props.buttonId === 'role-btn' &&
            css`
              width: 65px;
            `};
          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
  }
`
