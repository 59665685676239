export const DOSAGE_UNITS = {
  piece: 'Stück',
  ml: 'Milliliter',
  tablet: 'Tablette(n)',
  injection: 'Injektion(en)',
  suppository: 'Zäpfchen',
  capsule: 'Kapsel(n)',
  drop: 'Tropfen',
  mg: 'Milligramm',
  puff: 'Hübe/Sprühstöße',
  ug: 'Mikrogramm',
  units: 'Einheiten'
}

export const INTERVALS = {
  once: 'ein einziges Mal',
  hourly: 'Stunde',
  daily: 'Tag',
  weekly: 'Woche',
  monthly: 'Monat',
  when_needed: 'bei Bedarf'
}

export const EFFECTS_STATI = {
  unknown: 'Unbekannt',
  recovering: 'Verbessert',
  recovered: 'Wiederhergestellt',
  ongoing: 'Andauernd',
  worsened: 'Verschlechtert',
  recovered_with_sequelae: 'Wiederhergestellt mit bleibenden Schäden',
  fatal: 'Tödlich'
}

export const DURATIONS = {
  hours: 'Stunden',
  days: 'Tage',
  weeks: 'Wochen',
  months: 'Monate'
}

export const CAUSALITIES = {
  certain: 'Gesichert',
  likely: 'Wahrscheinlich',
  possible: 'Möglich',
  unlikely: 'Unwahrscheinlich',
  unknown: 'Nicht zu beurteilen',
  none: 'Kein Zusammenhang'
}

export const HCP_STATUS = {
  VERIFIED: ['hcp-report-verified'],
  NOT_VERIFIED: [
    'hcp-not-included',
    'hcp-declined',
    'hcp-report-skipped',
    'hcp-report-could-not-be-verified'
  ],
  PENDING: ['hcp-included', 'hcp-assigned', 'hcp-confirmed']
}

export const BROWSER_SUPPORT = {
  chrome: '>=73',
  safari: '>=12',
  firefox: '>=66',
  'Microsoft Edge': '>=17',
  'internet explorer': '=11',
  opera: '>=58'
}
