import styled from 'styled-components'
import * as palette from 'styles/palette'

export const Title = styled.div`
  padding-top: 2.7rem;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 600;
  color: ${palette.primaryDarkRed};
`
