import styled from 'styled-components'
import * as palette from 'styles/palette'
import MedButton from 'lib/elements/MedButton'
import { LoginFormContainer } from 'components/Login/style'

export const ResetPasswordContainer = styled(LoginFormContainer)`
  &&& {
    width: 600px;
    min-height: 180px;
  }
`

export const ButtonsContainer = styled.div`
  margin-top: ${props => (props.goForward ? '0' : '1rem')};
  display: flex;
  justify-content: ${props => (props.goForward ? 'center' : 'space-between')};
`

export const BackButton = styled(MedButton)`
  &&& {
    margin-top: 2.4rem;
    background-color: ${palette.primaryDarkRed};
    &:hover {
      background-color: ${palette.primaryLightRed};
    }
    padding: 1rem 2.5rem;
  }
`

export const SuccessMessage = styled.div`
  background-color: rgba(230, 123, 66, 0.1);
  border: 1px solid transparent;
  border-color: rgba(230, 123, 66, 0.2);
  color: ${palette.orange};
  margin-top: 2rem;
  padding: 1rem;
  font-size: 1.4rem;
  text-align: left;
`

export const ErrorMessage = styled.div`
  background-color: rgba(230, 123, 66, 0.1);
  border: 1px solid ${palette.errorOrangeColor};
  color: ${palette.orange};
  margin-top: 2rem;
  padding: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.9rem;
  text-align: left;
`
