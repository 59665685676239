import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'i18n'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { withStyles } from '@material-ui/core'
import { StyledTextField } from './style'

const StyledAutocomplete = withStyles({
  root: {
    width: '350px'
  },
  option: {
    fontSize: '13px'
  },
  popper: {
    zIndex: '9999999'
  }
})(Autocomplete)

const SearchBar = ({ handleOnChange, id, options, getOptionLabel }) => {
  let searchbarValue = ''
  return (
    <StyledAutocomplete
      id={id}
      options={options}
      onChange={(event, report) => report && handleOnChange(event, report.id)}
      getOptionLabel={getOptionLabel}
      noOptionsText={I18n.t('components.Searchbar.noOptionsText')}
      renderInput={params => (
        <StyledTextField
          {...params}
          placeholder={I18n.t('components.Searchbar.placeholder')}
          variant='standard'
          input={searchbarValue}
          onChange={event => (searchbarValue = event.target.value)}
          onKeyPress={event => {
            if (event.key === 'Enter') {
              handleOnChange(event, searchbarValue)
            }
          }}
        />
      )}
    />
  )
}

SearchBar.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  options: PropTypes.array
}

export default SearchBar
