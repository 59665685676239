import styled from 'styled-components'
import * as palette from 'styles/palette'

const Label = styled.label`
  color: ${palette.primaryDarkGreen};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
`

export default Label
