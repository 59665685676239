import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import { MenuList } from './style'

class DropDownMenu extends Component {
  static propTypes = {
    anchorEl: PropTypes.object,
    dropdownList: PropTypes.array.isRequired,
    onHandleClose: PropTypes.func.isRequired,
    menuId: PropTypes.string.isRequired,
    buttonId: PropTypes.string.isRequired
  }

  state = {
    width: ''
  }

  componentDidlMount () {
    const buttonItem = document.getElementById(this.props.buttonId)
    this.setState({ width: `${buttonItem.offsetWidth}px` })
  }

  render () {
    const {
      anchorEl,
      dropdownList,
      onHandleClose,
      menuId,
      buttonId,
      isDisabled
    } = this.props
    return (
      <MenuList
        anchorEl={anchorEl}
        buttonId={buttonId}
        id={menuId}
        onClose={() => onHandleClose('close')}
        open={Boolean(anchorEl) && !isDisabled}
        width={this.state.width}
      >
        {dropdownList.map((item, index) => (
          <MenuItem key={index} onClick={() => onHandleClose(item)}>
            {item}
          </MenuItem>
        ))}
      </MenuList>
    )
  }
}

export default DropDownMenu
