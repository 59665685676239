import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { routes } from '../../routing'
import { connect } from 'react-redux'
import Default from 'components/Default'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import * as palette from 'styles/palette'
import { MedTrackingProvider } from 'services/tracking'

import { isUserLoggedIn, getUserId } from 'selectors/user'
import {
  getCompanyDetails,
  getCompanySubscriptionType
} from 'selectors/company'

const theme = createMuiTheme({
  overrides: {
    MuiChip: {
      colorPrimary: {
        backgroundColor: palette.primaryVeryLightGreen
      }
    },
    MuiButton: {
      root: {
        fontFamily: `'Open Sans', sans-serif !important`
      }
    },
    MuiFormLabel: {
      root: {
        fontFamily: `'Open Sans', sans-serif !important`
      }
    },
    MuiTableCell: {
      root: {
        fontFamily: `'Open Sans', sans-serif !important`
      }
    },
    MuiDrawer: {
      paper: {
        top: '66px'
      }
    },
    MuiInput: {
      underline: {
        '&:before': {
          transition: 'none',
          position: 'fixed',
          border: 'none'
        },
        '&:after': {
          transition: 'none',
          position: 'fixed',
          borderBottom: 'none'
        }
      }
    },
    MuiAutocomplete: {
      endAdornment: {
        top: 'calc(62% - 14px)'
      }
    }
    // TODO: add all material-ui stuff to override
  }
})
class App extends Component {
  static propTypes = {
    userData: PropTypes.object,
    userLoggedIn: PropTypes.bool
  }

  async componentWillMount () {
    console.info(`Pipeline id: ${process.env.REACT_APP_CI_PIPELINE_ID}`)
  }

  render () {
    const trackingConfig = {
      userId: this.props.userId,
      companySubscriptionType: this.props.companySubscriptionType
    }

    const elements = routes.map((item, index) => {
      const { path, exact, component } = item
      const routeProps = { path, exact }
      return <Route key={index} {...routeProps} component={component} />
    })
    // When no route match redirect to default
    elements.push(<Route key={elements.length} component={Default} />)

    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <MedTrackingProvider config={trackingConfig}>
            <Switch>{elements}</Switch>
          </MedTrackingProvider>
        </MuiThemeProvider>
      </div>
    )
  }
}

function mapStateToProps (state) {
  const { userData } = state.userData
  const companyData = getCompanyDetails(state)
  const userLoggedIn = isUserLoggedIn(state)
  const { proOffer } = state
  return {
    proOffer,
    userData,
    companyData,
    userLoggedIn,
    userId: getUserId(state),
    companySubscriptionType: getCompanySubscriptionType(state)
  }
}

export default withRouter(connect(mapStateToProps, null)(App))
