import React from 'react'

import './style.css'

const ContainerWrapper = props => {
  const classes = `container-wrapper ${
    props.isNavOpen
      ? 'container-wrapper__shrink'
      : 'container-wrapper__full_width'
  }`
  return <div className={classes}>{props.children}</div>
}

export default ContainerWrapper
