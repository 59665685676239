import React from 'react'
import styled from 'styled-components'
import * as palette from 'styles/palette'
import * as layersConfig from 'styles/layersConfig'
import DialogActions from '@material-ui/core/DialogActions'
import MedButton from 'lib/elements/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

export const WarningContainer = styled(props => <div {...props} />)`
  display: ${props => !props.open && 'none'};
  position: absolute;
  position: fixed;
  z-index: ${layersConfig.levelFour};
  width: 100%;
  top: 0px;
  left: 0px;
  border-bottom: 1px solid ${palette.primaryLightOrange};
  text-align: center;
  color: ${palette.black};
  background-color: ${palette.PrimaryPaleOrange};
  font: 18px Calibri, Helvetica, sans-serif;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
`
export const Content = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`
export const ContentTitle = styled.span`
  line-height: 1.3rem;
  font-size: medium;
  color: ${palette.primaryDarkGreen};
`

export const ContentText = styled.div`
  line-height: 1.8rem;
  font-size: 14px;
  color: ${palette.black};
`

export const ContentColoredText = styled.div`
  line-height: 1.8rem;
  font-size: 12px;
  color: ${palette.primaryLightGreen};
`

export const DialogAction = styled(props => <DialogActions {...props} />)`
  &&& {
    margin: 0em;
    padding-top: 0em;
    display: flex;
    justify-content: center;
  }
`

export const YesBtn = styled(MedButton)`
  &&& {
    background-color: ${palette.primaryDarkGreen};
    &:hover {
      background-color: ${palette.primaryLightGreen};
    }
    &:disabled {
      background-color: ${palette.primarySuperLightGreen};
    }
  }
`

export const CancelBtn = styled(MedButton)`
  &&& {
    width: 170px;
    background-color: ${palette.primaryLightOrange};
    cursor: pointer;
    &:hover {
      background-color: ${palette.primaryLightRed};
    }
    &:disabled {
      background-color: ${palette.primaryLightRed};
    }
  }
`
export const IgnoreBtn = styled(MedButton)`
  &&& {
    width: 84px;
    background-color: ${palette.primaryLightOrange};
    cursor: pointer;
  }
`

export const ReportHeader = styled.p`
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: ${palette.primaryDarkGreen};
  margin: 0;
  margin-bottom: 1.3rem;
`

export const AlertHeader = styled(ReportHeader)`
  color: ${palette.primaryDarkRed};
`

export const LoadingSpinner = styled(props => <CircularProgress {...props} />)`
  &&& {
    margin-right: 0.8rem;
    color: ${palette.white};
  }
`
