import React, { useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import TreeView from '@material-ui/lab/TreeView'
import TreeItem from '@material-ui/lab/TreeItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Menu from '@material-ui/core/Menu'
import IconButton from '@material-ui/core/IconButton'
import FilterListIcon from '@material-ui/icons/FilterList'
import * as palette from 'styles/palette'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

const StyledMenu = withStyles({
  paper: {
    top: '130px !important',
    minWidth: '250px',
    padding: '10px',
    border: `1px solid ${palette.primarySuperLightGrey}`
  }
})(props => <Menu {...props} />)

const useStylesView = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  itemIconRoot: {
    '& .MuiSvgIcon-root': {
      fontSize: '2.5rem'
    }
  },
  itemGroup: {
    marginLeft: theme.spacing(1)
  },
  itemContent: {
    borderRadius: '0px 20px 20px 0px'
  },
  label: {
    fontSize: '1.3rem'
  },
  teaserText: {
    fontSize: '1rem',
    fontWeight: 600,
    color: palette.primaryDarkGreen,
    verticalAlign: 'super',
    marginLeft: -12
  }
}))

export default function NestedMenu (props) {
  const dataToRender = props.dataToRender
  const defaultFilters = props.filters
  // filtersState is used internally, to handle checking and unchecking of checkboxes (filters)
  const [filtersState, setFiltersState] = React.useState(defaultFilters)
  const [anchorEl, setAnchorEl] = React.useState(null)

  useEffect(() => {
    setFiltersState(props.filters)
  }, [props.filters])

  const handleCheckboxChange = event => {
    setFiltersState({
      ...filtersState,
      [event.target.name]: event.target.checked
    })
    props.onFilterChanged(event.target.name, event.target.checked)
  }

  const treeViewClasses = useStylesView()
  const renderTree = nodes => {
    return nodes.map(node => (
      <TreeItem
        key={node.id}
        nodeId={node.id}
        classes={{
          iconContainer: treeViewClasses.itemIconRoot,
          group: treeViewClasses.itemGroup,
          content: treeViewClasses.itemContent
        }}
        label={
          (!node.id.includes('root') && (
            <>
              <FormControlLabel
                value={node.id}
                name={node.id}
                checked={filtersState[node.id]}
                classes={{
                  label: treeViewClasses.label
                }}
                control={
                  <Checkbox
                    name={node.id}
                    onChange={handleCheckboxChange}
                    {...(!node.disabled && {
                      style: {
                        color: palette.primaryLightGreen
                      }
                    })}
                    value={node.id}
                  />
                }
                disabled={node.disabled}
                label={node.name}
                labelPlacement='end'
              />
              {node.showPremiumTeaser && (
                <span className={treeViewClasses.teaserText}>
                  {node.premiumTeaserText}
                </span>
              )}
            </>
          )) || <span>{node.name}</span>
        }
      >
        {Array.isArray(node.children) ? renderTree(node.children) : null}
      </TreeItem>
    ))
  }

  return (
    <>
      <IconButton
        aria-label='filter reports'
        style={{ padding: '5px', float: 'right' }}
        aria-haspopup='true'
        onClick={event => setAnchorEl(event.currentTarget)}
        variant='contained'
      >
        <FilterListIcon style={{ fontSize: '2rem' }} />
      </IconButton>
      <StyledMenu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <TreeView
          className={treeViewClasses.root}
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          defaultExpanded={['root']}
        >
          {renderTree(dataToRender)}
        </TreeView>
      </StyledMenu>
    </>
  )
}
