import { get as gv } from 'lodash'
import {
  INTERVALS,
  DOSAGE_UNITS,
  EFFECTS_STATI,
  HCP_STATUS
} from '../constants'
import moment from 'moment'

const formatDose = (item, defaultValue = '') => {
  const amount = gv(item, 'dose.amount', '')
  if (!amount) return defaultValue

  const unit = gv(DOSAGE_UNITS, gv(item, 'dose.unit', ''), '')
  const interval = gv(INTERVALS, gv(item, 'dose.interval', ''), '')

  if (interval === 'ein einziges Mal' || interval === 'bei Bedarf') {
    return `${amount} ${unit} ${interval}`
  } else {
    return `${amount} ${unit} ${interval && 'pro ' + interval}`
  }
}

const formatIndication = (item, defaultValue = '') => {
  const name = gv(item, 'indications.0.name', '')
  if (!name) return defaultValue

  const code = gv(item, 'indications.0.codes.icd10Code', '')

  return name + (code ? ` (${code})` : '')
}

const formatDuration = (item, defaultValue = 'K. A.') => {
  const startDateFormat =
    gv(item, 'startDate', '').split('-').length === 2 ? 'MM.YYYY' : 'DD.MM.YYYY'
  const endDateFormat =
    gv(item, 'endDate', '').split('-').length === 2 ? 'MM.YYYY' : 'DD.MM.YYYY'

  const startDate = moment(gv(item, 'startDate', ''), 'YYYY-MM-DD')
  const endDate = moment(gv(item, 'endDate', ''), 'YYYY-MM-DD')

  if (startDate.isValid()) {
    return endDate.isValid()
      ? `${startDate.format(startDateFormat)} - ${endDate.format(
          endDateFormat
        )}`
      : `Seit ${startDate.format(startDateFormat)}`
  } else if (endDate.isValid()) {
    return `Bis ${endDate.format(endDateFormat)}`
  } else {
    return defaultValue
  }
}

// This is NOT the status like "neu", "bearbeitet", but like "ongoing", "recovered"
const formatStatus = (item, defaultValue = '') => {
  return gv(EFFECTS_STATI, gv(item, 'status', ''), defaultValue)
}

const formatHCPConfirmation = report => {
  const status = gv(report, 'metaData.hcpStatus')
  if (HCP_STATUS.VERIFIED.includes(status) === true) {
    return 'yes'
  } else if (HCP_STATUS.PENDING.includes(status) === true) {
    return 'pending'
  } else {
    return 'no'
  }
}

const formatContactConfirmation = report => {
  if (formatHCPConfirmation(report) === 'yes') {
    return 'not_released'
  } else {
    return 'default'
  }
}

const formatName = (report, defaultValue = '') => {
  const firstName = gv(report, 'contacts.patient.identity.name.firstName', '')
  const lastName = gv(report, 'contacts.patient.identity.name.lastName', '')
  const name = [firstName, lastName].filter(x => !!x).join(' ')
  return name || defaultValue
}

const formatGender = (report, letterOnly = false, defaultValue = '') => {
  const gender = gv(
    report,
    'contacts.patient.gender',
    gv(report, 'patient.gender', '')
  ).toUpperCase()
  if (!gender) return defaultValue

  const genderName = gender.startsWith('F')
    ? 'weiblich'
    : gender.startsWith('M')
    ? 'männlich'
    : 'divers'

  return letterOnly ? genderName.slice(0, 1).toLowerCase() : genderName
}

const findPrimaryDrug = report => {
  const filtered = gv(report, 'drugs', []).find(x => x.primary)
  return filtered || gv(report, 'drugs.0', null)
}

const formatAge = report => {
  const birthDate = moment(
    gv(report, 'contacts.patient.identity.dateOfBirth', ''),
    'YYYY-MM-DD'
  )
  return birthDate.isValid() ? moment().diff(birthDate, 'years') : ''
}

const formatBirthDate = (report, format = 'DD.MM.YYYY', defaultValue = '') => {
  const birthDate = moment(
    gv(report, 'contacts.patient.identity.dateOfBirth', ''),
    'YYYY-MM-DD'
  )
  return birthDate.isValid() ? birthDate.format(format) : defaultValue
}

const formatCreatedAt = (
  report,
  format = 'DD.MM.YYYY HH:mm',
  defaultValue = ''
) => {
  const createdAt = moment(
    gv(report, 'metaData.dateCreated', gv(report, 'created', ''))
  )
  return createdAt.isValid() ? createdAt.format(format) : defaultValue
}

const formatAssignedAt = (
  report,
  format = 'DD.MM.YYYY HH:mm',
  defaultValue = ''
) => {
  const createdAt = moment(
    gv(report, 'metaData.dateAssigned', gv(report, 'assigned', ''))
  )
  return createdAt.isValid() ? createdAt.format(format) : defaultValue
}

const formatHeight = (report, defaultValue = '') => {
  const height = gv(report, 'contacts.patient.height', null)
  return height ? `${height} cm` : defaultValue
}

const formatWeight = (report, defaultValue = '') => {
  const height = gv(report, 'contacts.patient.weight', null)
  return height ? `${height} kg` : defaultValue
}

const formatPregnancy = (report, defaultValue = '') => {
  const pregnant = gv(report, 'contacts.patient.pregnancy.pregnant', null)
  const week = gv(report, 'contacts.patient.pregnancy.week', false)

  if (pregnant === true) {
    return week !== false ? `${week}. Woche` : 'Ja'
  }

  return pregnant === false ? 'Nein' : defaultValue
}

const formatLongString = (stringa, limit = 15) => {
  // add a break to a word contained in stringa if it's longer then {limit} chars
  const str = stringa.split(' ')

  // TODO: resolve lint error here
  // eslint-disable-next-line
  for (const s in str) {
    if (str[s].length > limit) {
      str[s] = str[s].slice(0, limit) + '\u00AD' + str[s].slice(limit)
    }
  }

  return str.join(' ')
}

export {
  formatDose,
  formatIndication,
  formatDuration,
  formatStatus,
  formatName,
  formatGender,
  findPrimaryDrug,
  formatAge,
  formatBirthDate,
  formatCreatedAt,
  formatAssignedAt,
  formatHeight,
  formatWeight,
  formatPregnancy,
  formatHCPConfirmation,
  formatContactConfirmation,
  formatLongString
}
