import { createSelector } from 'reselect'
import { isEmpty } from 'lodash'

export const isUserLoggedIn = createSelector(
  state => state.auth.isAuthenticated,
  isAuthenticated => isAuthenticated
)

export const isLoginInProgress = createSelector(
  state => state.auth.isAuthInProgress,
  isAuthInProgress => isAuthInProgress
)

const getSuccess = state => state.auth.isAuthenticated
const getInProgress = state => state.auth.isAuthInProgress
const getError = state => state.auth.errors

export const getLoginStatus = createSelector(
  [getSuccess, getInProgress, getError],
  (isAuthenticated, isAuthInProgress, authErrorObj) => {
    if (isAuthenticated) {
      return 'success'
    }
    if (!isEmpty(authErrorObj)) {
      return 'failed'
    }
    if (isAuthInProgress) {
      return 'inProgress'
    }
  }
)

export const getUserToken = createSelector(
  state => state.auth.user.token,
  userToken => userToken
)

export const getUserDetails = createSelector(
  state => state.userData.userData,
  userData => userData
)

export const getUserEmail = createSelector(
  state => state.userData.userData.email,
  email => email
)

export const getUserFullName = createSelector(
  state => state.userData.userData.name,
  name => `${name.firstName} ${name.lastName}`.trim()
)

export const getUserRole = createSelector(
  state => state.userData.userData.role,
  role => role
)

export const getUserId = createSelector(
  state => state.userData.userData._id,
  id => id
)

export const isUserAdmin = createSelector(
  [getUserRole],
  role => role === 'admin'
)
