import { identityInstanceAxios as identity } from '../config'

const NEW_PASSWORD_SETUP_URL = '/pharma/reset/newPassword'

/**
 * New password setup request using AJAX call
 *
 * @returns {Promise} - Result of ajax call
 */
export async function newPasswordSetupAPI (data, token) {
  const config = {
    url: NEW_PASSWORD_SETUP_URL,
    method: 'post',
    data: {
      newPassword: data.newPassword
    },
    headers: { Authorization: token }
  }

  return identity(config)
}
