import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation, Trans } from 'react-i18next'

import {
  MedModalDlg,
  RegisterContainer,
  RegisterWrapper,
  BasicPlusInfo,
  RegisterStyled,
  ModalTitle,
  RegisterText
} from './style'

import RegisterConfirm from '../RegisterConfirm'

class MedModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showConfirmationPage: false,
      registeredFromBasic: false
    }
  }

  handleOnDoneRegistration = () => {
    this.setState({ showConfirmationPage: true, registeredFromBasic: true })
  }

  handleOnCancel = () => {
    this.props.onCancel()
  }

  // handleOutsideC
  render () {
    const showConfirmation = this.state.showConfirmationPage
    return (
      <MedModalDlg
        aria-describedby='alert-dialog-description'
        aria-labelledby='alert-dialog-title'
        open={this.props.open}
        onBackdropClick={this.handleOnCancel}
        showConfirmation={showConfirmation}
      >
        {(showConfirmation && (
          <RegisterConfirm
            onClose={this.handleOnCancel}
            registeredFromBasic={this.state.registeredFromBasic}
          />
        )) || (
          <RegisterContainer>
            <BasicPlusInfo>
              <ModalTitle>
                <Trans i18nKey='components.RegisterModal.title'>
                  <strong />
                  <br />
                </Trans>
              </ModalTitle>
              <br /> <br />
              <Trans
                i18nKey='components.RegisterModal.text'
                values={{
                  break: '<br />'
                }}
              >
                <RegisterText />
              </Trans>
            </BasicPlusInfo>
            <RegisterWrapper>
              <RegisterStyled
                onDoneRegistration={this.handleOnDoneRegistration}
              />
            </RegisterWrapper>
          </RegisterContainer>
        )}
      </MedModalDlg>
    )
  }
}

MedModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

export default withTranslation()(MedModal)
