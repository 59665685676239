import React from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import * as palette from 'styles/palette'

const MedButton = styled(props => <Button {...props} />)`
  &&& {
    color: ${palette.white};
    background-color: ${props => props.fontColor};
    border-radius: 0.7rem;
    min-height: 3.2rem;
    & > span {
      letter-spacing: 0.1px;
      text-transform: none;
    }
  }
`

export default MedButton
