import {
  getReportsAPI,
  getReportAPI,
  changeReportStatusAPI,
  updatePharmaInternalIdAPI,
  changeReportAssigneeAPI
} from 'api'

// Define action types
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS'
export const GET_REPORTS_FAIL = 'GET_REPORTS_FAIL'
export const GET_REPORTS_PENDING = 'GET_REPORTS_PENDING'
export const CHANGE_REPORT_STATUS_SUCCESS = 'CHANGE_REPORT_STATUS_SUCCESS'
export const CHANGE_REPORT_STATUS_FAIL = 'CHANGE_REPORT_STATUS_FAIL'
export const UPDATE_PHARMA_INTERNAL_ID_SUCCESS =
  'UPDATE_PHARMA_INTERNAL_ID_SUCCESS'
export const UPDATE_PHARMA_INTERNAL_ID_FAIL = 'UPDATE_PHARMA_INTERNAL_ID_FAIL'
export const CHANGE_REPORT_ASSIGNEE_SUCCESS = 'CHANGE_REPORT_ASSIGNEE_SUCCESS'
export const CHANGE_REPORT_ASSIGNEE_FAIL = 'CHANGE_REPORT_ASSIGNEE_FAIL'

export const GET_REPORTS = async params => {
  try {
    const res = await getReportsAPI(params)
    if (res) {
      return { type: GET_REPORTS_SUCCESS, payload: { reports: res } }
    }
  } catch (err) {
    return {
      type: GET_REPORTS_FAIL,
      payload: err.response && err.response.status
    }
  }
}

// This *currently* uses the same action types, since there's no difference in the interface with one or more reports
export const GET_REPORT = async (reportId, original, minVersion, token) => {
  try {
    const res = await getReportAPI(reportId, original, minVersion, token)
    return { type: GET_REPORTS_SUCCESS, payload: { report: res } }
  } catch (err) {
    return { type: GET_REPORTS_FAIL, payload: err.response.status }
  }
}

export const CHANGE_REPORT_STATUS = async (reportId, toStatus, token) => {
  try {
    const res = await changeReportStatusAPI(reportId, toStatus, token)
    return { type: CHANGE_REPORT_STATUS_SUCCESS, payload: res }
  } catch (err) {
    return { type: CHANGE_REPORT_STATUS_FAIL, payload: err.response.status }
  }
}

export const UPDATE_PHARMA_INTERNAL_ID = async (
  reportId,
  newPharmaInternalId,
  token
) => {
  try {
    const res = await updatePharmaInternalIdAPI(
      reportId,
      newPharmaInternalId,
      token
    )
    return { type: UPDATE_PHARMA_INTERNAL_ID_SUCCESS, payload: res }
  } catch (err) {
    return {
      type: UPDATE_PHARMA_INTERNAL_ID_FAIL,
      payload: err.response.status
    }
  }
}

export const CHANGE_REPORT_ASSIGNEE = async (
  reportId,
  newAssigneeId,
  token
) => {
  try {
    const res = await changeReportAssigneeAPI(reportId, newAssigneeId, token)
    return { type: CHANGE_REPORT_ASSIGNEE_SUCCESS, payload: res }
  } catch (err) {
    return { type: CHANGE_REPORT_ASSIGNEE_FAIL, payload: err.response.status }
  }
}
