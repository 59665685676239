import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'i18n'
import {
  Container,
  SubContainer,
  SubtitleContainer,
  DetailContainer,
  SubTitle,
  DetailTitleColumn,
  ColumnDetailText,
  DetailText,
  DetailTitleRow,
  DetailTitle,
  RowDetailText,
  DurationText
} from './style'
import { Title } from '../LeftDetailView/style'
import Habit from './HabitComponent'
import { formatGender, formatLongString } from 'lib/helper/formatters'
import lodash from 'lodash'

const RightDetailView = ({
  onOpenPatientDataModal,
  onOpenDiseasesModal,
  onOpenHabitsModal,
  report
}) => {
  let patient = lodash.get(report, 'contacts.patient', {})
  return (
    <Container>
      <Title>
        {I18n.t(
          'components.ReportDetail.DetailContainer.RightDetailView.title'
        )}
      </Title>
      <SubContainer>
        <SubtitleContainer>
          <SubTitle>
            {I18n.t(
              'components.ReportDetail.DetailContainer.RightDetailView.subtitles.general'
            )}
          </SubTitle>
          {/* <EditBtn onClick={onOpenPatientDataModal}/> */}
        </SubtitleContainer>
        <DetailContainer>
          {patient.gender && (
            <DetailTitleRow>
              <DetailTitle>
                {I18n.t(
                  'components.ReportDetail.DetailContainer.RightDetailView.details.gender'
                )}
              </DetailTitle>
              <ColumnDetailText>{formatGender(report)}</ColumnDetailText>
            </DetailTitleRow>
          )}
          {patient.height && (
            <DetailTitleRow>
              <DetailTitle>
                {I18n.t(
                  'components.ReportDetail.DetailContainer.RightDetailView.details.height'
                )}
              </DetailTitle>
              <ColumnDetailText>{patient.height} cm</ColumnDetailText>
            </DetailTitleRow>
          )}
          {patient.weight && (
            <DetailTitleRow>
              <DetailTitle>
                {I18n.t(
                  'components.ReportDetail.DetailContainer.RightDetailView.details.weight'
                )}
              </DetailTitle>
              <ColumnDetailText>{patient.weight} kg</ColumnDetailText>
            </DetailTitleRow>
          )}
          {patient.pregnancy && (
            <DetailTitleRow>
              <DetailTitle>
                {I18n.t(
                  'components.ReportDetail.DetailContainer.RightDetailView.details.pregnancy'
                )}
              </DetailTitle>
              <ColumnDetailText>
                {patient.pregnancy.pregnant
                  ? patient.pregnancy.week +
                    I18n.t(
                      'components.ReportDetail.DetailContainer.RightDetailView.week'
                    )
                  : I18n.t(
                      'components.ReportDetail.DetailContainer.RightDetailView.no'
                    )}
              </ColumnDetailText>
            </DetailTitleRow>
          )}
          {patient.dateOfBirthYear && (
            <DetailTitleRow>
              <DetailTitle>
                {I18n.t(
                  'components.ReportDetail.DetailContainer.RightDetailView.details.birthyear'
                )}
              </DetailTitle>
              <ColumnDetailText>{patient.dateOfBirthYear}</ColumnDetailText>
            </DetailTitleRow>
          )}
        </DetailContainer>
      </SubContainer>
      <SubContainer>
        <SubtitleContainer>
          <SubTitle>
            {I18n.t(
              'components.ReportDetail.DetailContainer.RightDetailView.subtitles.diseases'
            )}
          </SubTitle>
          {/* <EditBtn onClick={onOpenDiseasesModal}/> */}
        </SubtitleContainer>
        <DetailContainer>
          {report.diagnoses && report.diagnoses.length > 0 ? (
            report.diagnoses.map((diagnosis, index) => {
              let shorterDescription = diagnosis.description || ''
              shorterDescription = formatLongString(shorterDescription, 20)

              return (
                <DetailTitleColumn key={index}>
                  <DetailTitle>
                    {diagnosis.name}
                    {(diagnosis.startDate || diagnosis.endDate) && (
                      <DurationText>
                        <br /> (
                        {diagnosis.startDate
                          ? diagnosis.startDate
                          : I18n.t(
                              'components.ReportDetail.DetailContainer.RightDetailView.na'
                            )}{' '}
                        -
                        <span />
                        {diagnosis.endDate
                          ? diagnosis.endDate
                          : I18n.t(
                              'components.ReportDetail.DetailContainer.RightDetailView.na'
                            )}
                        )
                      </DurationText>
                    )}
                  </DetailTitle>
                  <RowDetailText>{shorterDescription}</RowDetailText>
                </DetailTitleColumn>
              )
            })
          ) : (
            <DetailText>
              {I18n.t(
                'components.ReportDetail.DetailContainer.RightDetailView.na'
              )}
            </DetailText>
          )}
        </DetailContainer>
      </SubContainer>
      <SubContainer>
        <SubtitleContainer>
          <SubTitle>
            {I18n.t(
              'components.ReportDetail.DetailContainer.RightDetailView.subtitles.riskFactors'
            )}
          </SubTitle>
          {/* <EditBtn onClick={onOpenHabitsModal}/> */}
        </SubtitleContainer>
        <DetailContainer>
          {(!report.habits ||
            (!(report.habits.others && report.habits.others.length) &&
              !report.habits.smoking &&
              !report.habits.drinking &&
              !report.habits.sport)) && (
            <DetailText>
              {I18n.t(
                'components.ReportDetail.DetailContainer.RightDetailView.na'
              )}
            </DetailText>
          )}
          {report.habits && report.habits.smoking && (
            <Habit
              habit={report.habits.smoking}
              title={I18n.t(
                'components.ReportDetail.DetailContainer.RightDetailView.smoking'
              )}
            />
          )}
          {report.habits && report.habits.drinking && (
            <Habit
              habit={report.habits.drinking}
              title={I18n.t(
                'components.ReportDetail.DetailContainer.RightDetailView.drinking'
              )}
            />
          )}
          {report.habits && report.habits.sport && (
            <Habit
              habit={report.habits.sport}
              title={I18n.t(
                'components.ReportDetail.DetailContainer.RightDetailView.sport'
              )}
            />
          )}
          {report.habits &&
            report.habits.others &&
            report.habits.others.map((habit, index) => (
              <Habit key={index} habit={habit} title={habit.name} />
            ))}
        </DetailContainer>
      </SubContainer>
      <SubContainer>
        <SubtitleContainer>
          <SubTitle>
            {I18n.t(
              'components.ReportDetail.DetailContainer.RightDetailView.subtitles.additions'
            )}
          </SubTitle>
          {/* <EditBtn/> */}
        </SubtitleContainer>
        <DetailContainer>
          <DetailText>
            {report.description
              ? report.description
              : I18n.t(
                  'components.ReportDetail.DetailContainer.RightDetailView.na'
                )}
          </DetailText>
        </DetailContainer>
      </SubContainer>
    </Container>
  )
}

RightDetailView.propTypes = {
  onOpenPatientDataModal: PropTypes.func,
  onOpenDiseasesModal: PropTypes.func,
  onOpenHabitsModal: PropTypes.func,
  report: PropTypes.object.isRequired
}

export default RightDetailView
