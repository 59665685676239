import React from 'react'
import styled from 'styled-components'
import * as palette from 'styles/palette'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import MedButton from 'lib/elements/MedButton'

export const TutorialDlg = styled(props => <Dialog {...props} />)``

export const Title = styled.p`
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-weight: 600;
  color: ${palette.primaryDarkGreen};
`

export const Content = styled(props => <DialogContent {...props} />)`
  &&& {
    background-color: ${palette.unknownBGColor};
    padding: 1.7rem 2.2rem;
  }
`

export const TextArea = styled.div`
  border: 1px solid ${palette.textAreaColor};
  border-radius: 5px;
  background-color: ${palette.white};
  padding: 1.8rem 1.5rem;
  height: 30.4rem;
  width: 58.8rem;
  margin-bottom: 1.9rem;
`

export const TextLine = styled.div`
  display: flex;
  padding-bottom: 2.1rem;
`

export const TextLineTitle = styled.p`
  margin: 0;
  color: ${palette.primaryDarkGrey};
  font-weight: 700;
  padding-right: 1rem;
  letter-spacing: 0.2px;
`

export const TextLineValue = TextLineTitle.extend`
  font-weight: 200;
`

export const ContentText = styled.div`
  line-height: 1.9rem;
  font-size: 14px;
  color: ${palette.dlgFontColor};
  max-width: 32.4rem;
  margin: auto;
  padding-top: 2.1rem;
`

export const DlgActions = styled(props => <DialogActions {...props} />)`
  &&& {
    margin: 0;
    padding: 3rem;
  }
`

export const ButtonGroupContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const OKButton = styled(MedButton)`
  &&& {
    background-color: ${palette.primaryLightGreen};
    color: ${palette.white};
    font-size: 2.1rem;
    line-height: 2.9rem;
    &:hover {
      background-color: ${palette.primaryDarkGreen};
    }
  }
`
