import { default as MedTrackingProvider } from './../MedTrackingProvider'

export const helperPageView = (func, pageTitle, pageUrl) => {
  const {
    isTrackerReady,
    isSubscriptionDataReady,
    subscriptionType
  } = MedTrackingProvider.getSubscriptionData()

  if (!isTrackerReady) {
    return
  }

  func({
    documentTitle: pageTitle,
    href: pageUrl,
    ...(isSubscriptionDataReady && {
      customDimensions: [
        {
          id: 1,
          value: subscriptionType
        }
      ]
    })
  })
}

export const helperEvent = (func, category, action, name, value) => {
  const {
    isTrackerReady,
    isSubscriptionDataReady,
    subscriptionType
  } = MedTrackingProvider.getSubscriptionData()

  if (!isTrackerReady) {
    return
  }

  func({
    category: category,
    action: action,
    name: name,
    value: value,
    ...(isSubscriptionDataReady && {
      customDimensions: [
        {
          id: 1,
          value: subscriptionType
        }
      ]
    })
  })
}
