import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import I18n from 'i18n'

import {
  ListContainer,
  ListText,
  ListIcon,
  ListItems,
  SidebarLinkContainer,
  SidebarLink
} from './style'
import { Drawer, List } from '@material-ui/core'
import DashBoardIcon from '@material-ui/icons/Assignment'
import SettingsIcon from '@material-ui/icons/Settings'
import LibraryAddIcon from '@material-ui/icons/LibraryAdd'

const StyledLink = {
  color: 'rgb(10, 10, 10)'
}

class Sidebar extends Component {
  static propTypes = {
    reports: PropTypes.array,
    report_id: PropTypes.string,
    isPro: PropTypes.bool,
    isPlus: PropTypes.bool
  }

  state = {
    pageSelected: ''
  }

  updateSelected (selectedIndex) {
    if (this.props.location.pathname !== selectedIndex) {
      this.setState({
        pageSelected: selectedIndex
      })
    }
  }

  render () {
    const { sidebarOpen, isPlus, isPro } = this.props

    if (this.state.pageSelected) {
      this.props.history.push({
        pathname: this.state.pageSelected,
        state: { keepSidebarOpen: true }
      })
    }

    return (
      <Drawer variant='persistent' anchor='left' open={sidebarOpen}>
        <ListContainer role='presentation'>
          <List style={{ paddingTop: '0', backgroundColor: 'transparent' }}>
            <ListItems
              onClick={() => this.updateSelected('/reports')}
              data-ispageselected={/reports/.test(this.props.location.pathname)}
            >
              <ListIcon>
                <DashBoardIcon />
              </ListIcon>
              <ListText>{I18n.t('components.Sidebar.UAW')}</ListText>
            </ListItems>
            {(isPro || isPlus) && (
              <ListItems
                onClick={() => this.updateSelected('/followups')}
                data-ispageselected={/followups/.test(
                  this.props.location.pathname
                )}
              >
                <ListIcon>
                  <LibraryAddIcon />
                </ListIcon>
                <ListText>{I18n.t('components.Sidebar.questions')}</ListText>
              </ListItems>
            )}
            {(isPro || isPlus) && (
              <ListItems
                onClick={() => this.updateSelected('/settings')}
                data-ispageselected={/settings/.test(
                  this.props.location.pathname
                )}
              >
                <ListIcon>
                  <SettingsIcon />
                </ListIcon>
                <ListText>{I18n.t('components.Sidebar.settings')}</ListText>
              </ListItems>
            )}
          </List>
        </ListContainer>
        <SidebarLinkContainer>
          <SidebarLink>
            <a
              href='https://xo-life.com/agb-13-03-2023'
              style={StyledLink}
              target='_blank'
              rel='noopener noreferrer'
            >
              {I18n.t('components.Sidebar.AGB')}
            </a>
          </SidebarLink>
          <SidebarLink>
            <a
              href='https://www.xo-life.com/datenschutz/'
              style={StyledLink}
              target='_blank'
              rel='noopener noreferrer'
            >
              {I18n.t('components.Sidebar.dataProtection')}
            </a>
          </SidebarLink>
          <SidebarLink>
            <a
              href='https://www.xo-life.com/impressum/'
              style={StyledLink}
              target='_blank'
              rel='noopener noreferrer'
            >
              {I18n.t('components.Sidebar.impressum')}
            </a>
          </SidebarLink>
        </SidebarLinkContainer>
      </Drawer>
    )
  }
}

export default withRouter(Sidebar)
