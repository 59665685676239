import styled from 'styled-components'
import Container from 'lib/elements/Container'
import BlurredImg from '../../assets/img/Background_Pro_blurred.png'

export const BackgroundImageWrapper = styled(Container)`
  background-image: url(${BlurredImg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`
