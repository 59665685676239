import React from 'react'
import Header from '../../components/Header'
import RegisterConfirm from '../../components/RegisterConfirm'
import { StyledContainer } from './style'

const RegisterConfirmContainer = props => {
  return (
    <>
      <Header />
      <StyledContainer>
        <RegisterConfirm />
      </StyledContainer>
    </>
  )
}

export default RegisterConfirmContainer
