import Button from '@material-ui/core/Button'
import styled from 'styled-components'
import React from 'react'

export const ToggleSwitchButton = styled(({ isActive, ...props }) => (
  <Button {...props} />
))`
  background-color: ${props =>
    props.disabled === true
      ? 'rgb(243, 248, 249)'
      : props.isActive === true
      ? '#12A5A9'
      : 'transparent'} !important;

  border-radius: 7px;
  padding: 5px !important;
  width: 135px;
  border: 1px solid !important;
  border-color: ${props =>
    props.disabled === true ? 'transparent' : '#12A5A9'} !important;

  span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;

    color: ${props =>
      props.disabled === true
        ? '#828282'
        : props.isActive === true
        ? 'white'
        : '#12A5A9'} !important;

    text-transform: none;
  }
`

export const FloatRight = styled.span`
  margin-right: -10px;
  float: right;
`
