import styled from 'styled-components'
import * as palette from 'styles/palette'
import Dialog from '@material-ui/core/Dialog'
import React from 'react'
import { Button, Avatar } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Tab from '@material-ui/core/Tab'
import { getProfileColor } from '../../utilities/generalFunctions'

export const DialogStyled = styled(({ isClicked, ...props }) => (
  <Dialog {...props} />
))`
  &&& > div > div {
    overflow: visible;
    width: 502px;
    height: 650px;
    min-height: 650px;
    border-radius: 7px;
  }
`
export const EditDataForm = styled.form`
  text-align: center;
  @media (max-width: 375px) {
    padding: 0 2rem;
  }
  width: 100%;
  height: 100%;
`

export const EditDataHeader = styled.div`
  background-color: ${palette.primaryBackground};
  border-radius: 7px 7px 0 0;
  height: 138px;
  width: 100%;
`
export const AvatarWrapper = styled.div`
  width: 121px;
  float: left;
  box-sizing: border-box;
  padding: 31px 23px 32px 23px;
`

export const ProfileInfo = styled.div`
  width: 381px;
  float: left;
  box-sizing: border-box;
  padding: 31px 0 32px 0;
`
export const ChangeColour = styled.div`
  background-color: white;
  height: 18px;
  width: 75px;
  border-radius: 0 0 7px 7px;
  text-align: center;
  font-size: 12px;
  line-height: 22px;
  cursor: pointer;
`

export const CloseIconStyled = styled(props => <CloseIcon {...props} />)`
  color: ${palette.primaryDarkGreen};
  cursor: pointer;
  position: absolute;
  top: 23px;
  right: 24px;
`

export const MenuItem = styled(props => <Tab {...props} />)`
  text-transform: capitalize !important;
  color: ${palette.primaryDarkGreen} !important;
  font-weight: 600 !important;
`

export const StyledAvatar = styled(props => <Avatar {...props} />)`
  &&& {
    color: ${palette.primaryBackground};
    background-color: ${props => getProfileColor(props.userID)};
    border-radius: 7px;
    width: 75px;
    // this is needed when we will have the 'change color' button
    // border-radius: 7px 7px 0 0;
    // height: 57px;
    height: 75px;

    & > span {
      font-weight: bold;
      font-size: 25px;
      line-height: 30px;
    }
  }
`

export const UserName = styled.span`
  color: ${palette.freeColor};
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  height: 22px;
  display: block;
  text-align: left;
`

export const UserTitle = styled.span`
  color: ${palette.primaryDarkGrey};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  height: 16px;
  line-height: 16px;
  margin-top: 13px;
  margin-bottom: 7px;
  display: block;
  text-align: left;
`

export const UserRole = styled.span`
  color: ${palette.primaryDarkGrey};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: block;
  text-align: left;
  text-transform: uppercase;
`

export const MenuBtn = styled(({ hasError, isDefault, ...props }) => (
  <Button {...props} />
))`
  &&& {
    width: 100%;
    border: 1px solid
      ${props =>
        props.hasError ? palette.errorBGColor : palette.primaryDarkGreen};
    border-radius: 0.7rem;
    padding: 0 0.5rem;
    text-align: center;
    min-height: 2.4rem;
    height: 2.4rem;
    padding-left: 0;
    opacity: ${props => (props.isDisabled === true ? '0.3' : '1')};
    & > span {
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${props =>
        props.isDefault ? palette.primaryDarkGreen : palette.primaryDarkGrey};
      text-transform: none;
      & > svg {
        position: absolute;
        right: 0;
      }
    }
  }
`

export const ExplanationText = styled.span``
