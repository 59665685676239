import React, { Component } from 'react'
import PropTypes from 'prop-types'
import I18n from 'i18n'
import MedModal from '../../../../MedModal'
import DiseasesModalContent from './DiseasesModalContent'

const hasYes = true
const hasNo = true

class DiseasesModal extends Component {
  static propTypes = {
    isOpenModal: PropTypes.bool,
    onClose: PropTypes.func
  }

  state = {
    name: '',
    birthYear: '',
    size: '',
    weight: '',
    gender: 0,
    email: '',
    week: '',
    count: 1
  }

  handleCancel = () => {
    this.props.onClose()
  }

  handleSave = () => {
    this.props.onClose()
  }

  handleAddComment = () => {
    this.setState({ count: this.state.count + 1 })
  }

  handleRemoveComment = () => {
    this.setState({ count: this.state.count - 1 })
  }

  render () {
    const { isOpenModal } = this.props
    return (
      <MedModal
        hasNo={hasNo}
        hasYes={hasYes}
        isAlert={false}
        isOpen={isOpenModal}
        modalContent={
          <DiseasesModalContent
            count={this.state.count}
            onAddComment={this.handleAddComment}
            onRemoveComment={this.handleRemoveComment}
          />
        }
        noTxt={I18n.t(
          'components.ReportDetail.DetailContainer.RightDetailView.DiseasesModal.cancel'
        )}
        onNo={this.handleCancel}
        onYes={this.handleSave}
        title={I18n.t(
          'components.ReportDetail.DetailContainer.RightDetailView.DiseasesModal.title'
        )}
        yesTxt={I18n.t(
          'components.ReportDetail.DetailContainer.RightDetailView.DiseasesModal.save'
        )}
      />
    )
  }
}

export default DiseasesModal
