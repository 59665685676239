import React from 'react'
import styled from 'styled-components'
import * as palette from 'styles/palette'
import { Button, Avatar } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Close } from '@material-ui/icons'
import DlgActions from 'lib/elements/DlgActions'
import MedButton from 'lib/elements/MedButton'

export const TeamContainer = styled.div`
  background-color: ${palette.primaryBackground};
  padding: 2rem;
  color: ${palette.tableHeaderColor};
  border-radius: 7px;
  min-height: 130px;
`

export const TeamTitle = styled.span`
  line-height: 2em;
  font-size: 1.3rem;
  font-weight: 600;
  color: ${palette.primaryDarkGreen};
`

export const PropertyValue = styled.span`
  font-size: 1.5rem;
`

export const InviteTeamMemberButton = styled(props => <Button {...props} />)`
  &&& {
    background-color: ${palette.primaryDarkGreen};
    border-radius: 7px;
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: ${palette.white};
    }
    float: right;
    text-transform: none;
    opacity: ${props => (props.isDisabled === true ? '0.3' : '1')};
    margin-right: 30px;
  }
`

export const UserProfileContainer = styled.div`
  height: 85px;
  display: block;
  &&& > div {
    background-color: ${props =>
      props.status === 'pending' && palette.primaryLightGrey};
  }
  & > span {
    color: ${props => props.status === 'pending' && palette.primaryLightGrey};
  }
`

export const StyledAvatar = styled(props => <Avatar {...props} />)`
  &&& {
    color: ${palette.primaryBackground};
    background-color: ${palette.primaryLightGreen};
    margin: 0 10px 0 0;
    float: left;
    border-radius: 30%;
    height: 50px;
    width: 50px;

    & > span {
      font-size: 20px;
    }
  }
`
export const UserName = styled.span`
  margin-top: 5px;
  color: ${palette.primaryDarkGreen};
  font-weight: bold;
  font-size: 1.3rem;
`
export const UserEmail = styled.span`
  color: ${palette.primaryDarkRed};
`

export const RoleBadge = styled.span`
  font-size: 10px;
  font-weight: bold;
  background-color: ${palette.primaryLightGreen};
  border-radius: 20%;
  height: 17px;
  padding: 0 5px;
  color: white;
  margin-left: 5px;
`

export const PendingStatus = styled.span`
  float: right;
  text-align: right;
  font-weight: 400;
  font-size: 12px;
`

export const StyledSpinner = styled(props => <CircularProgress {...props} />)`
  &&& {
    margin-left: 50em;
    padding: 0.625em;
    color: ${palette.safeGreen};
  }
`
export const Divider = styled.hr`
  border-width: 1px;
`
export const ExpirationDate = styled.span`
  float: right;
  font-size: 12px;
`

export const SendInvitationLink = styled(props => <Button {...props} />)`
  background-color: ${palette.primaryDarkGreen} !important;
  border-radius: 7px !important;
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: ${palette.white};
  }
  float: left;
  text-transform: none !important;
  opacity: ${props => (props.isDisabled === true ? '0.3' : '1')} !important;
`

export const PendingInvitationInfoContainer = styled.div`
  float: right;
  background-color: unset !important;
  margin-right: 10px;
`
export const PendingInvitationActionsContainer = styled.div`
  float: right;
  background-color: unset !important;
`

export const CloseIconStyled = styled(props => <Close {...props} />)`
  color: ${palette.primaryDarkGreen};
  cursor: pointer;
  position: absolute;
  top: 23px;
  right: 24px;
  font-size: 20px !important;
`
export const DlgActionsStyled = styled(props => <DlgActions {...props} />)`
  &&& {
    width: inherit;
    margin: 0px 20px 20px 20px;
  }
`

export const DeleteUserHeader = styled.div`
  background-color: ${palette.primaryBackground};
  border-radius: 0.7rem 0.7rem 0 0;
  height: 70px;
  width: 100%;
`

export const DeleteUserTitle = styled.span`
  color: ${palette.primaryDarkGreen};
  font-weight: 600;
  font-size: 16px;
  line-height: 65px;
  margin-left: 20px;
`
export const DeleteUserContent = styled.div`
  padding: 20px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
`

export const YesBtn = styled(MedButton)`
  &&& {
    background-color: ${palette.primaryDarkRed};
    &:hover {
      background-color: ${palette.primaryLightRed};
    }
  }
`

export const NoBtn = styled(MedButton)`
  &&& {
    background-color: ${props => !props.disabled && palette.primaryDarkGreen};
    &:hover {
      background-color: ${palette.primaryLightGreen};
    }
  }
`
