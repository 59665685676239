import { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { isUserLoggedIn } from 'selectors/user'

class DefaultComponent extends Component {
  componentDidMount () {
    const { userLoggedIn } = this.props

    if (userLoggedIn) {
      this.props.history.push('/reports')
    } else {
      this.props.history.push('/login')
    }
  }

  render () {
    return true
  }
}

const mapStateToProps = state => ({
  userLoggedIn: isUserLoggedIn(state)
})

DefaultComponent.propTypes = {
  userLoggedIn: PropTypes.bool.isRequired
}

export default connect(mapStateToProps)(DefaultComponent)
