import React, { Component } from 'react'
import { AgbSection, Impressum, LinkContainer, PrivacySection } from './style'
import I18n from 'i18n'

const StyledLink = {
  color: 'rgb(10, 10, 10)'
}

class TermsAndConditions extends Component {
  render () {
    return (
      <LinkContainer>
        <AgbSection>
          <a
            href='https://xo-life.com/agb-13-03-2023'
            style={StyledLink}
            target='_blank'
            rel='noopener noreferrer'
          >
            {I18n.t('components.TermsAndConditions.AGB')}
          </a>
        </AgbSection>
        <PrivacySection>
          <a
            href='https://www.xo-life.com/datenschutz/'
            style={StyledLink}
            target='_blank'
            rel='noopener noreferrer'
          >
            {I18n.t('components.TermsAndConditions.dataProtection')}
          </a>
        </PrivacySection>
        <Impressum>
          <a
            href='https://www.xo-life.com/impressum/'
            style={StyledLink}
            target='_blank'
            rel='noopener noreferrer'
          >
            {I18n.t('components.TermsAndConditions.impressum')}
          </a>
        </Impressum>
      </LinkContainer>
    )
  }
}

export default TermsAndConditions
