import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import InputContainer from 'lib/elements/InputContainer'
import CircularProgress from '@material-ui/core/CircularProgress'
import Input from 'lib/elements/Input'
import ErrorLabel from '../../../ErrorLabel'
import validator from 'validator'
// import CircularProgress from '@material-ui/core/CircularProgress'
import DlgActions from 'lib/elements/DlgActions'
import DropDownMenu from '../../../DropDownMenu'
import ErrorIcon from '@material-ui/icons/Error'
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { withTranslation } from 'react-i18next'

import { INVITE_COLLEAGUE_PENDING, INVITE_TEAM_MEMBER } from 'actions'
import { getUserToken } from 'selectors/user'

import {
  MedModalDlg,
  InviteMemberForm,
  InvitationLabel,
  ErrorTextLabel,
  InvitationBtn,
  // MenuBtn,
  // ExplanationText,
  NoBtn
} from './style'

// import RegisterConfirm from '../RegisterConfirm'

class InviteTeamModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      // showConfirmationPage: true,
      isFocusOnPwd: false,
      strength: 0,
      teamMemberData: {
        firstName: '',
        lastName: '',
        email: '',
        role: ''
      },
      isLoading: false,
      anchorEl: null,
      filterList: ['Admin', 'Editor'],
      filterName: 'Editor',
      isValid: {
        firstName: true,
        lastName: true,
        email: true,
        filterName: true
      }
    }
  }

  initializeInvitationForm = () => {
    this.setState({
      teamMemberData: {
        firstName: '',
        lastName: '',
        email: '',
        role: ''
      },
      isLoading: false,
      showErrorMessage: false,
      isValid: {
        firstName: true,
        lastName: true,
        email: true,
        filterName: true
      }
    })
  }

  handleInviteMemberBtn = async () => {
    const { firstName, lastName, email } = this.state.teamMemberData
    const { filterName } = this.state
    if (
      !firstName.length ||
      !lastName.length ||
      !email.length ||
      filterName === 'Berechtigung'
    ) {
      this.setState({
        isValid: {
          ...this.state.isValid,
          firstName: !!firstName.length,
          lastName: !!lastName.length,
          email: !!email.length
        },
        isLoading: false
      })
      return
    }

    if (!validator.isEmail(email)) {
      return this.setState({
        isValid: { ...this.state.isValid, email: false },
        isLoading: false
      })
    } else {
      this.setState({ isValid: { ...this.state.isValid, email: true } })
    }

    this.setState({ isLoading: true })

    await this.setState(
      {
        teamMemberData: {
          ...this.state.teamMemberData,
          role: this.state.filterName.toLowerCase()
        }
      },
      async () => {
        const dataToSend = {
          ...this.state.teamMemberData,
          callbackUrl: `${window.location.origin}/register`
        }
        let response = await this.props.inviteTeamMember(
          dataToSend,
          this.props.userToken
        )
        if (
          response.payload &&
          response.payload.message === 'Member already invited'
        ) {
          this.setState({ showErrorMessage: true, isLoading: false })
        } else {
          this.handleOnCancel()
        }
      }
    )
  }

  handleOnCancel = () => {
    this.initializeInvitationForm()
    this.props.closeModal()
  }

  handleFilterClose = item => {
    this.setState({
      anchorEl: null,
      filterName: item !== 'close' ? item : this.state.filterName
    })
  }

  handleFilterClick = event => {
    // this.setState({ anchorEl: event.currentTarget })
  }

  handleChangeEmail = event => {
    this.setState({})
  }

  render () {
    const {
      isValid,
      anchorEl,
      filterList,
      teamMemberData,
      isLoading,
      showErrorMessage
    } = this.state
    return (
      <MedModalDlg
        aria-describedby='alert-dialog-description'
        aria-labelledby='alert-dialog-title'
        open={this.props.openModal}
        onBackdropClick={this.handleOnCancel}
      >
        <InviteMemberForm>
          <InvitationLabel>
            {this.props.t('components.Settings.Team.InviteTeamMember.title')}
          </InvitationLabel>
          <br />
          <br />
          <InputContainer>
            <Input
              haserror={!isValid.firstName}
              name='firstName'
              onChange={event => {
                teamMemberData.firstName = event.currentTarget.value
              }}
              placeholder={this.props.t(
                'components.Settings.Team.InviteTeamMember.firstName'
              )}
              type='text'
              required
            />
          </InputContainer>
          <InputContainer>
            <Input
              haserror={!isValid.lastName}
              name='lastName'
              onChange={event => {
                teamMemberData.lastName = event.currentTarget.value
              }}
              placeholder={this.props.t(
                'components.Settings.Team.InviteTeamMember.lastName'
              )}
              type='text'
              required
            />
          </InputContainer>
          <InputContainer>
            <Input
              haserror={!isValid.email}
              name='email'
              onChange={event => {
                teamMemberData.email = event.currentTarget.value
              }}
              placeholder={this.props.t(
                'components.Settings.Team.InviteTeamMember.email'
              )}
              type='text'
              required
            />
            <ErrorLabel
              errText={this.props.t(
                'components.Settings.Team.InviteTeamMember.emailError'
              )}
              hasError={!isValid.email}
            />
          </InputContainer>
          <InputContainer>
            <br />
            {/* <Input name='role' onChange={this.handleChangeRole} placeholder='Berechtigung' type='text' /> */}
            {/* <MenuBtn
              aria-haspopup='true'
              aria-owns={anchorEl ? 'filter-menu' : null}
              hasError={false}
              id='filter-btn'
              onClick={this.handleFilterClick}
            >
              <span style={{ color: '#117982' }}>{filterName}</span>
              <ArrowDropDownIcon style={{ color: '#117982' }} />
            </MenuBtn> */}
            <DropDownMenu
              anchorEl={anchorEl}
              buttonId='filter-btn'
              dropdownList={filterList}
              menuId='filter-menu'
              onHandleClose={this.handleFilterClose}
            />
            {/* <br /><br /> */}
            {/* {filterName === 'Admin'
              ? <ExplanationText>Können Firmeneinstellungen verändern und Teams verwalten</ExplanationText>
              : filterName === 'Editor'
                ? <ExplanationText>Können Firmeneinstellungen nicht verändern und Teams nicht verwalten</ExplanationText>
                : ''
            } */}
          </InputContainer>
          {showErrorMessage && (
            <>
              <ErrorTextLabel>
                <ErrorIcon
                  style={{ verticalAlign: 'top', marginRight: '5px' }}
                />
                {this.props.t(
                  'components.Settings.Team.InviteTeamMember.teamMemberAlreadyInvited'
                )}
              </ErrorTextLabel>
              <br /> <br />
            </>
          )}
          {isLoading ? (
            <CircularProgress />
          ) : (
            <DlgActions
              style={{ 'justify-content': 'space-between', padding: 0 }}
            >
              <NoBtn onClick={this.handleOnCancel}>
                {this.props.t(
                  'components.Settings.Team.InviteTeamMember.cancel'
                )}
              </NoBtn>
              <InvitationBtn
                onClick={this.handleInviteMemberBtn}
                variant='contained'
              >
                {this.props.t(
                  'components.Settings.Team.InviteTeamMember.sendInvitation'
                )}
              </InvitationBtn>
            </DlgActions>
          )}
        </InviteMemberForm>
      </MedModalDlg>
    )
  }
}

InviteTeamModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

function mapStateToProps (state) {
  return { userToken: getUserToken(state) }
}

function mapDispatchToProps (dispatch) {
  return {
    inviteTeamMember: async (userData, token) => {
      dispatch({ type: INVITE_COLLEAGUE_PENDING })
      const response = await INVITE_TEAM_MEMBER(userData, token)
      return dispatch(response)
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(InviteTeamModal))
