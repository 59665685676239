import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'
import { FloatRight, ToggleSwitchButton } from './style'
import { get as gv } from 'lodash'
import ReactTooltip from 'react-tooltip'
import { formatHCPConfirmation } from 'lib/helper/formatters'

class MedToggle extends Component {
  static propTypes = {
    // Called when the slider is moved to "Med. Fachpersonal"
    onDocSelected: PropTypes.func.isRequired,
    // Called when the slider is moved to "Patient"
    onPatientSelected: PropTypes.func.isRequired,
    report: PropTypes.object.isRequired
  }

  state = {
    isPatientSelected: true, // Default: doc selected
    isHcpVerified: false
  }

  componentDidMount () {
    this.props.onPatientSelected()
  }

  static getDerivedStateFromProps (props, state) {
    const status = gv(props.report, 'metaData.hcpStatus')
    if (status === 'hcp-report-verified') {
      return {
        isHcpVerified: true
      }
    } else {
      return {
        isHcpVerified: false
      }
    }
  }

  handleToggle (isPatientSelected, isHcpVerified) {
    if (isHcpVerified === true) {
      isPatientSelected = !isPatientSelected
      if (isPatientSelected) {
        this.props.onPatientSelected()
      } else {
        this.props.onDocSelected()
      }
      this.setState({ isPatientSelected })
    }
  }

  render () {
    const { t, report } = this.props

    const { isPatientSelected, isHcpVerified } = this.state

    let showTooltipMsg = ''
    if (formatHCPConfirmation(report) === 'pending') {
      showTooltipMsg = t('lib/elements.MedToggle.tooltipmsgPending')
    } else if (formatHCPConfirmation(report) === 'no') {
      showTooltipMsg = t('lib/elements.MedToggle.tooltipmsgNo')
    }

    return (
      <FloatRight
        onClick={() => this.handleToggle(isPatientSelected, isHcpVerified)}
      >
        <ToggleSwitchButton isActive={isPatientSelected}>
          {t('lib/elements.MedToggle.patient')}
        </ToggleSwitchButton>
        <span data-tip data-for={!isHcpVerified ? 'medical_staff' : ''}>
          <ToggleSwitchButton
            isActive={!isPatientSelected}
            disabled={!isHcpVerified}
          >
            {t('lib/elements.MedToggle.medicalStaffShort')}
          </ToggleSwitchButton>
        </span>
        <ReactTooltip id='medical_staff' type='dark' effect='solid'>
          <span>{showTooltipMsg}</span>
        </ReactTooltip>
      </FloatRight>
    )
  }
}

export default withTranslation()(MedToggle)
