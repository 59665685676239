import styled from 'styled-components'
import * as palette from 'styles/palette'
import MedButton from 'lib/elements/MedButton'

export const LoginFormContainer = styled.div`
  max-width: 48rem;
  padding: 4.8rem 1.2rem 4rem;
  background-color: ${palette.white};
  border-radius: 0.7rem;
  @media (max-width: 480px) {
    border-radius: 0;
  }
`

export const LoginForm = styled.form`
  padding: 0 6rem;
  text-align: center;
  @media (max-width: 375px) {
    padding: 0 2rem;
  }
`

export const LoginTitle = styled.div`
  text-align: left;
  &&& > span {
    font-size: 20px;
    font-weight: bolder;
    color: ${palette.primaryDarkGreen};
  }
`

export const LoginLabel = styled.p`
  font-weight: 600;
  color: ${palette.primaryLightGrey};
  text-align: left;
  margin-top: 3rem;
  margin-bottom: 0;
`

export const LoginButton = styled(MedButton)`
  &&& {
    margin-top: 2.4rem;
    background-color: ${palette.primaryLightGreen};
    &:hover {
      background-color: ${palette.primaryDarkGreen};
    }
    padding: 1rem 2.5rem;
  }
`

export const ErrorTextLabel = styled.div`
  background-color: ${palette.errorBGColor};
  border-radius: 0.7rem;
  color: ${palette.white};
  padding: 0.7rem 1.3rem;
  transition: all ease-in-out 0.6s;
  white-space: nowrap;
`
