import React from 'react'
import styled from 'styled-components'
import DialogContent from '@material-ui/core/DialogContent'

export const Content = styled(props => <DialogContent {...props} />)`
  &&& {
    padding: 2.2rem 2.7rem;
    padding-bottom: 2.6rem;
    overflow-y: hidden;
  }
`

export default Content
