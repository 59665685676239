import React from 'react'
import styled from 'styled-components'
import * as palette from 'styles/palette'
import Paper from '@material-ui/core/Paper'
import Tab from '@material-ui/core/Tab'

export const SettingsContainer = styled(props => <Paper {...props} />)`
  background-color: ${palette.white};
  max-width: 126.3rem;
  margin: auto;
  padding: 4em;
  margin-top: 6.6em;
  border-radius: 7px;
  @media only screen and (max-width: 1470px) {
    padding: 2.6em;
    margin-left: 1.4rem;
  }
`

export const SettingsTitle = styled.span`
  color: ${palette.primaryDarkGrey};
  font-size: 1.3em;
  font-weight: 550;
  color: ${palette.primaryDarkGreen};
`

export const MenuItem = styled(props => <Tab {...props} />)`
  text-transform: capitalize !important;
  color: ${palette.primaryDarkGreen} !important;
  font-weight: 600 !important;
`
