import {
  LOGIN_AUTH_SUCCESS,
  LOGOUT_AUTH_SUCCESS,
  GET_USER_DATA_PENDING,
  GET_USER_DATA_SUCCESS,
  GET_USER_DATA_FAIL,
  GET_USER_RESET
} from 'actions'
import moment from 'moment'

const initialState = {
  step: 1,
  isGettingUserData: false,
  userData: {},
  persistExpiresAt: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    // When login is success, set the persist expiration time
    case LOGIN_AUTH_SUCCESS: {
      const { user } = action.payload
      return {
        ...state,
        persistExpiresAt: moment.unix(user.expiresAt).format()
      }
    }

    case LOGOUT_AUTH_SUCCESS: {
      return initialState
    }

    case GET_USER_DATA_PENDING: {
      return {
        ...state,
        isGettingUserData: true,
        userData: {}
      }
    }
    case GET_USER_DATA_SUCCESS: {
      const userData = action.payload
      return {
        ...state,
        isGettingUserData: false,
        userData: Object.assign({}, userData),
        errors: {}
      }
    }
    case GET_USER_DATA_FAIL: {
      const errors = action.payload
      return {
        ...state,
        userData: {},
        isGettingUserData: false,
        errors: {
          errors
        }
      }
    }
    case GET_USER_RESET: {
      return initialState
    }
    default: {
      return state
    }
  }
}
