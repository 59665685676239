import palette from './styles/palette'

export const MED_APP_PHARMA_TOKEN = 'med_app_pharma_token'
export const CLIENT_LOGOUT = 'RESET_STATE'
export const PERSIST_KEY = 'state'
export const HEADERS = {
  PRO: 'Pro werden',
  LOGOUT: 'Ausloggen',
  TUTORIAL: 'Tutorial',
  SETTING: 'Einstellungen',
  TEAM: 'Team (1)',
  SIGN: 'Anmelden',
  REGISTER: 'Registrieren',
  USER: 'K. Jordan'
}
export const FILTERS = {
  ALL: 'all',
  NEW: 'new',
  EDIT: 'edit',
  COMPLETE: 'complete'
}
export const REPORT_HEADERS = [
  'Bearbeiter',
  'Datum',
  'Patient',
  'Arzneimittel',
  'Status'
]
export const REPORT_STEPS = {
  PatientReport: 'Patientenbericht',
  DoctorConfirm: 'Ärztliche Bestätigung',
  ProductorConfirm: 'Herstellerbestätigung'
}
export const REPORT_STATUS = {
  New: 'pharma-assigned',
  pharmaOpened: 'pharma-opened',
  inProgress: 'pharma-in-progress',
  QuestionsAsked: 'pharma-question-asked',
  ReminderSent: 'pharma-reminder-sent',
  QuestionsAnswered: 'pharma-question-answered',
  Closed: 'pharma-closed',
  Archived: 'pharma-archived'
}
export const USER_ROLE = {
  Patient: 0,
  Doctor: 1,
  Productor: 2
}
export const CARD_TYPE = {
  Free: 'Kostenlose Version',
  Plus: 'Basic Plus Version',
  Pro: 'Pro Version',
  Enterprise: 'Enterprise Version'
}
export const TEAM_ROLE = {
  Admin: 'Admin',
  Editor: 'Editor',
  ReadOnly: 'Read-only'
}

// Some strings used in the Layout. We need proper String management.
export const GENDER_MAP = {
  other: 'Sonstige',
  divers: 'Divers',
  female: 'Weiblich',
  male: 'Männlich'
}
export const HABIT_MAP = {
  smoking: 'Rauchen',
  drinking: 'Trinken',
  sport: 'Sport',
  others: 'Sonstiges'
}

export const UNITS = {
  packages: 'Schachteln',
  exercises: 'Übungen'
}

export const INTERVALS = {
  day: 'täglich',
  weekly: 'wöchentlich'
}

export const DOSAGE_UNITS = {
  piece: 'Stück',
  ml: 'Milliliter',
  tablet: 'Tabletten',
  suppository: 'Zäpfchen',
  capsule: 'Kapseln',
  drop: 'Tropfen',
  mg: 'Milligramm'
}

export const DURATIONS = {
  hours: 'Stunden',
  days: 'Tage',
  weeks: 'Wochen',
  months: 'Monate',
  when_needed: 'bei Bedarf'
}

export const COUNTRIES = {
  de: 'Deutschland',
  ch: 'Schweiz',
  at: 'Österreich'
}

export const PHARMA_PLANS = {
  engage_pro: 'Pharma ENGAGE Premium',
  engage_basic: 'Pharma ENGAGE Basic',
  engage_basic_plus: 'Pharma ENGAGE Basic Plus'
}

export const PROFILE_COLORS = [
  palette.teal,
  palette.primaryDarkGreen,
  palette.primaryLightGreen,
  palette.rowActiveColor,
  palette.navy,
  palette.plum
]
