import { identityInstanceAxios as identity } from '../config'

const REGISTER_URL = '/pharma/register'
const REQUEST_PRO_EMAIL_URL = 'pharma/requestPro'

/**
 * Registration request
 *
 * @returns {Promise} - Result of ajax call
 */
export async function registerAPI (data, token) {
  const config = {
    url: REGISTER_URL,
    method: 'post',
    data: data,
    headers: { Authorization: token }
  }

  return identity(config)
}

export async function requestProByMail (token) {
  const config = {
    url: REQUEST_PRO_EMAIL_URL,
    method: 'post',
    headers: { Authorization: token }
  }
  return identity(config)
}
