import { getSideEffectsAPI } from 'api'

// Define action types
export const GET_SIDEEFFECTS_SUCCESS = 'GET_SIDEEFFECTS_SUCCESS'
export const GET_SIDEEFFECTS_FAIL = 'GET_SIDEEFFECTS_FAIL'
export const GET_SIDEEFFECTS_PENDING = 'GET_SIDEEFFECTS_PENDING'

export const GET_SIDEEFFECTS = async params => {
  try {
    const res = await getSideEffectsAPI(params)
    if (res.data.name) {
      return { type: GET_SIDEEFFECTS_SUCCESS, payload: { report: res.data } }
    } else {
      return { type: GET_SIDEEFFECTS_PENDING, payload: { reports: res.data } }
    }
  } catch (err) {
    return { type: GET_SIDEEFFECTS_FAIL, payload: err.response.data }
  }
}
