import React from 'react'
import PropTypes from 'prop-types'
import { ContentText, YesBtn, NoBtn } from './style'

import Dialog from '@material-ui/core/Dialog'
import ContentHeader from 'lib/elements/ContentHeader'
import Content from 'lib/elements/Content'
import DlgActions from 'lib/elements/DlgActions'
import { withTranslation, Trans } from 'react-i18next'
import I18n from 'i18n'

const AttentionModal = ({ onSend, onCancel, isReject }) => {
  return (
    <Dialog
      aria-describedby='alert-dialog-description'
      aria-labelledby='alert-dialog-title'
      onClose={onCancel}
      open={isReject}
    >
      <Content>
        <ContentHeader>
          {I18n.t('components.Reports.AttentionModal.header')}
        </ContentHeader>
        <ContentText>
          <Trans
            i18nKey='components.Reports.AttentionModal.text'
            values={{
              break: '<br />'
            }}
          />
        </ContentText>
      </Content>
      <DlgActions>
        <YesBtn onClick={onSend} variant='contained'>
          {I18n.t('components.Reports.AttentionModal.yesBtn')}
        </YesBtn>
        <NoBtn onClick={onCancel} variant='contained'>
          {I18n.t('components.Reports.AttentionModal.noBtn')}
        </NoBtn>
      </DlgActions>
    </Dialog>
  )
}

AttentionModal.propTypes = {
  isReject: PropTypes.bool.isRequired,
  onSend: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default withTranslation()(AttentionModal)
