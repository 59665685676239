import React from 'react'
import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { makeStyles } from '@material-ui/core/styles'

import * as palette from 'styles/palette'
import { REPORT_STATUS } from '../../../constants'

const getColorForStatusBtn = (status, applyTo) => {
  switch (status) {
    case REPORT_STATUS.Closed:
    case REPORT_STATUS.Archived:
      if (applyTo === 'background') {
        return palette.primaryLightGrey
      } else if (applyTo === 'text') {
        return palette.white
      } else if (applyTo === 'border') {
        return palette.primaryLightGrey
      }
      break

    case REPORT_STATUS.QuestionsAnswered:
    case REPORT_STATUS.New:
      if (applyTo === 'background') {
        return palette.primaryLightGreen
      } else if (applyTo === 'text') {
        return palette.white
      } else if (applyTo === 'border') {
        return palette.primaryLightGreen
      }
      break

    case REPORT_STATUS.QuestionsAsked:
      if (applyTo === 'background') {
        return palette.primaryDarkRed
      } else if (applyTo === 'text') {
        return palette.white
      } else if (applyTo === 'border') {
        return palette.primaryDarkRed
      }
      break

    case REPORT_STATUS.ReminderSent:
      if (applyTo === 'background') {
        return palette.primaryCarmineRed
      } else if (applyTo === 'text') {
        return palette.white
      } else if (applyTo === 'border') {
        return palette.primaryDarkRed
      }
      break

    case REPORT_STATUS.pharmaOpened:
    case REPORT_STATUS.inProgress:
      if (applyTo === 'background') {
        return palette.white
      } else if (applyTo === 'text') {
        return palette.black
      } else if (applyTo === 'border') {
        return palette.primaryDarkGrey
      }
      break
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  button: props => ({
    width: '120px',
    backgroundColor: getColorForStatusBtn(props.status, 'background'),
    color: getColorForStatusBtn(props.status, 'text'),
    border: `1px solid ${getColorForStatusBtn(props.status, 'border')}`,
    textTransform: 'none',
    whiteSpace: 'nowrap',
    padding: '1px 9px 1px 7px',
    borderRadius: '0.7rem',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    '& .MuiButton-label': {
      fontSize: '11px'
    }
  }),
  paper: {
    marginRight: theme.spacing(2)
  }
}))

export default function MenuListComposition (props) {
  const classes = useStyles(props)
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown (event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }
  const onMenuItemClick = (event, handleMenuItemClick) => {
    handleMenuItemClick(event)
    setOpen(false)
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <div className={classes.root}>
      <div>
        <Button
          ref={anchorRef}
          className={classes.button}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup='true'
          onClick={handleToggle}
        >
          {props.text}
        </Button>
        {!props.menuDisabled && (
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={props.popperStyle}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom'
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id='menu-list-grow'
                      onKeyDown={handleListKeyDown}
                    >
                      {props.menuList.map((item, index) => (
                        <MenuItem
                          key={index}
                          disabled={item.isItemDisabled}
                          onClick={e => onMenuItemClick(e, item.onItemClick)}
                        >
                          {item.text}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        )}
      </div>
    </div>
  )
}
