import React from 'react'
import AdditionalComment from './AdditionalComment'
import PropTypes from 'prop-types'

const DiseasesModalContent = ({ count, onAddComment, onRemoveComment }) => {
  const a = []
  for (let i = 0; i < count; i++) {
    a.push(i)
  }
  return (
    <div>
      {a.map((item, index) => (
        <AdditionalComment
          index={item}
          key={index}
          onAddComment={onAddComment}
          onRemoveComment={onRemoveComment}
        />
      ))}
    </div>
  )
}

DiseasesModalContent.propTypes = {
  count: PropTypes.number,
  onAddComment: PropTypes.func,
  onRemoveComment: PropTypes.func
}

export default DiseasesModalContent
