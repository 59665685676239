import styled from 'styled-components'
import * as palette from 'styles/palette'
import MedButton from 'lib/elements/MedButton'

export const TeaserSentence = styled.p`
  font-size: 1.3rem;
  line-height: 2.5rem;
  color: ${palette.primaryDarkGreen};
  font-weight: 600;
`

export const TestProBtn = styled(MedButton)`
  &&& {
    width: 150px;
    background-color: ${palette.white} !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    color: ${palette.primaryDarkGreen};
    margin-top: 10px;
    font-weight: 600;
    z-index: 9;
    &:hover {
      background-color: ${palette.primaryLightGreen};
    }
  }
`
