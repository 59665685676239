import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import * as palette from 'styles/palette'

function LinearProgressWithLabel (props) {
  return (
    <Box display='flex' alignItems='center'>
      <Box width='100%' mr={1}>
        <LinearProgress variant='determinate' {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant='body2' color='textSecondary'>{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  )
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired
}

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  colorPrimary: {
    backgroundColor: palette.primarySuperLightGreen
  },
  barColorPrimary: {
    backgroundColor: palette.primaryDarkGreen
  }
})

export default function LinearWithValueLabel (props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <LinearProgressWithLabel
        value={props.progress}
        classes={{
          colorPrimary: classes.colorPrimary,
          barColorPrimary: classes.barColorPrimary
        }}
      />
    </div>
  )
}
