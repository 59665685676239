import React from 'react'
import PropTypes from 'prop-types'

import { Title } from './styles'

import QuestionItemBox from './QuestionItemBox'

const QuestionsEditPanel = ({ isPro, questions }) => {
  return questions.map((item, index) => {
    return (
      <div key={index}>
        <Title>{item.title}</Title>
        <div>
          {item.questionItems.map((qItem, qIndex) => {
            return (
              <div key={qIndex}>
                <QuestionItemBox isPro={isPro} questionItem={qItem} />
              </div>
            )
          })}
        </div>
      </div>
    )
  })
}

QuestionsEditPanel.propTypes = {
  isPro: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(PropTypes.object)
}

export default QuestionsEditPanel
