import React from 'react'
import styled from 'styled-components'
import Checkbox from '@material-ui/core/Checkbox'
import * as palette from 'styles/palette'

const MedCheckbox = styled(props => <Checkbox {...props} />)`
  &&& {
    height: 1.4rem;
    width: 3rem;
    & > span,
    svg {
      font-size: 12px;
      color: ${palette.primaryDarkGreen};
    }
  }
`

export default MedCheckbox
