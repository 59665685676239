import React from 'react'
import styled from 'styled-components'
import * as palette from 'styles/palette'
import Input from 'lib/elements/Input'
import Button from '@material-ui/core/Button'
import MedCheckBox from 'lib/elements/Checkbox'
import DatePicker from 'react-datepicker'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import CloseIcon from '@material-ui/icons/Close'

export const InputLabel = styled.span`
  color: ${palette.dlgFontColor};
  display: block;
  margin-top: 1.1rem;
  padding-bottom: 6px;
`

export const InputBox = styled(Input)`
  &&& {
    border: 1px solid ${palette.primarySuperLightGreen};
    margin: 0;
    padding-left: 1rem !important;
    &::placeholder {
      color: ${palette.textAreaColor} !important;
    }
  }
`

export const InputBoxForOpacity = InputBox.extend`
  &&& {
    opacity: 0.3;
    &:focus {
      opacity: 1;
    }
  }
`

export const MenuBtn = styled(props => <Button {...props} />)`
  &&& {
    width: 100%;
    border: 1px solid ${palette.primaryLightGreen};
    border-radius: 0.5rem;
    padding: 0 2rem;
    padding-right: 0;
    min-height: 32px;
    height: 32px;
    & > span {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: ${palette.primaryLightGreen};
      text-transform: none;
    }
  }
`

export const Asterisk = styled.span`
  color: ${palette.primaryDarkRed};
`

export const DurationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.5rem;
`

export const DontKnowContainer = styled.div`
  display: flex;
  align-items: center;
`

export const CheckBox = styled(MedCheckBox)`
  &&& {
    margin-top: 0.5rem;
  }
`

export const MedDatePicker = styled(DatePicker)`
  border: 1px solid ${palette.primaryLightGreen};
  color: ${palette.primaryLightGreen};
  border-radius: 5px;
  padding: 0.7rem;
  &:focus,
  &:active {
    outline: none;
    border-color: ${palette.primaryDarkGreen};
    box-shadow: 0 0 0 0.3rem rgba(17, 121, 130, 0.25);
  }
`

export const ProText = styled.p`
  margin: 0;
  padding: 0.5rem 0.7rem;
  line-height: 1.8rem;
  font-weight: 700;
  color: ${palette.primaryLightGreen};
  &:first-child {
    padding-left: 0;
  }
`

export const DatePickerContainer = styled.div`
  position: relative;
  .react-datepicker-popper {
    transform: none !important;
    margin: 0;
    .react-datepicker {
      border-radius: 5px;
      &__triangle {
        display: none;
      }
      &__navigation--previous {
        left: 25px;
        border-right-color: ${palette.primaryDarkGreen};
      }
      &__navigation--next {
        right: 25px;
        border-left-color: ${palette.primaryDarkGreen};
      }
      &__header {
        background-color: white;
        border: none;
      }
      &__current-month {
        color: ${palette.primaryDarkGreen};
      }
      &__day-names {
        .react-datepicker__day-name {
          font-size: 8px;
          font-weight: 700;
          color: ${palette.primaryLightGrey};
        }
      }
      &__week {
        padding: 2px;
      }
      &__day {
        font-size: 8px;
        color: ${palette.dlgFontColor};
        &--selected {
          padding: 5px !important;
          border-radius: 5px !important;
          background-color: ${palette.primaryLightGreen};
          color: ${palette.white};
        }
        &--in-selecting-range {
          background-color: ${palette.primarySuperLightGreen};
          border-radius: 0;
          margin: 0;
          padding: 0.166rem;
          color: ${palette.white};
        }
        &--in-range {
          background-color: ${palette.primaryLightGreen};
          border-radius: 0;
          padding: 0.166rem;
          margin: 0;
          color: ${palette.white};
        }
      }
    }
  }
`

export const CalendarIcon = styled(props => <CalendarTodayIcon {...props} />)`
  position: absolute;
  top: 7px;
  right: 8px;
  width: 14px !important;
  height: 14px !important;
  color: ${palette.primaryLightGreen};
`

export const FromToContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.8rem;
`

export const TextBox = styled.textarea`
  &&& {
    height: 7.3rem;
    border-radius: 0.5rem;
    border: 1px solid ${palette.textAreaColor};
    width: 100%;
    margin-top: 1.1rem;
    padding: 0.8rem 1.2rem;
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
  }
`

export const HiddenCommentBtn = styled(props => <CloseIcon {...props} />)`
  padding: 0.3rem;
  border: 1px solid ${palette.primaryLightGreen};
  border-radius: 0.5rem;
  color: ${palette.primaryLightGreen};
  margin-left: 0.9rem;
  &:hover {
    cursor: pointer;
    border-color: ${palette.primaryDarkGreen};
    color: ${palette.primaryDarkGreen};
  }
`

export const DisplayFlex = styled.div`
  display: flex;
`
