import React, { Component } from 'react'
import { Redirect, Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import I18n from 'i18n'
import validator from 'validator'
import {
  LoginFormContainer,
  LoginForm,
  LoginTitle,
  LoginLabel,
  LoginButton,
  ErrorTextLabel
} from './style'
import Input from 'lib/elements/Input'
import InputContainer from 'lib/elements/InputContainer'
import ErrorLabel from '../ErrorLabel'
import CircularProgress from '@material-ui/core/CircularProgress'
import { LOGIN_AUTH, LOGIN_AUTH_PENDING } from 'actions'
import { ErrorMessage } from '../LoginReset/style'

class Login extends Component {
  state = {
    goToReports: false,
    isLoading: false,

    // Form values
    userData: {
      email: '',
      password: ''
    },

    // validations
    isValid: {
      email: true
    },

    serverError: false,
    loginOrPasswordError: false,
    token: ''
  }

  handleChangeEmail = event => {
    const { isValid, userData } = this.state
    this.setState(
      {
        userData: {
          ...userData,
          email: event.currentTarget.value
        }
      },
      () => {
        if (validator.isEmail(this.state.userData.email)) {
          isValid.email = true
        } else {
          isValid.email = false
        }
        this.setState({ isValid })
      }
    )
  }

  handleChangePassword = event => {
    const { userData } = this.state
    this.setState({
      userData: {
        ...userData,
        password: event.currentTarget.value
      }
    })
  }

  isValidation = () => {
    const { isValid, userData } = this.state
    const { email } = userData

    if (!validator.isEmail(email)) {
      isValid.email = false
    }

    const formValid = isValid.email
    this.setState({ isValid })
    return formValid
  }

  handleLogin = async () => {
    this.setState({ isLoading: true })
    if (!this.isValidation()) {
      this.setState({ isLoading: false })
      return
    }

    try {
      const response = await this.props.login(this.state.userData)
      if (response.type === 'LOGIN_AUTH_SUCCESS') {
        const token = response.payload.user.token.split(' ')[1]

        this.setState({
          goToReports: true,
          reportId: '',
          isLoading: false,
          token: token
        })
      } else {
        this.setState({
          isLoading: false,
          loginOrPasswordError: true
        })
      }
    } catch (err) {
      this.setState({
        isLoading: false,
        serverError: true
      })
    }
  }

  handleSubmit = event => {
    event.preventDefault()
  }

  handleEnter = event => {
    if (event.key === 'Enter') {
      this.handleLogin()
    }
  }

  render () {
    const {
      goToReports,
      isLoading,
      isValid,
      serverError,
      loginOrPasswordError
    } = this.state

    const reportId =
      (this.props.history.location.state &&
        this.props.history.location.state.reportId) ||
      ''

    if (goToReports) {
      return <Redirect to={'/reports/' + reportId} />
    }

    return (
      <LoginFormContainer>
        <LoginForm onSubmit={this.handleSubmit}>
          <LoginTitle>
            <span>{I18n.t('components.Login.title')}</span>
          </LoginTitle>
          {this.props.wrongAccountError && (
            <ErrorMessage>
              {I18n.t('components.Login.errors.wrongAccount')}
            </ErrorMessage>
          )}
          {this.props.reportLinkDisabledError && (
            <ErrorMessage>
              {I18n.t('components.Login.errors.reportLinkDisabled')}
            </ErrorMessage>
          )}
          <LoginLabel>{I18n.t('components.Login.label')}</LoginLabel>
          <InputContainer>
            <Input
              name='email'
              onChange={this.handleChangeEmail}
              placeholder={I18n.t('components.Login.placeholders.email')}
              type='text'
            />
            <ErrorLabel
              errText={I18n.t('components.Login.errors.invalidEmail')}
              hasError={!isValid.email}
            />
          </InputContainer>
          <InputContainer>
            <Input
              onKeyPress={this.handleEnter}
              name='password'
              onChange={this.handleChangePassword}
              placeholder={I18n.t('components.Login.placeholders.password')}
              type='password'
            />
          </InputContainer>
          <Link to='/login/reset'>
            <LoginLabel>
              {I18n.t('components.Login.buttons.forgotPassword')}
            </LoginLabel>
          </Link>
          {isLoading ? (
            <>
              <br /> <br /> <br />
              <CircularProgress />
            </>
          ) : (
            <LoginButton onClick={this.handleLogin}>
              {I18n.t('components.Login.buttons.login')}
            </LoginButton>
          )}
          <br /> <br />
          {serverError && (
            <ErrorTextLabel>
              {I18n.t('components.Login.errors.serverError')}
            </ErrorTextLabel>
          )}
          {loginOrPasswordError && (
            <ErrorTextLabel>
              {I18n.t('components.Login.errors.loginOrPasswordError')}
            </ErrorTextLabel>
          )}
        </LoginForm>
      </LoginFormContainer>
    )
  }
}

function mapStateToProps (state) {
  const { wrongAccountError, reportLinkDisabledError } = state.route
  return { wrongAccountError, reportLinkDisabledError }
}

function mapDispatchToProps (dispatch) {
  return {
    login: async data => {
      // user login
      dispatch({ type: LOGIN_AUTH_PENDING })
      const loginRes = await LOGIN_AUTH(data)
      return dispatch(loginRes)
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
