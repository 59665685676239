import styled from 'styled-components'
import * as palette from 'styles/palette'

const Input = styled.input`
  border-radius: 0.7rem;
  border: 1px solid ${palette.primaryDarkGreen};
  box-sizing: border-box;
  width: 100%;
  color: ${palette.primaryDarkGrey} !important;
  background: ${palette.white};
  padding: 0.7rem 2rem !important;
  margin-top: 1.6rem;
  transition: all ease-in-out 0.3s;
  border-color: ${props =>
    props.haserror ? palette.errorBGColor : palette.primaryDarkGreen};
  &::placeholder {
    color: ${palette.primaryLightGreen} !important;
  }
  &:focus,
  &:active {
    border-color: none;
    box-shadow: 0 0 0 0.3rem rgba(17, 121, 130, 0.25);
    box-shadow: ${props =>
      props.haserror
        ? `0 0 0 0.3rem rgba(235,87,87,.25)`
        : `0 0 0 0.3rem rgba(17,121,130,.25)`};
    outline: none;
  }
`

export default Input
