import React from 'react'
import styled from 'styled-components'
import DialogActions from '@material-ui/core/DialogActions'

export const DlgActions = styled(props => <DialogActions {...props} />)`
  &&& {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 2.4rem 2.3rem;
    padding-top: 0;
  }
`

export default DlgActions
