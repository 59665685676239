import React, { Component } from 'react'
import LoginReset from 'components/LoginReset'
import {
  LoginWrapper,
  LoginHeader,
  LoginFormWrapper
} from 'containers/LoginContainer/style'
import background from 'assets/img/medikura-background.png'

class LoginResetContainer extends Component {
  render () {
    return (
      <>
        <LoginWrapper>
          <LoginHeader />
          <LoginFormWrapper imageUrl={background}>
            <LoginReset />
          </LoginFormWrapper>
        </LoginWrapper>
      </>
    )
  }
}

export default LoginResetContainer
