import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import I18n from 'i18n'
import { connect } from 'react-redux'
import validator from 'validator'
import CircularProgress from '@material-ui/core/CircularProgress'

import { PASSWORD_RESET, PASSWORD_RESET_PENDING } from 'actions/reset/index'
import {
  LoginForm,
  LoginTitle,
  LoginLabel,
  LoginButton
} from 'components/Login/style'
import {
  ResetPasswordContainer,
  BackButton,
  ButtonsContainer,
  SuccessMessage,
  ErrorMessage
} from './style'
import Input from 'lib/elements/Input'
import InputContainer from 'lib/elements/InputContainer'
import ErrorLabel from 'components/ErrorLabel'

class LoginReset extends Component {
  state = {
    loginResetError: false,
    email: '',
    goBack: false,
    goForward: false,
    isLoading: false,

    // validations
    isValid: {
      email: true
    }
  }

  isValidation = () => {
    const { isValid, email } = this.state

    if (!validator.isEmail(email)) {
      isValid.email = false
    }

    const formValid = isValid.email
    this.setState({ isValid })
    return formValid
  }

  handleChangeEmail = event => {
    const { isValid } = this.state
    this.setState(
      {
        email: event.currentTarget.value
      },
      () => {
        if (validator.isEmail(this.state.email)) {
          isValid.email = true
        } else {
          isValid.email = false
        }
        this.setState({ isValid })
      }
    )
  }

  handleBack = () => {
    this.setState({
      goBack: true
    })
  }

  handleResetPassword = async () => {
    this.setState({ isLoading: true })
    if (!this.isValidation()) {
      this.setState({ isLoading: false })
      return
    }

    try {
      const request = {
        email: this.state.email,
        callbackURL:
          window.location.protocol +
          '//' +
          window.location.host +
          '/login/newPassword'
      }
      const response = await this.props.reset(request)
      if (response.type === 'PASSWORD_RESET_SUCCESS') {
        this.setState({
          goForward: true
        })
      } else {
        this.setState({
          isLoading: false,
          loginResetError: true
        })
      }
    } catch (error) {
      this.setState({
        loginResetError: true
      })
    }
  }

  handleSubmit = event => {
    event.preventDefault()
  }

  handleEnter = event => {
    if (event.key === 'Enter') {
      this.handleResetPassword()
    }
  }

  render () {
    const { goBack, goForward, isValid, isLoading } = this.state

    if (goBack) {
      return <Redirect to='/login' />
    }

    return (
      <ResetPasswordContainer>
        <LoginForm onSubmit={this.handleSubmit}>
          {!goForward ? (
            <>
              <LoginTitle>
                <span>{I18n.t('components.LoginReset.title')}</span>
              </LoginTitle>
              {!isLoading ? (
                <>
                  {this.props.tokenExpiredError && (
                    <ErrorMessage>
                      {I18n.t('components.LoginReset.errors.tokenExpired')}
                    </ErrorMessage>
                  )}
                  <LoginLabel>
                    {I18n.t('components.LoginReset.label')}:
                  </LoginLabel>
                  <InputContainer>
                    <Input
                      name='email'
                      onChange={this.handleChangeEmail}
                      placeholder={I18n.t(
                        'components.LoginReset.placeholders.email'
                      )}
                      type='text'
                      onKeyPress={this.handleEnter}
                    />
                    <ErrorLabel
                      errText={I18n.t(
                        'components.LoginReset.errors.invalidEmail'
                      )}
                      hasError={!isValid.email}
                    />
                  </InputContainer>
                  <ButtonsContainer>
                    <BackButton onClick={this.handleBack}>
                      {I18n.t('components.LoginReset.backButton')}
                    </BackButton>
                    <LoginButton onClick={this.handleResetPassword}>
                      {I18n.t('components.LoginReset.requestPassword')}
                    </LoginButton>
                  </ButtonsContainer>
                </>
              ) : (
                <>
                  <br /> <br /> <br />
                  <CircularProgress />
                </>
              )}
            </>
          ) : (
            <>
              <LoginTitle>
                <span>{I18n.t('components.LoginReset.title')}</span>
              </LoginTitle>
              <SuccessMessage>
                {I18n.t('components.LoginReset.successMessage')}
              </SuccessMessage>
              <ButtonsContainer goForward={goForward}>
                <LoginButton onClick={this.handleBack}>
                  {I18n.t('components.LoginReset.login')}
                </LoginButton>
              </ButtonsContainer>
            </>
          )}
        </LoginForm>
      </ResetPasswordContainer>
    )
  }
}

function mapStateToProps (state) {
  const { errors, isResettingPassword, tokenGenerated } = state.reset
  const { tokenExpiredError } = state.route

  return { errors, isResettingPassword, tokenGenerated, tokenExpiredError }
}

function mapDispatchToProps (dispatch) {
  return {
    reset: async data => {
      dispatch({ type: PASSWORD_RESET_PENDING })
      const res = await PASSWORD_RESET(data)
      return dispatch(res)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginReset)
