import RegisterContainer from 'containers/RegisterContainer'
import Reports from 'containers/ReportsContainer'
import Launch from 'components/Launch'
import RegisterConfirm from 'containers/RegisterConfirmContainer'
import LoginContainer from 'containers/LoginContainer'
import LoginResetContainer from 'containers/LoginResetContainer'
import LoginNewPasswordContainer from 'containers/LoginNewPasswordContainer'
import Settings from 'containers/SettingsContainer'
import Followups from 'containers/FollowupsContainer'
import ErrorPageContainer from 'containers/ErrorPageContainer'
import { createBrowserHistory } from 'history'
import Default from 'components/Default'

import WithAuth from './withAuth'
import WithUrlToken from './withUrlToken'

export const history = createBrowserHistory()

export const routes = [
  /* Currently, pharma people only get a link with an identifying token. */
  {
    path: '/',
    exact: true,
    component: Default
  },
  {
    path: '/login',
    exact: true,
    component: LoginContainer
  },
  {
    path: '/login/reset',
    exact: true,
    component: LoginResetContainer
  },
  {
    path: '/login/newPassword',
    exact: true,
    component: WithUrlToken(LoginNewPasswordContainer)
  },
  {
    path: '/register',
    exact: true,
    component: WithUrlToken(RegisterContainer)
  },
  {
    path: '/register-confirm',
    exact: true,
    component: RegisterConfirm
  },
  {
    path: '/reports',
    exact: true,
    component: WithAuth(Reports)
  },
  {
    path: '/reports/:id',
    exact: true,
    component: WithUrlToken(Reports)
  },
  {
    path: '/launch',
    exact: true,
    component: Launch
  },
  {
    path: '/settings',
    exact: true,
    component: WithAuth(Settings)
  },
  {
    path: '/followups',
    exact: true,
    component: WithAuth(Followups)
  },
  {
    path: '/error',
    exact: true,
    component: ErrorPageContainer
  }
]
