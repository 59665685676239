import styled from 'styled-components'
import * as palette from 'styles/palette'
import MedButton from 'lib/elements/MedButton'

export const ConfirmForm = styled.form`
  padding: 25px 2rem;
  text-align: center;
  @media (max-width: 300px) {
    padding: 0 2rem;
  }
`

export const SuccessContainer = styled.div`
  max-width: 37rem;
  border-radius: 0.7rem;
  background-color: ${palette.white};
`

export const ConfirmBtn = styled(MedButton)`
  &&& {
    margin-top: 2rem;
    background-color: ${palette.primaryLightGreen};
    margin-bottom: 1.2rem;
    &:hover {
      background-color: ${palette.primaryDarkGreen};
    }
  }
`
