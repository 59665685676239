import styled from 'styled-components'
import * as palette from 'styles/palette'

export const ContentHeader = styled.p`
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 1.8rem;
  color: ${palette.primaryDarkGreen};
  margin: 0;
  margin-bottom: 1.7rem;
`

export default ContentHeader
