import styled from 'styled-components'
import * as palette from 'styles/palette'
import MedButton from 'lib/elements/MedButton'
import InputContainer from 'lib/elements/InputContainer'
import Input from 'lib/elements/Input'
import Label from 'lib/elements/Label'

export const InputsContainer = styled.div`
  padding: 0 20px 20px 20px;
`
export const InputContainerStyled = styled(InputContainer)`
  text-align: left;
  margin-top: 1.6rem;
`

export const InputStyled = styled(Input)`
  margin-top: 0.5rem;
  padding: 0.7rem 1rem !important;
`

export const LabelStyled = styled(Label)``

export const ErrorMessageContainer = styled.div`
  height: 30px;
  padding: 10px 0 10px 0;
}
`
export const ErrorTextLabel = styled.div`
  background-color: ${palette.errorBGColor};
  border-radius: 0.7rem;
  color: ${palette.white};
  padding: 0.7rem 1.3rem;
  position: unset;
  bottom: 1px;
  transition: all ease-in-out 0.6s;
  left: 100%;
  width: auto;
  white-space: nowrap;
  margin-left: 1.1rem;
  margin-right: 1.1rem;
  opacity: 0;
  opacity: 1;
  box-sizing: border-box;
`
export const UpdateDataBtn = styled(MedButton)`
  &&& {
    background-color: ${palette.primaryDarkGreen};
    &:hover {
      background-color: ${palette.primaryLightGreen};
    }
  }
`
export const NoBtn = styled(MedButton)`
  &&& {
    background-color: ${palette.primaryDarkRed};
    &:hover {
      background-color: ${palette.primaryLightRed};
    }
  }
`
