import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import {
  Container,
  LeftDetailViewContainer,
  ItemContainer,
  Title,
  TextLine,
  TextLineTitle,
  TextLineValue,
  LargeTitle,
  BodyCell,
  HeaderCell
} from './style'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { REPORT_STATUS } from '../../../../constants'
import I18n from 'i18n'
import MedToggle from 'lib/elements/MedToggle'
import {
  formatDose,
  formatDuration,
  formatLongString
} from 'lib/helper/formatters'
import { get as gv } from 'lodash'

class LeftDetailView extends Component {
  static propTypes = {
    report: PropTypes.object.isRequired,
    step: PropTypes.string,
    onSetStatus: PropTypes.func,
    onSetStep: PropTypes.func,
    status: PropTypes.string,
    isNR: PropTypes.bool,
    isQuestion: PropTypes.bool,
    isPN: PropTypes.bool,
    onSwitchReport: PropTypes.func
  }

  state = {
    isEditMedicinesUsedModal: false,
    isEditSideEffectsModal: false,
    anchorElCausality: null,
    causalityName: I18n.t(
      'components.ReportDetail.DetailContainer.LeftDetailView.unlikely'
    ),
    addionsText: '',
    isConfirmModal: false,
    isAgreeModal: false,
    isFinalQuestionsModal: false,
    isAnswerConfirmModal: false,
    isAnswerThankUModal: false,
    isAnswered: false,
    status: REPORT_STATUS.New,
    isPatient: true,
    isArzt: false
  }

  /* eslint-disable */
  componentWillReceiveProps (nextProps) {
    if (nextProps.status !== this.state.status) {
      this.setState({ status: nextProps.status })
    }
  }

  handleDocSelected = () => {
    // Called when the user wants to see the Doc's version of the report
    this.props.onSwitchReport(
      this.props.match && this.props.match.params.id,
      false
    )
    this.setState({
      isPatient: false,
      isArzt: true
    })
  }

  handlePatientSelected = test => {
    // Called when the user wants to see the Patient's version of the report
    this.props.onSwitchReport(
      this.props.match && this.props.match.params.id,
      true
    )
    this.setState({
      isPatient: true,
      isArzt: false
    })
  }

  render () {
    const { report, isPN, t } = this.props
    const { isFinalQuestionsModal, isPatient, isArzt } = this.state
    return (
      <Container>
        <LeftDetailViewContainer>
          <LargeTitle>
            {I18n.t(
              'components.ReportDetail.DetailContainer.LeftDetailView.title1'
            )}
          </LargeTitle>
          <MedToggle
            onDocSelected={this.handleDocSelected}
            onPatientSelected={this.handlePatientSelected}
            report={report}
          />
          <Title>
            {I18n.t(
              'components.ReportDetail.DetailContainer.LeftDetailView.title2'
            )}
          </Title>
          <ItemContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <HeaderCell isUnknown={false}>
                    {I18n.t(
                      'components.ReportDetail.DetailContainer.LeftDetailView.tableheaders.drug'
                    )}
                  </HeaderCell>
                  <HeaderCell isUnknown={false}>
                    {I18n.t(
                      'components.ReportDetail.DetailContainer.LeftDetailView.tableheaders.dosage'
                    )}
                  </HeaderCell>
                  <HeaderCell isUnknown={false}>
                    {I18n.t(
                      'components.ReportDetail.DetailContainer.LeftDetailView.tableheaders.intakeDuration'
                    )}
                  </HeaderCell>
                  <HeaderCell isUnknown={false}>
                    {I18n.t(
                      'components.ReportDetail.DetailContainer.LeftDetailView.tableheaders.indication'
                    )}
                  </HeaderCell>
                  <HeaderCell isUnknown={false}>
                    {I18n.t(
                      'components.ReportDetail.DetailContainer.LeftDetailView.tableheaders.suspicious'
                    )}
                  </HeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {gv(report, 'drugs', []).map((drug, index) => {
                  return (
                    <TableRow key={index}>
                      {/*Use <UnknownCell/> for highlighted cells here.*/}
                      <BodyCell>
                        {drug.name
                          ? drug.name
                          : I18n.t(
                              'components.ReportDetail.DetailContainer.LeftDetailView.na'
                            )}{' '}
                        <br />
                        Ch.-B.:{' '}
                        {gv(
                          drug,
                          'codes.batchNumber',
                          I18n.t(
                            'components.ReportDetail.DetailContainer.LeftDetailView.na'
                          )
                        )}
                      </BodyCell>
                      <BodyCell>
                        {drug.dose
                          ? formatDose(drug)
                          : I18n.t(
                              'components.ReportDetail.DetailContainer.LeftDetailView.na'
                            )}{' '}
                        <br />
                        {drug.action
                          ? I18n.t(
                              'components.ReportDetail.DetailContainer.LeftDetailView.actions.' +
                                drug.action
                            )
                          : I18n.t(
                              'components.ReportDetail.DetailContainer.LeftDetailView.na'
                            )}
                      </BodyCell>
                      <BodyCell>{formatDuration(drug)}</BodyCell>
                      <BodyCell>
                        {drug.indications && drug.indications.length > 0
                          ? formatLongString(drug.indications[0].name) +
                            (drug.indications.length > 1
                              ? I18n.t(
                                  'components.ReportDetail.DetailContainer.LeftDetailView.etc'
                                )
                              : '')
                          : I18n.t(
                              'components.ReportDetail.DetailContainer.LeftDetailView.na'
                            )}
                      </BodyCell>
                      <BodyCell>
                        {drug.suspicious === true
                          ? I18n.t(
                              'components.ReportDetail.DetailContainer.LeftDetailView.yes'
                            )
                          : drug.suspicious === false
                          ? I18n.t(
                              'components.ReportDetail.DetailContainer.LeftDetailView.no'
                            )
                          : I18n.t(
                              'components.ReportDetail.DetailContainer.LeftDetailView.na'
                            )}
                      </BodyCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </ItemContainer>
          <Title>
            {I18n.t(
              'components.ReportDetail.DetailContainer.LeftDetailView.title3'
            )}
          </Title>
          <ItemContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <HeaderCell isUnknown={false}>
                    {I18n.t(
                      'components.ReportDetail.DetailContainer.LeftDetailView.sideEffect'
                    )}
                  </HeaderCell>
                  <HeaderCell isUnknown={false}>
                    {I18n.t(
                      'components.ReportDetail.DetailContainer.LeftDetailView.duration'
                    )}
                  </HeaderCell>
                  <HeaderCell isUnknown={false}>
                    {I18n.t(
                      'components.ReportDetail.DetailContainer.LeftDetailView.outcome'
                    )}
                  </HeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {gv(report, 'effects', []).map((effect, index) => {
                  return (
                    <TableRow key={index}>
                      <BodyCell>
                        {effect.name
                          ? effect.name
                          : I18n.t(
                              'components.ReportDetail.DetailContainer.LeftDetailView.na'
                            )}
                      </BodyCell>
                      <BodyCell>{formatDuration(effect)}</BodyCell>
                      <BodyCell>
                        {effect.status
                          ? I18n.t(`constants.effectsStati.${effect.status}`)
                          : I18n.t(
                              'components.ReportDetail.DetailContainer.LeftDetailView.na'
                            )}
                      </BodyCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </ItemContainer>
          {isArzt && !isPatient && (
            <div>
              <Title style={{ padding: '22px 0' }}>
                {I18n.t(
                  'components.ReportDetail.DetailContainer.LeftDetailView.complement'
                )}
              </Title>
              <ItemContainer>
                <TextLine>
                  <TextLineTitle>
                    {I18n.t(
                      'components.ReportDetail.DetailContainer.LeftDetailView.causality'
                    )}
                  </TextLineTitle>
                  <TextLineValue>
                    {report.evaluation
                      ? t(`report.causality.${report.evaluation.causality}`)
                      : I18n.t(
                          'components.ReportDetail.DetailContainer.LeftDetailView.na'
                        )}
                  </TextLineValue>
                </TextLine>
                <TextLineTitle>
                  {I18n.t(
                    'components.ReportDetail.DetailContainer.LeftDetailView.comment'
                  )}
                </TextLineTitle>
                <TextLineValue>
                  {_.get(
                    report,
                    'evaluation.description',
                    I18n.t(
                      'components.ReportDetail.DetailContainer.LeftDetailView.na'
                    )
                  )}
                </TextLineValue>
              </ItemContainer>
            </div>
          )}
        </LeftDetailViewContainer>
      </Container>
    )
  }
}

export default withRouter(LeftDetailView)
