import {
  getFollowupsApi,
  addFollowupApi,
  deleteFollowupApi,
  editFollowupApi,
  uploadFileApi,
  downloadFileApi,
  deleteFileApi
} from 'api'

// Define action types
export const GET_FOLLOWUPS_SUCCESS = 'GET_FOLLOWUPS_SUCCESS'
export const GET_FOLLOWUPS_FAIL = 'GET_FOLLOWUPS_FAIL'
export const GET_FOLLOWUPS_PENDING = 'GET_FOLLOWUPS_PENDING'
export const ADD_FOLLOWUP_SUCCESS = 'ADD_FOLLOWUP_SUCCESS'
export const ADD_FOLLOWUP_FAIL = 'ADD_FOLLOWUP_FAIL'
export const ADD_FOLLOWUP_PENDING = 'ADD_FOLLOWUP_PENDING'
export const DELETE_FOLLOWUP_SUCCESS = 'DELETE_FOLLOWUP_SUCCESS'
export const DELETE_FOLLOWUP_FAIL = 'DELETE_FOLLOWUP_FAIL'
export const DELETE_FOLLOWUP_PENDING = 'DELETE_FOLLOWUP_PENDING'
export const EDIT_FOLLOWUP_SUCCESS = 'EDIT_FOLLOWUP_SUCCESS'
export const EDIT_FOLLOWUP_FAIL = 'EDIT_FOLLOWUP_FAIL'
export const EDIT_FOLLOWUP_PENDING = 'EDIT_FOLLOWUP_PENDING'
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS'
export const UPLOAD_FILE_FAIL = 'UPLOAD_FILE_FAIL'
export const UPLOAD_FILE_PENDING = 'UPLOAD_FILE_PENDING'
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS'
export const DOWNLOAD_FILE_FAIL = 'DOWNLOAD_FILE_FAIL'
export const DOWNLOAD_FILE_PENDING = 'DOWNLOAD_FILE_PENDING'
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS'
export const DELETE_FILE_FAIL = 'DELETE_FILE_FAIL'
export const DELETE_FILE_PENDING = 'DELETE_FILE_PENDING'

export const GET_FOLLOWUPS = async token => {
  try {
    const res = await getFollowupsApi(token)
    if (res.data) {
      return { type: GET_FOLLOWUPS_SUCCESS, payload: res.data }
    } else {
      return { type: GET_FOLLOWUPS_PENDING, payload: { reports: res.data } }
    }
  } catch (err) {
    return {
      type: GET_FOLLOWUPS_FAIL,
      payload: err.response && err.response.data
    }
  }
}

export const ADD_FOLLOWUP = async (followups, token) => {
  try {
    const res = await addFollowupApi(followups, token)
    if (res.data) {
      return { type: ADD_FOLLOWUP_SUCCESS, payload: { report: res.data } }
    } else {
      return { type: ADD_FOLLOWUP_PENDING, payload: { reports: res.data } }
    }
  } catch (err) {
    return {
      type: ADD_FOLLOWUP_FAIL,
      payload: err.response && err.response.data
    }
  }
}

export const DELETE_FOLLOWUP = async (_id, token) => {
  try {
    const res = await deleteFollowupApi(_id, token)
    if (res.data) {
      return { type: DELETE_FOLLOWUP_SUCCESS, payload: { report: res.data } }
    } else {
      return { type: DELETE_FOLLOWUP_PENDING, payload: { reports: res.data } }
    }
  } catch (err) {
    return {
      type: DELETE_FOLLOWUP_FAIL,
      payload: err.response && err.response.data
    }
  }
}

export const EDIT_FOLLOWUP = async (followups, token) => {
  try {
    const res = await editFollowupApi(followups, token)
    if (res.data) {
      return { type: EDIT_FOLLOWUP_SUCCESS, payload: { report: res.data } }
    } else {
      return { type: EDIT_FOLLOWUP_PENDING, payload: { reports: res.data } }
    }
  } catch (err) {
    return {
      type: EDIT_FOLLOWUP_FAIL,
      payload: err.response && err.response.data
    }
  }
}

export const UPLOAD_FILE = async (file, token, cb) => {
  try {
    const res = await uploadFileApi(file, token, cb)
    if (res.data) {
      return { type: UPLOAD_FILE_SUCCESS, payload: res.data }
    }
  } catch (err) {
    return {
      type: UPLOAD_FILE_FAIL,
      payload: err.response && err.response.data
    }
  }
}

export const DOWNLOAD_FILE = async (file, templateId, token) => {
  try {
    const res = await downloadFileApi(file, templateId, token)
    if (res.data) {
      return { type: DOWNLOAD_FILE_SUCCESS, payload: res }
    }
  } catch (err) {
    return {
      type: DOWNLOAD_FILE_FAIL,
      payload: err.response && err.response.data
    }
  }
}

export const DELETE_FILE = async (fileId, token) => {
  try {
    const res = await deleteFileApi(fileId, token)
    if (res.data) {
      return { type: DELETE_FILE_SUCCESS, payload: res.data }
    }
  } catch (err) {
    return {
      type: DELETE_FILE_FAIL,
      payload: err.response && err.response.data
    }
  }
}
