import {
  GET_REPORTS_SUCCESS,
  GET_REPORTS_FAIL,
  GET_REPORTS_PENDING
} from 'actions'

const initialState = {
  reports: [],
  reportsLoaded: false,
  errors: {},
  reportLoaded: false,
  report: {}
}

export default (state, action) => {
  if (typeof state === 'undefined') {
    return initialState
  }
  switch (action.type) {
    case GET_REPORTS_PENDING:
      return {
        ...state,
        errors: {},
        reportsLoaded: false
      }
    case GET_REPORTS_SUCCESS: {
      const { reports } = action.payload
      return {
        ...state,
        reports,
        reportsLoaded: true
      }
    }
    case GET_REPORTS_FAIL: {
      return {
        ...state,
        error_code: action.payload
      }
    }
    default: {
      return state
    }
  }
}
