import React from 'react'
import Register from '../../components/Register'
import Header from '../../components/Header'
import { BackgroundImageWrapper } from './style'

const RegisterContainer = props => {
  return (
    <>
      <Header />
      <BackgroundImageWrapper>
        <Register />
      </BackgroundImageWrapper>
    </>
  )
}

export default RegisterContainer
