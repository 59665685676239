import { newPasswordSetupAPI } from 'api'

export const NEW_PASSWORD_SETUP_PENDING = 'NEW_PASSWORD_SETUP_PENDING'
export const NEW_PASSWORD_SETUP_SUCCESS = 'NEW_PASSWORD_SETUP_SUCCESS'
export const NEW_PASSWORD_SETUP_FAIL = 'NEW_PASSWORD_SETUP_FAIL'

export const NEW_PASSWORD_SETUP = async (data, token) => {
  try {
    const res = await newPasswordSetupAPI(data, token)
    return { type: NEW_PASSWORD_SETUP_SUCCESS, payload: { user: res.data } }
  } catch (err) {
    return { type: NEW_PASSWORD_SETUP_FAIL, payload: err.response.data }
  }
}
