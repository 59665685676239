import styled from 'styled-components'
import { LoginFormContainer } from 'components/Login/style'

export const ResetPasswordContainer = styled(LoginFormContainer)`
  &&& {
    width: 600px;
  }
`

export const ButtonsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
`
