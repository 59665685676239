import { registerAPI } from 'api'

export const ACCOUNT_CREATION_PENDING = 'ACCOUNT_CREATION_PENDING'
export const ACCOUNT_CREATION_SUCCESS = 'ACCOUNT_CREATION_SUCCESS'
export const ACCOUNT_CREATION_FAIL = 'ACCOUNT_CREATION_FAIL'

export const ACCOUNT_CREATION = async (data, token) => {
  try {
    const res = await registerAPI(data, token)
    if (!res) {
      throw new Error('Account creation failed!')
    }
    return { type: ACCOUNT_CREATION_SUCCESS, payload: res && res.data.data }
  } catch (err) {
    return {
      type: ACCOUNT_CREATION_FAIL,
      payload:
        (err.response && err.response.status) || 'Account creation failed!'
    }
  }
}
