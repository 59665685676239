import styled from 'styled-components'
import { palette } from '@material-ui/system'

export const Container = styled.div`
  text-align: center;
`
export const RocketLogo = styled.p`
  margin-top: 2em;
  font-size: 15em;
`

export const Goodspeed = styled.p`
  font-size: 10em;
  color: ${palette.tableHeaderColor};
`

export const Timer = styled.p`
  font-size: 5em;
  color: ${palette.tableHeaderColor};
`
