import styled from 'styled-components'
import * as palette from 'styles/palette'

const Container = styled.div`
  background-color: ${palette.primaryBackground};
  display: flex;
  align-items: center;
  height: 100vh;
`
export default Container
