import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  TableHeaderContainer,
  TableRowContainer,
  TableHeaderCell,
  SortingIcon
} from './style'
const DE_TO_EN_HEADERS = {
  Bearbeiter: 'assignee',
  Datum: 'date',
  Patient: 'patient',
  Arzneimittel: 'drug',
  Status: 'status'
}

class TableHeader extends Component {
  constructor (props) {
    super(props)
    this.state = {
      ...this.initializeSortingState()
    }
  }

  componentDidUpdate (prev) {
    if (prev.sortReportsByDefault !== this.props.sortReportsByDefault) {
      if (this.props.sortReportsByDefault) {
        // sort by latest sorting options if they exist or by date if they dont
        if (this.state.lastSortingState) {
          this.sortReports({
            item: Object.keys(this.state.lastSortingState)[0],
            descendingSorting: this.state.lastSortingState[
              Object.keys(this.state.lastSortingState)[0]
            ].descendingSorting
          })
        } else {
          this.sortReports({ item: 'date', descendingSorting: true })
        }
      }
    }
  }

  initializeSortingState = () => {
    return {
      date: {
        descendingSorting: false,
        showArrow: false,
        shouldSort: true
      },
      drug: {
        descendingSorting: false,
        showArrow: false,
        shouldSort: true
      },
      patient: {
        descendingSorting: false,
        showArrow: false,
        shouldSort: false
      },
      assignee: {
        descendingSorting: false,
        showArrow: false,
        shouldSort: true
      },
      status: {
        descendingSorting: false,
        showArrow: false,
        shouldSort: true
      }
    }
  }

  sortReports = (options = {}) => {
    this.setState(
      {
        ...this.initializeSortingState(),
        [options.item]: {
          descendingSorting: options.descendingSorting,
          showArrow: true,
          shouldSort: true
        },
        lastSortingState: {
          [options.item]: {
            descendingSorting: options.descendingSorting,
            showArrow: true,
            shouldSort: true
          }
        }
      },
      () => {
        this.props.handleReportSorting(
          options.item && options.item.toLowerCase(),
          this.state[options.item].descendingSorting
        )
      }
    )
  }

  render () {
    const { headers, isBasic } = this.props
    return (
      <TableHeaderContainer>
        <TableRowContainer>
          {headers.map((item, index) => {
            let itemInEN = DE_TO_EN_HEADERS[item]
            // if user is basic or basic plus, dont show Assignee. If the user is pro user, dont show patient
            if (
              (isBasic && itemInEN === 'assignee') ||
              (!isBasic && itemInEN === 'patient')
            ) {
              return
            }
            return (
              <TableHeaderCell
                item={itemInEN}
                key={index}
                showArrow={this.state[itemInEN].showArrow}
                shouldSort={this.state[itemInEN].shouldSort}
                onClick={() =>
                  this.state[itemInEN].shouldSort &&
                  this.sortReports({
                    item: itemInEN,
                    descendingSorting: !this.state[itemInEN].descendingSorting
                  })
                }
              >
                <SortingIcon
                  descendingSorting={this.state[itemInEN].descendingSorting}
                  shouldSort={this.state[itemInEN].shouldSort}
                  showArrow={this.state[itemInEN].showArrow}
                />
                {item}
              </TableHeaderCell>
            )
          })}
        </TableRowContainer>
      </TableHeaderContainer>
    )
  }
}

TableHeader.propTypes = {
  headers: PropTypes.array.isRequired,
  handleCheckAll: PropTypes.func.isRequired
}

export default TableHeader
