import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import lodash from 'lodash'
import TableHeader from './TableHeader'
import TableBodySection from './TableBodySection'
import { TableContainer } from './style'
import { REPORT_STATUS } from '../../../src/constants'
import { getActiveTeamMembers } from 'selectors/team'
const SORT_BY_STATUS_ORDER = [
  REPORT_STATUS.New,
  REPORT_STATUS.pharmaOpened,
  REPORT_STATUS.inProgress,
  REPORT_STATUS.QuestionsAsked,
  REPORT_STATUS.ReminderSent,
  REPORT_STATUS.QuestionsAnswered,
  REPORT_STATUS.Closed,
  REPORT_STATUS.Archived
]

class ReportTable extends Component {
  state = {
    allChecked: false,
    checkedItems: {},
    reports: [],
    sortReportsByDefault: false
  }

  componentWillMount () {
    // populate checkitems with all the available report ids
    // as key and false as value (default option)
    const { allChecked } = this.state
    const { data } = this.props
    const newCheckedItems = {}

    data.map(item => {
      newCheckedItems[
        (item.metaData && item.metaData.id) || item.id
      ] = allChecked
    })

    this.setState({
      checkedItems: newCheckedItems
    })
  }

  componentDidUpdate (prev) {
    if (
      prev.data.length !== this.props.data.length ||
      !lodash.isEqual(prev.data, this.props.data)
    ) {
      // we need 'sortReportsByDefault' to be set to true after 'reports' have been populated, so the default sorting can happen
      this.setState({ reports: this.props.data, sortReportsByDefault: true })
    }
  }

  onCheckAll = () => {
    const { allChecked, checkedItems } = this.state

    this.setState({
      allChecked: !allChecked
    })

    const newCheckedItems = Object.assign({}, checkedItems)
    for (const id in newCheckedItems) {
      newCheckedItems[id] = !allChecked
    }

    this.setState({
      checkedItems: newCheckedItems
    })
  }

  onCheckbox = e => {
    const id = e.target.name
    const isChecked = e.target.checked
    this.setState({
      checkedItems: {
        [id]: isChecked
      }
    })
  }

  onReportSorting = (item, isDescendingSorting) => {
    let sortedReports = this.sortReports(
      item,
      isDescendingSorting,
      this.state.reports
    )
    this.setState({ reports: sortedReports, sortReportsByDefault: false })
  }

  getReportAssigneeName = userId => {
    let userInfo = this.props.teamMembers.filter(
      teamMember => teamMember._id === userId
    )[0]

    if (userInfo) {
      return `${userInfo.name.firstName} ${userInfo.name.lastName}`
    } else {
      return ''
    }
  }

  sortReports = (byItem, desc, reportsToSort) => {
    let sortedReports = []
    switch (byItem) {
      case 'date':
        sortedReports = reportsToSort.slice().sort((a, b) => {
          if (desc) {
            return new Date(b.assigned) - new Date(a.assigned)
          } else {
            return new Date(a.assigned) - new Date(b.assigned)
          }
        })
        return sortedReports
      case 'drug':
        sortedReports = reportsToSort.slice().sort((a, b) => {
          let primaryA = a.primaryDrug.toLowerCase()
          let primaryB = b.primaryDrug.toLowerCase()
          if (desc) {
            if (primaryA < primaryB) {
              return -1
            }
            if (primaryA > primaryB) {
              return 1
            }
            return 0
          } else {
            if (primaryA < primaryB) {
              return 1
            }
            if (primaryA > primaryB) {
              return -1
            }
            return 0
          }
        })
        return sortedReports
      case 'status':
        sortedReports = reportsToSort.slice().sort((a, b) => {
          let isAStatusSmallerThenBStatus =
            SORT_BY_STATUS_ORDER.indexOf(a.status) <
            SORT_BY_STATUS_ORDER.indexOf(b.status)
          if (desc) {
            if (isAStatusSmallerThenBStatus) {
              return -1
            } else {
              return 1
            }
          } else {
            if (isAStatusSmallerThenBStatus) {
              return 1
            } else {
              return -1
            }
          }
        })
        return sortedReports
      case 'assignee':
        sortedReports = reportsToSort.slice().sort((a, b) => {
          let firstReportAssigneeName = this.getReportAssigneeName(
            a.assignedPharmaEmployeeId
          ).toLowerCase()
          let secondReportAssigneeName = this.getReportAssigneeName(
            b.assignedPharmaEmployeeId
          ).toLowerCase()

          if (desc) {
            if (firstReportAssigneeName < secondReportAssigneeName) {
              return -1
            }
            if (firstReportAssigneeName > secondReportAssigneeName) {
              return 1
            }
            return 0
          } else {
            if (firstReportAssigneeName < secondReportAssigneeName) {
              return 1
            }
            if (firstReportAssigneeName > secondReportAssigneeName) {
              return -1
            }
            return 0
          }
        })

        return sortedReports
      default:
        return reportsToSort
    }
  }

  render () {
    const {
      headers,
      onSelectReport,
      selectedReportId,
      status,
      isNR,
      isPro,
      isBasic
    } = this.props
    const { checkedItems, reports, sortReportsByDefault } = this.state
    return (
      <TableContainer>
        <TableHeader
          isBasic={isBasic}
          headers={headers}
          handleCheckAll={this.onCheckAll}
          disableCheckbox={!isPro}
          handleReportSorting={this.onReportSorting}
          sortReportsByDefault={sortReportsByDefault}
        />
        <TableBodySection
          data={reports}
          isNR={isNR}
          onSelectReport={onSelectReport}
          selectedReportId={selectedReportId}
          status={status}
          checkedItems={checkedItems}
          handleCheckbox={this.onCheckbox}
          onUpdateReport={this.props.onUpdateReport}
        />
      </TableContainer>
    )
  }
}

ReportTable.propTypes = {
  headers: PropTypes.array.isRequired,
  data: PropTypes.array,
  onSelectReport: PropTypes.func,
  status: PropTypes.string,
  isNR: PropTypes.bool
}

function mapStateToProps (state, props) {
  const { isPro, isPlus } = state.proOffer
  const isBasic = !isPro && !isPlus
  return { isBasic, isPro, teamMembers: getActiveTeamMembers(state) }
}

export default connect(mapStateToProps)(withTranslation()(ReportTable))
