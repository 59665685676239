import { eventstoreInstanceAxios as eventstore } from '../config'

const REPORTS_URL = '/reports'
const REPORT_URL = '/report'

/**
 * Retrieve report table data
 *
 * @returns {Promise} - Result of ajax call
 */
export async function getReportsAPI (token) {
  let statusesOfLowerSortOrder = ['pharma-closed', 'pharma-archived']
  const config = {
    url: REPORTS_URL,
    method: 'get',
    headers: { Authorization: token }
  }

  const res = await eventstore(config)
  const reports = res.data.sort((repA, repB) => {
    // sorting criteria (bottom - up):
    // position reports with status pharma-archived at the end of the list
    // then position reports with status pharma-closed
    // sort the rest based on assigned date

    if (
      statusesOfLowerSortOrder.includes(repB.status) &&
      !statusesOfLowerSortOrder.includes(repA.status)
    ) {
      return -1
    }
    if (
      statusesOfLowerSortOrder.includes(repA.status) &&
      !statusesOfLowerSortOrder.includes(repB.status)
    ) {
      return 1
    }

    return new Date(repB.assigned) - new Date(repA.assigned)
  })
  return reports
}

/**
 * @param reportId
 * @param original If set, will return the original report as posted by the patient. If not, the changes by doctors etc. are also incorporated
 * @param minVersion the minimum version to expect - the API will block until that version is received or a timeout is reached
 * @returns {Promise<*>}
 */
export async function getReportAPI (
  reportId,
  original = false,
  minVersion = 0,
  token
) {
  let url = `${REPORTS_URL}/${reportId}?minVersion=${minVersion}`
  if (original) {
    url += '&original=true'
  }
  const config = {
    url: url,
    method: 'get',
    headers: { Authorization: token }
  }

  const res = await eventstore(config)
  return res.data
}

/**
 * Change report status (allowed statuses change are 'pharma-in-progress' & 'pharma-closed')
 * Check statusAllowedToBeSetThroughPrivatePharmaAPI in med-api-reports/src/models/PharmaStatus.js
 *
 * @returns {Promise} - Result of ajax call
 */
export async function changeReportStatusAPI (reportId, toStatus, token) {
  const config = {
    url: `${REPORT_URL}/${reportId}/statusPharma/${toStatus}`,
    method: 'post',
    headers: { Authorization: token }
  }

  const res = await eventstore(config)
  return res.data
}

/**
 * updates internal pharma id of a report
 *
 * @returns {Promise} - Result of ajax call
 */
export async function updatePharmaInternalIdAPI (
  reportId,
  newPharmaInternalId,
  token
) {
  const config = {
    url: `${REPORT_URL}/${reportId}/pharmaInternalId`,
    method: 'patch',
    headers: { Authorization: token },
    data: {
      pharmaInternalId: newPharmaInternalId
    }
  }

  const res = await eventstore(config)
  return res.data
}

/**
 * Change report assignee
 *
 * @returns {Promise} - Result of ajax call
 */
export async function changeReportAssigneeAPI (reportId, newId, token) {
  const config = {
    url: `${REPORT_URL}/${reportId}/assignToColleague`,
    method: 'post',
    headers: { Authorization: token },
    data: {
      colleagueId: newId
    }
  }

  const res = await eventstore(config)
  return res.data
}
