import React, { Component } from 'react'
import LoginNewPassword from 'components/LoginNewPassword'
import {
  LoginWrapper,
  LoginHeader,
  LoginFormWrapper
} from 'containers/LoginContainer/style'
import background from 'assets/img/medikura-background.png'

class LoginNewPasswordContainer extends Component {
  render () {
    return (
      <>
        <LoginWrapper>
          <LoginHeader />
          <LoginFormWrapper imageUrl={background}>
            <LoginNewPassword />
          </LoginFormWrapper>
        </LoginWrapper>
      </>
    )
  }
}

export default LoginNewPasswordContainer
