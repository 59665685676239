import styled from 'styled-components'
import * as palette from 'styles/palette'

export const NotificationContainer = styled.div`
  background-color: ${props =>
    props.isPlus ? palette.unknownBGColor : palette.primaryBackground};
  padding: 2rem;
  color: ${props =>
    props.isPlus ? palette.unknownBGColor : palette.tableHeaderColor};
  line-height: 2.6em;
  border-radius: 7px;
`

export const PropertyLabel = styled.span`
  font-weight: bold;
  line-height: 2em;
  font-size: 1.3rem;
  color: ${props =>
    props.isPlus ? palette.primarySuperLightGreen : palette.primaryDarkGreen};
`

export const PropertyValue = styled.span`
  font-size: 1.3rem;
`
export const Divider = styled.hr`
  border-width: ${props => (props.isPlus ? '0px' : 'thin')};
`
