import {
  SET_ROUTE_ERROR,
  SET_TOKEN_EXPIRED_ERROR,
  SET_INVALID_REPORT_ID_ERROR,
  SET_INVALID_TOKEN_ERROR,
  SET_REPORT_LINK_DISABLED_ERROR
} from 'actions'

const initialState = {
  wrongAccountError: false,
  tokenExpiredError: false,
  invalidReportIdError: false,
  invalidTokenError: false,
  reportLinkDisabledError: false
}
// We can use this to handle route transition related state changes,
// For example, to show error, success msg on changing of route explicitly via history.push
// pushing state in the history.location makes it messier.
// NOTE:: This is different then routing reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ROUTE_ERROR: {
      return {
        ...state,
        wrongAccountError: action.payload
      }
    }
    case SET_TOKEN_EXPIRED_ERROR: {
      return {
        ...state,
        tokenExpiredError: action.payload
      }
    }
    case SET_INVALID_REPORT_ID_ERROR: {
      return {
        ...state,
        invalidReportIdError: action.payload
      }
    }
    case SET_INVALID_TOKEN_ERROR: {
      return {
        ...state,
        invalidTokenError: action.payload
      }
    }
    case SET_REPORT_LINK_DISABLED_ERROR: {
      return {
        ...state,
        reportLinkDisabledError: action.payload
      }
    }
    default: {
      return state
    }
  }
}
