import React, { Component } from 'react'
import PropTypes from 'prop-types'
import I18n from 'i18n'
import MedModal from '../../../../MedModal'
import HabitsModalContent from './HabitsModalContent'

const hasYes = true
const hasNo = true

class HabitsModal extends Component {
  static propTypes = {
    isOpenModal: PropTypes.bool,
    onClose: PropTypes.func
  }

  state = {
    name: '',
    birthYear: '',
    size: '',
    weight: '',
    gender: 0,
    email: '',
    week: '',
    count: 1
  }

  handleCancel = () => {
    this.props.onClose()
  }

  handleSave = () => {
    this.props.onClose()
  }

  handleAddHabits = () => {
    this.setState({ count: this.state.count + 1 })
  }

  handleRemoveHabits = () => {
    this.setState({ count: this.state.count - 1 })
  }

  render () {
    const { isOpenModal } = this.props
    return (
      <MedModal
        hasNo={hasNo}
        hasYes={hasYes}
        isAlert={false}
        isOpen={isOpenModal}
        modalContent={
          <HabitsModalContent
            count={this.state.count}
            onAddHabits={this.handleAddHabits}
            onRemoveHabits={this.handleRemoveHabits}
          />
        }
        noTxt={I18n.t(
          'components.ReportDetail.DetailContainer.RightDetailView.HabitsModal.cancel'
        )}
        onNo={this.handleCancel}
        onYes={this.handleSave}
        title={I18n.t(
          'components.ReportDetail.DetailContainer.RightDetailView.HabitsModal.title'
        )}
        yesTxt={I18n.t(
          'components.ReportDetail.DetailContainer.RightDetailView.HabitsModal.save'
        )}
      />
    )
  }
}

export default HabitsModal
